import React, { useState, useEffect } from 'react';
import QuickReportForm from './QuickReportForm';
import { notification, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import moment from 'moment';
import * as inspectorService from '../../../services/InspectorDataService';
import * as quickReportService from '../../../services/createQuickReport';
import * as createClientService from '../../../services/AllClients';
import * as getClientdropService from '../../../services/getClientdrop';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../store/actions/inspection';
import { CheckCircleOutlined } from '@ant-design/icons';
import * as SingleRequestServices from '../../../services/SingleRequest';
import ReportSpin from '../../../components/ReportSpinner';

function QuickReport() {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const Data = useSelector((state) => state.Inspection.reqId);
  const [loading, setLoading] = useState(true);
  const [clientDropdown, setclientDropdown] = useState([]);
  // const [loadings, setLoadings] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [SpinnerReport, setSpinnerReport] = useState(false);
  const [vals, setVals] = useState({});
  const [calOpen, setCalOpen] = useState(false);
  const [calOpen1, setCalOpen1] = useState(false);
  const [calOpen2, setCalOpen2] = useState(false);
  const [calOpen3, setCalOpen3] = useState(false);
  const reduxData = useSelector((state) => state.Inspection.reportDetails);
  const [open, setOpen] = useState(['']);
  const [footerLogo, setFooterLogo] = useState([]);
  const [isOpen, setIsOpen] = useState({
    commPartyopen: false,
    buildField: false,
    subFloorOther: false,
    sendingSampleField: false,
    claimHistoryField: true,
    observationField: true,
    applicableField: true,
    industryField: true,
    conclusionField: true,
    causeField: false,
    confidentialField: false,
    signatureField: false,
    footerField: false,
    notesField: false,
    manuissuesField: false,
    siteissuesField: false,
    perfoissuesField: false,
    instaissuesField: false,
  });
  const [values, setValues] = useState({
    claim_num: '',
    ins_time: '',
    inspection_type: '',
    comm_party_drop: '',
    company_name_qr: '',
    agent_claim_name: '',
    comm_email: '',
    street_name_number: '',
    comm_other: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    phone_no_party: '',
    dealer_name: '',
    dealer_phone: '',
    h_eu_name: '',
    h_eu_street: '',
    h_eu_city: '',
    h_eu_stateProvince: '',
    h_eu_country: '',
    h_eu_zip: '',
    h_eu_contact: '',
    h_eu_phone_number: '',
    h_eu_alt_phone_number: '',
    h_eu_email: '',
    h_bui_occupied: 'HideThisField',
    reported_to: '',
    h_bi_manu_name: '',
    style_name: '',
    h_bi_color_name: '',
    area_room_ins: '',
    total_claim_qt: '',
    total_affected: '',
    flooring_installed_toggle: 'HideThisField',
    concerns: '',
    subfloor_type: '',
    qr_subfloor_type: '',
    building_landscape: '',
    subs_grade: '',
    footprint: '',
    total_claim_qt_unit: '',
    sending_samples: 'HideThisField',
    claim_his_togg: true,
    observe_togg: true,
    app_field_togg: true,
    indus_stand_togg: true,
    conc_togg: true,
    cause_togg: '',
    confi: '',
    upload_sign_toggle: true,
    footer_text_info: '',
    private_notes_togg: '',
    footerLogo: [],
    adults: '',
    childrens: '',
    pets: '',
    shipping_company: '',
    tracking_number: '',
    manufacturing_issue_togg: '',
    site_rel_issue_togg: '',
    insta_rel_issue_togg: '',
    perfo_rel_issue_togg: '',
    manufacturing_issue: '',
    site_rel_issue: '',
    insta_rel_issue: '',
    perfo_rel_issue: '',
    disable_conclusion: false
  });
  useEffect(() => {
    if (
      Object.keys(reduxData).length !== 0 &&
      reduxData.constructor === Object &&
      reduxData.currentUrl !== ''
    ) {
      console.warn('reduxData', reduxData);
      setLoading(true);
      setValues(reduxData);
      fetchInspectorData();
      fetchclientData();
      setDefaultPanel();

      setLoading(false);
    } else {
      fetchReqData();
      fetchInspectorData();
      fetchclientData();
      setDefaultPanel();
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchReqData = () => {
    dispatch(SingleRequestServices.getSingleRequest(Data.req_id))
      .then((res) => {
        setLoading(true);
        values.client_type = res.client_type;
        values.claim_num = res.claim_number;
        values.concerns = res.concerns;
        values.dealer_name = res.dealer_name;
        values.dealer_phone = res.dealer_phone;
        values.dealer_email = res.dealer_email;
        values.end_email = res.eu_email;
        values.h_eu_name = res.eu_name;
        values.h_eu_street = res.eu_street;
        values.h_eu_city = res.eu_city;
        values.h_eu_country = res.eu_country;
        values.h_eu_stateProvince = res.eu_state;
        values.h_eu_phone_number = res.eu_mob;
        values.h_eu_alt_phone_number = res.eu_phone2;
        values.h_eu_email = res.eu_email;
        values.end_address = res.eu_address;
        values.h_eu_zip = res.eu_zip;
        values.end_mobile = res.eu_mob;
        values.eu_phone1 = res.eu_phone2;
        values.h_eu_contact = res.manufacturer_contact;
        values.product_date_of_ins = res.installation_date;
        values.date_problem_noticed = res.problem_date;
        values.h_bi_manu_name = res.manufacturer_name;
        values.style_name = res.style_name;
        values.product_color = res.color;
        values.total_claim_qt = res.quanty;
        values.area_room_ins = res.room_installed;
        values.manf_name = res.manufacturer_name;
        values.manf_contact = res.manufacturer_contact;
        values.manf_phone = res.manufacturer_phone_number;
        values.manf_email = res.manufacturer_email;
        values.product_manf = res.manufacturer_name;
        values.dealer_contact = res.dealer_contact;
        values.product_phone = res.dealer_phone;
        values.product_email = res.dealer_email;
        values.company_name_qr = res.comm_party_company_name;
        values.agent_claim_name = res.comm_party_first_last_name;
        values.comm_email = res.comm_party_email;
        values.street_name_number = res.comm_party_street_name_number;
        values.city = res.comm_party_city;
        values.state = res.comm_party_state;
        values.country = res.comm_party_country;
        values.zip_code = res.comm_party_zip_code;
        values.phone_no_party = res.comm_party_phone_number;
        values.disable_conclusion = res.disable_conclusion;
        if (res.disable_conclusion) {
          values.conclusion_text = "The commissioning party has requested that the inspection report provide factual and technical information, which will be used with other manufacturer’s data, including product development, product history, warranty, installation, and care & maintenance information for this claim’s determination and final disposition. The manufacturer will use the information to determine if the claim is manufacturing-related or related to non-manufacturing issues."; 
        }
        setLoading(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...', loading);
        setLoading(false);
      });
  };
  const fetchInspectorData = (params = {}) => {
    dispatch(inspectorService.getInspectorData())
      .then((res) => {
        values.signId = res.sign_for_report;
        values.signature = res.sign_for_report;
        values.signurl = res.signurl;
        values.footer_text = res.footer_text;
        if (res.footerlogos) {
          var sphValues = res.footerlogos;
          setFooterLogo(res.footerlogos);
          values.footerLogo = sphValues.map(function (e) {
            return e.toString();
          });
        }
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
        setLoading(false);
      });
  };

  const setDefaultPanel = () => {
    setOpen(['1', '2', '3', '4', '5', '6', '7', '8', '10']);
  };
  const fetchclientData = (params = {}) => {
    dispatch(getClientdropService.getClientdrop())
      .then((res) => {
        setclientDropdown(res);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };
  const onChangeCollapse = (key) => {
    setOpen(key);
  };
  const handleChange = (input) => (e) => {
    console.warn({ input });

    if (
      input === 'comm_party_drop' ||
      input === 'subfloor_type' ||
      input === 'sending_samples' ||
      input === 'claim_his_togg' ||
      input === 'observe_togg' ||
      input === 'app_field_togg' ||
      input === 'indus_stand_togg' ||
      input === 'conc_togg' ||
      input === 'cause_togg' ||
      input === 'manufacturing_issue_togg' ||
      input === 'site_rel_issue_togg' ||
      input === 'insta_rel_issue_togg' ||
      input === 'perfo_rel_issue_togg' ||
      input === 'confi' ||
      input === 'upload_sign_toggle' ||
      input === 'footer_text_info' ||
      input === 'private_notes_togg' ||
      input === 'flooring_installed_toggle' ||
      input === 'h_bui_occupied'
    ) {
      if (input === 'flooring_installed_toggle') {
        setValues({ ...values, [input]: e });
      }

      if (input === 'comm_party_drop' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          commPartyopen: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'comm_party_drop' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          commPartyopen: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'subfloor_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          subFloorOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'subfloor_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          subFloorOther: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'h_bui_occupied') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            buildField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            buildField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }
      if (input === 'sending_samples') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            sendingSampleField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            sendingSampleField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }
      if (input === 'claim_his_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          claimHistoryField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'claim_his_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          claimHistoryField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'observe_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          observationField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'observe_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          observationField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'app_field_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          applicableField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'app_field_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          applicableField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'indus_stand_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          industryField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'indus_stand_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          industryField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'conc_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          conclusionField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'conc_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          conclusionField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'cause_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          causeField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'cause_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          causeField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'manufacturing_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          manuissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'manufacturing_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          manuissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'site_rel_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          siteissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'site_rel_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          siteissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'insta_rel_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          instaissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'insta_rel_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          instaissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'perfo_rel_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          perfoissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'perfo_rel_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          perfoissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'confi' && e === true) {
        setIsOpen({
          ...isOpen,
          confidentialField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'confi' && e === false) {
        setIsOpen({
          ...isOpen,
          confidentialField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'upload_sign_toggle' && e === true) {
        setIsOpen({
          ...isOpen,
          signatureField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'upload_sign_toggle' && e === false) {
        setIsOpen({
          ...isOpen,
          signatureField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'footer_text_info' && e === true) {
        setIsOpen({
          ...isOpen,
          footerField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'footer_text_info' && e === false) {
        setIsOpen({
          ...isOpen,
          footerField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'private_notes_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          notesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'private_notes_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          notesField: false,
        });
        setValues({ ...values, [input]: e });
      } else {
        setValues({ ...values, [input]: e });
      }
    } else if (
      input === 'time_type' ||
      input === 'inspection_type' ||
      input === 'subs_grade' ||
      input === 'footprint' ||
      input === 'building_landscape' ||
     
      input === 'measure_type1' ||
      input === 'measure_type2'
    ) {
      input === 'time_type' ||
      input === 'measure_type1' ||
      input === 'measure_type2'
        ? setValues({ ...values, [input]: e[0] })
        : setValues({ ...values, [input]: e });
    } else if (input === 'footerLogo') {
      //start
      let footerlogo1 = [];
      let id = e.target.value;
      footerlogo1 = [...footerLogo];
      if (footerlogo1?.includes(id)) {
        let footer_idex = footerlogo1.indexOf(id);
        delete footerlogo1[footer_idex];
      } else {
        footerlogo1.push(id);
      }
      footerlogo1 = footerlogo1?.filter((e) => e);
      setFooterLogo(footerlogo1);
      values.footerLogo = footerlogo1;
    } else {
      setValues({ ...values, [input]: e.target.value });
    }

    setVals({ ...values, currentUrl: window.location.href, attachments: [] });
    dispatch(Actions.create_report(vals));
  };

  const handleEditor = (valEditor, label) => {
    setValues((l) => ({ ...l, [label]: valEditor }));
    let tempVals = {
      ...values,
      currentUrl: window.location.href,
      attachments: [],
    };
    console.warn('handleEditor', values);
    dispatch(Actions.create_report(tempVals));
    console.warn('valhandleditor', tempVals, { label }, { valEditor });
  };

  const handleFill = (val) => {
    setValues({ ...values, ...val });
    setVals({ ...values, currentUrl: window.location.href, attachments: [] });

    dispatch(Actions.create_report(vals));
    console.warn('valhandlefill', vals, { values });
  };

  const buttonSubmit = () => {
    setSpinnerReport(true);
    values.status = 'Saved';
    onFinish();
    console.warn('sent values to api ', values);
    //return;
    dispatch(quickReportService.createQuickReport(values))
      .then((res) => {
        console.warn('Submit', res);
        notification.open({
          message: 'Report successfully created!',
          description: 'You have successfully created a report.',
          icon: (
            <CheckCircleOutlined
              style={{
                color: '#108ee9',
              }}
            />
          ),
        });
        //return;
        setSpinnerReport(false);
        navigate('/single-quick-report/?report_id=' + res.data.report_id);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };
  const saveDraft = () => {
    setSpinnerReport(true);
    onFinish();
    values.status = 'Draft';
    dispatch(quickReportService.createQuickReport(values))
      .then((res) => {
        console.warn('Submit', res);
        setSpinnerReport(false);
        navigate('/quick-report-update/?report_id=' + res.data.report_id);
        message.success('Saved as draft!');
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };
  const addClient = () => {
    let clientData = {
      company_name_qr: values.company_name_qr,
      comm_party_drop: values.comm_party_drop,
      agent_claim_name: values.agent_claim_name,
      comm_email: values.comm_email,
      street_name_number: values.street_name_number,
      state: values.state,
      city: values.city,
      country: values.country,
      zip_code: values.zip_code,
      phone_no_party: values.phone_no_party,
    };
    if (clientData.company_name_qr !== '' && clientData.comm_email !== '') {
      dispatch(createClientService.create_client(clientData))
        .then((res) => {
          console.warn('createClientService', res);
          if (res.status === 'client added') {
            fetchclientData();
            notification.open({
              message: 'Client successfully created!',
              description: 'You have successfully created a client.',
              icon: (
                <CheckCircleOutlined
                  style={{
                    color: '#108ee9',
                  }}
                />
              ),
            });
          } else {
            fetchclientData();
            notification.error({
              message: `${res.status}`,
              description:
                'The client that you are trying to create already exists.”',
              className: 'custom-class',
            });
          }
        })
        .catch((err) => {
          console.info('Get Error Edit State...', loading);
          notification.error('This is an error message');
          setLoading(false);
        });
    } else {
      notification.error('Client data is not valid');
    }
  };
  const handleDateformate = (e, name, type) => {
    if (name === 'h_bi_ins_date') {
      if (type === 'date') {
        setCalOpen(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'product_date_of_ins') {
      if (type === 'date') {
        setCalOpen1(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_problem_noticed') {
      if (type === 'date') {
        setCalOpen2(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }

    if (name === 'date_shipped') {
      if (type === 'date') {
        setCalOpen3(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
  };
  const onFinish = () => {
    dispatch(Actions.create_report({}));
    // enterLoading(0);
    setLoadings(true);

    values.req_id = Data.req_id;
    values.category = Data.floor_category;
    values.total_claim_qt_unit = values?.measure_type1;
    values.total_affected_unit = values?.measure_type2;
    // values.total_claim_qt_unit = values?.measure_type;
    values.claim_his_togg = values.claim_his_togg === true ? 'yes' : 'no';
    values.observe_togg = values.observe_togg === true ? 'yes' : 'no';
    values.app_field_togg = values.app_field_togg === true ? 'yes' : 'no';
    values.indus_stand_togg = values.indus_stand_togg === true ? 'yes' : 'no';
    values.conc_togg = values.conc_togg === true ? 'yes' : 'no';
    values.cause_togg = values.cause_togg === true ? 'yes' : 'no';
    values.manufacturing_issue_togg = values.manufacturing_issue_togg === true ? 'yes' : 'no';
    values.site_rel_issue_togg = values.site_rel_issue_togg === true ? 'yes' : 'no';
    values.insta_rel_issue_togg = values.insta_rel_issue_togg === true ? 'yes' : 'no';
    values.perfo_rel_issue_togg = values.perfo_rel_issue_togg === true ? 'yes' : 'no';
    values.issues_info_toggle = open.includes('10') ? 'yes' : 'no';
    values.confi = values.confi === true ? 'yes' : 'no';
    values.upload_sign_toggle =
      values.upload_sign_toggle === true ? 'yes' : 'no';
    values.footer_text_info = values.footer_text_info === true ? 'yes' : 'no';
    values.private_notes_togg =
      values.private_notes_togg === true ? 'yes' : 'no';
    values.footer_info_toggle =
      values.footer_info_toggle === true ? 'yes' : 'no';
    values.floor_rolled_toggle =
      values.floor_rolled_toggle === true ? 'yes' : 'no';
    values.party_toggle = open.includes('1') ? 'yes' : 'no';
    values.dealer_info_toggle = open.includes('2') ? 'yes' : 'no';
    values.enduser_toggle = open.includes('3') ? 'yes' : 'no';
    values.basic_info_toggle = open.includes('4') ? 'yes' : 'no';
    values.subtrace_toggle = open.includes('5') ? 'yes' : 'no';
    values.obser_info_toggle = open.includes('6') ? 'yes' : 'no';
    //values.confi = open.includes('7') ? 'yes' : 'no';
    values.footer_info_toggle = open.includes('8') ? 'yes' : 'no';
    values.open = open;

    console.log('Success:', values);
  };
  return (
    <>
      {SpinnerReport ? (
        <ReportSpin />
      ) : (
        <>
          <QuickReportForm
            handleChange={handleChange}
            handleFill={handleFill}
            values={values}
            loading={loading}
            isOpen={isOpen}
            open={open}
            onChangeCollapse={onChangeCollapse}
            addClient={addClient}
            clientDropdown={clientDropdown}
            handleDateformate={handleDateformate}
            calOpen={calOpen}
            setCalOpen={setCalOpen}
            calOpen1={calOpen1}
            setCalOpen1={setCalOpen1}
            calOpen2={calOpen2}
            setCalOpen2={setCalOpen2}
            calOpen3={calOpen3}
            setCalOpen3={setCalOpen3}
            handleEditor={handleEditor}
            buttonSubmit={buttonSubmit}
            saveDraft={saveDraft}
            loadings={loadings}
            setLoadings={setLoadings}
          />
        </>
      )}
    </>
  );
}

export default QuickReport;
