import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

/**
 * Save or update an inspection report
 * @param {Object} reportData - The inspection report data to save
 * @returns {Promise}
 */
export function saveFsInspectionReport(reportData) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(INSPECTION_URL + "save_fsinspection_report", reportData)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getFsInspectionReport(reportId) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(INSPECTION_URL + "get_fsinspection_report/" + reportId)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getFsInspectionRequest(requestId) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(INSPECTION_URL + "get_fsinspection_request/" + requestId)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function generatePdfInspectionReport(reportId) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(INSPECTION_URL + "generate-pdf/" + reportId)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}
