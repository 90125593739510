import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Card,
  Form,
  Switch,
  Collapse,
  Button,
  Tooltip,
  Modal,
  message,
  Cascader,
  Upload,
  Image,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  SearchOutlined,
  CopyOutlined,
  FormOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import SkeletonIndustryStandard from "../SkeletonIndustryStandard";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
import axios from "axios";
import { MEDIA_UPLOAD_URL } from "../../../Helper";
import Upload1 from "../uploadMultistep";
import { useDispatch } from "react-redux";
import * as FooterLogoService from "../../../services/FooterLogo";
import * as getSuggestionService from "../../../services/getSuggestion";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";

const optionss = [
  {
    value: "°F",
    label: "°F",
  },
  {
    value: "°C",
    label: "°C",
  },
];

const FourthStep = (props) => {
  console.warn("FourthStep", props);
  var dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterStandards, setFilterStandards] = useState([]);
  const [modalIndustry, setModalIndustry] = useState(false);
  const [industryContent, setindustryContent] = useState("");
  const [sidebarState, setsidebarState] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [footerLogo, setFooterLogo] = useState([]);
  const [selectedlogs, setselectedLogos] = useState([]);
  const [sign, setSign] = useState([]);
  const [editorState5, setEditorState5] = useState("");
  const [editorState6, setEditorState6] = useState("");
  const [editorState7, setEditorState7] = useState("");
  const [editorState8, setEditorState8] = useState("");
  const [editorState9, setEditorState9] = useState("");

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const onEditorStateChange5 = (editorData) => {
    setEditorState5(editorData);
    props.values.apply_industry_stndr = editorData;
    props.handleEditor(editorData, "apply_industry_stndr");
  };
  const onEditorStateChange6 = (editorData) => {
    setEditorState6(editorData);
    props.values.add_testing_information = editorData;
    props.handleEditor(editorData, "add_testing_information");
  };

  const onEditorStateChange7 = (editorData) => {
    setEditorState7(editorData);
    props.values.conclusion_cause = editorData;
    props.handleEditor(editorData, "conclusion_cause");
  };
  const onEditorStateChange8 = (editorData) => {
    setEditorState8(editorData);
    props.values.footer_text = editorData;
    props.handleEditor(editorData, "footer_text");
  };
  const onEditorStateChange9 = (editorData) => {
    setEditorState9(editorData);
    props.values.private_notes = editorData;
    props.handleEditor(editorData, "private_notes");
  };
  const SunEditorOpts = {
    showPathLabel: false,
    imageMultipleFile: true,
    imageWidth: "150px",
    imageHeight: "150px",
    height: "auto",
    minHeight: "200px",
    requestHeaders: {
      "X-Sample": "sample",
    },
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      image,
      link,
    ],
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor"],
      ["removeFormat"],
      ["align", "horizontalRule","lineHeight"],
      ["outdent", "indent"],["list"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks", "preview"],
    ],
    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
    font: [
      "Arial",
      "Calibri",
      "Comic Sans",
      "Courier",
      "Garamond",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Palatino Linotype",
      "Segoe UI",
      "Tahoma",
      "Times New Roman",
      "Trebuchet MS",
      "Roboto",
    ],
  };
    const onImageUploadBefore = (files, info, uploadHandler) => {
    const formData = new FormData()
     const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    formData.append('file', files[0])
    axios.post(MEDIA_UPLOAD_URL, formData, config)
      .then((res)=>{
         const response = {
                // The response must have a "result" array.
                "result": [
                    {
                        "url": res.data.source_url,
                        "name": files[0].name,
                        "size": files[0].size
                    },
            ]}
        uploadHandler(response)
      })
  }
  const getSuggestion = (label, category) => {
    setLoading(true);
    let suggestiondata = {
      label: label,
      category: category,
    };
    dispatch(getSuggestionService.getindStandard(suggestiondata))
      .then((res) => {
        setsidebarState(res);
        setLoading(false);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
        props.values.signurl = url;
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  console.log(sign);
  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    fmData.append("file", file);
    try {
      const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
      onSuccess("Ok");
      setSign(res);
      console.log("server res: ", res);
      props.values.signature = res?.data?.id;
      console.log("props.values.signature", props.values.signature);
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
      console.warn(error);
    }
  };

  const takefileList = (list) => {
    props.values.attachments = list;
  };
  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(FooterLogoService.getLogos())
      .then((res) => {
        setFooterLogo(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    setSign(props.values?.signId);
    setselectedLogos(props.values?.footerLogo);
    setImageUrl(props.values?.signurl);
    props.values.apply_industry_stndr
    ? setEditorState5(props.values.apply_industry_stndr)
    : setEditorState5("");
    props.values.add_testing_information
      ? setEditorState6(props.values.add_testing_information)
      : setEditorState6("");
    props.values.conclusion_cause
      ? setEditorState7(props.values.conclusion_cause)
      : setEditorState7("");
    props.values.footer_text
      ? setEditorState8(props.values.footer_text)
      : setEditorState8("");
    props.values.private_notes
      ? setEditorState9(props.values.private_notes)
      : setEditorState9("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.footerLogo]);

  useEffect(() => {
    console.info("Useffect Called");
    if (sidebarState) {
      const filteredData = sidebarState.filter((entry) =>
        entry?.title?.toLowerCase().includes(search.toLowerCase())
      );
      setFilterStandards(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleClick = (item1) => {
    setModalIndustry(true);
    sidebarState.filter((item) =>
      item.id === item1 ? setindustryContent(item) : ""
    );
  };

  const sendTextToEditor = (text, label) => {
    if (label === "applicable-field-tests") {
      setEditorState6(editorState6 + text);
      onEditorStateChange6(editorState6 + text);
} else if (label === "industry_standards") {
  setEditorState5(editorState5 + text);
  onEditorStateChange5(editorState5 + text);
} else if (label === "conclusion") {
  setEditorState7(editorState7 + text);
  onEditorStateChange7(editorState7 + text);
}
else if (label === "footer-text") {
  setEditorState8(editorState8 + text);
  onEditorStateChange8(editorState8 + text);
}

  };
  const [form] = Form.useForm();

  const { Panel } = Collapse;

  return (
    <>
      <Form name="report" autoComplete="off" layout="vertical" form={form}>
        <Card className="reportCard">
          <div className="reportCardBody">
            <div className="formSection">
              <div className="inspectionSection">
                <div className="CollapseSection">
                  <Collapse
                    defaultActiveKey={
                      props.values.stepFourTogg1
                        ? props.values.stepFourTogg1
                        : ["1"]
                    }
                    onChange={props.handleChange("stepFourTogg1")}
                    className="reportCollapse"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <MinusOutlined className="custom-icon" />
                      ) : (
                        <PlusOutlined className="custom-icon" />
                      )
                    }
                    ghost
                  >
                    <Panel header="Jobsite Conditions at Inspection" key="1">
                      <div className="inspecContent">
                        <Row gutter={16}>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Moisture Content of Floor"
                                name="moist_cont_floor"
                              >
                                <Input
                                  placeholder="Moisture Content of Floor"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    "moist_cont_floor"
                                  )}
                                  defaultValue={props.values.moist_cont_floor}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Method/Meter Used"
                                name="meter_meth_used"
                              >
                                <Input
                                  placeholder="Method/Meter Used"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    "meter_meth_used"
                                  )}
                                  defaultValue={props.values.meter_meth_used}
                                />
                              </Form.Item>
                            </div>
                          </Col>

                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              
                              <Form.Item
                                    label="HVAC System Operating"
                                    name="hvac_sys_operating"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.hvac_sys_operating}
                                        onToggle={props.handleChange('hvac_sys_operating')}
                                      />
                                </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              
                              <Form.Item
                                     label="HVAC Testing" name="hvac_tsti"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.hvac_tsti}
                                        onToggle={props.handleChange('hvac_tsti')}
                                      />
                                </Form.Item>
                            </div>
                          </Col>
                          {props.isOpen.hvac_tstiField === true ||
                          props.values.hvac_tsti === 'Yes' ? (
                            <>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup custom-form-group">
                                  <Form.Item
                                    label="Temperature"
                                    name="Temp_hvac"
                                  >
                                    <Input
                                      className="formGroup customPadding"
                                      addonAfter={
                                        <Cascader
                                          placeholder="F/C"
                                          onChange={props.handleChange(
                                            "measure_type16"
                                          )}
                                          options={optionss}
                                          defaultValue={
                                            props.values.measure_type16
                                          }
                                          className="custom-cascadar"
                                        />
                                      }
                                      onChange={props.handleChange("Temp_hvac")}
                                      defaultValue={props.values.Temp_hvac}
                                      placeholder="Temperature"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Relative Humidity(%)"
                                    name="rel_humidity"
                                  >
                                    <Input
                                      placeholder="Relative Humidity"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "rel_humidity"
                                      )}
                                      defaultValue={props.values.rel_humidity}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item label="Meter Used" name="m_use">
                                    <Input
                                      placeholder="Meter Used"
                                      className="formControl"
                                      onChange={props.handleChange("m_use")}
                                      defaultValue={props.values.m_use}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                        </Row>
                        {/* <Row gutter={16}>
                          <Form.Item>
                            <p>Humidification/Dehumidification System</p>
                          </Form.Item>
                        </Row> */}
                        <Row gutter={16}>
                        <Col xs={24} md={6}>
                            <div className="formGroup">
                              
                              <Form.Item
                                    label="Humidification/Dehumidification System"
                                    name="h_dehu_syst"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.h_dehu_syst}
                                        onToggle={props.handleChange('h_dehu_syst')}
                                      />
                                </Form.Item>
                            </div>
                          </Col>
                          {props.isOpen.humi_dehum_sysField === true ||
                        props.values.h_dehu_syst === 'Yes' ? (
                            <>
                          <Col xs={24} md={12} xl={12}>
                            <div className="formGroup">
                              
                              <Form.Item
                                    label="Is Humidification System Operating?"
                                    name="humidification"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.humidification}
                                        onToggle={props.handleChange('humidification')}
                                      />
                                </Form.Item>
                            </div>
                          </Col>
                          {props.isOpen.humidificationField === true ||
                          props.values.humidification === 'Yes' ? (
                            <>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Thermostat Control Settings"
                                    name="th_control_settings"
                                  >
                                    <Input
                                      placeholder="Thermostat Control Settings"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "th_control_settings"
                                      )}
                                      defaultValue={
                                        props.values.th_control_settings
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  
                                  <Form.Item
                                    label="Data Logger"
                                    name="data_logger"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.data_logger}
                                        onToggle={props.handleChange('data_logger')}
                                      />
                                </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={12} xl={12}>
                                <div className="formGroup">
                                  
                                  <Form.Item
                                    label="Is Humidification/Dehumidification System Programmable?"
                                    name="programmable"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.programmable}
                                        onToggle={props.handleChange('programmable')}
                                      />
                                </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={12} xl={12}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Surface Temperature for Humidification/Dehumidification System"
                                    name="surface_temp"
                                  >
                                    <Input
                                      placeholder="Surface Temperature"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "surface_temp"
                                      )}
                                      defaultValue={props.values.surface_temp}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={12} xl={12}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Method/Meter for Humidification/Dehumidification System Used"
                                    name="dehum_meter_meth_used"
                                  >
                                    <Input
                                      placeholder="Method/Meter Used"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "dehum_meter_meth_used"
                                      )}
                                      defaultValue={
                                        props.values.dehum_meter_meth_used
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                          </> ) : "" }
                        </Row>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
                <div className="CollapseSection">
                  <div className="customObservationPanel">
                    <Row gutter={16}>
                      <Col xs={24} md={16} xl={16}>
                        <Collapse
                          defaultActiveKey={
                            props.values.stepFourTogg2
                              ? props.values.stepFourTogg2
                              : ["1", "2", "3", "4"]
                          }
                          onChange={props.handleChange("stepFourTogg2")}
                          className="reportCollapse"
                          expandIcon={({ isActive }) =>
                            isActive ? (
                              <MinusOutlined className="custom-icon" />
                            ) : (
                              <PlusOutlined className="custom-icon" />
                            )
                          }
                          ghost
                        >
                          <Panel
                            header="Additional Testing Information"
                            key="1"
                          >
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="m-2"><i><b>
                                 Note: Please list all of the test performed along with the results and tool used for the tests. Add reference to supporting photos.
                                  </b> </i>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        getSuggestion(
                                          "applicable-field-tests",
                                          "Hardwood"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState6}
                                      onChange={onEditorStateChange6}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel
                            header="Applied Industry Standards"
                            key="2"
                          >
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="m-2"><i><b>
                                  Note: Please list the source from which standards were applied . Then copy/paste specific areas of content that directly apply to the Statement of Concern.</b> </i> </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        getSuggestion(
                                          "industry_standards",
                                          "Hardwood"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState5}
                                      onChange={onEditorStateChange5}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="Conclusion/Cause" key="3">
                          <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="m-2"><i><b>
                                Note: Please list all of the test performed along with the results and tool used for the tests. Add reference to supporting photos.
                                </b></i></div>
                                 </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        getSuggestion(
                                          "conclusion",
                                          "Hardwood"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState7}
                                      onChange={onEditorStateChange7}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="Footer" key="4">
                            <Col xs={24} md={12} xl={24}>
                              <Row
                                gutter={16}
                                className="footer-singnature-section"
                              >
                                <div className="formGroup">
                                  <Form.Item
                                    label="Upload Signature"
                                    name="upload_sign_toggle"
                                  >
                                    <Switch
                                      defaultChecked={
                                        props.values.upload_sign_toggle ===
                                          true ||
                                        props.isOpen.signatureField === true
                                          ? true
                                          : false
                                      }
                                      onChange={props.handleChange(
                                        "upload_sign_toggle"
                                      )}
                                    />
                                  </Form.Item>
                                </div>
                                {props.isOpen.signatureField === true ||
                                props.values.upload_sign_toggle === true ? (
                                  <>
                                    <div className="image-wrapper">
                                      <Form.Item
                                        name="upload"
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                      >
                                        <Upload
                                          name="avatar"
                                          listType="picture-card"
                                          className="avatar-uploader"
                                          showUploadList={false}
                                          customRequest={uploadImage}
                                          beforeUpload={beforeUpload}
                                          onChange={handleChangeImage}
                                        >
                                          {imageUrl ? (
                                            <img
                                              src={imageUrl}
                                              alt="avatar"
                                              style={{
                                                width: "100%",
                                              }}
                                            />
                                          ) : (
                                            uploadButton
                                          )}
                                        </Upload>
                                      </Form.Item>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </Col>
                            <Col xs={24} md={24} xl={24}>
                              <Row gutter={16}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Footer Text"
                                    name="footer_text_info"
                                    valuePropName="checked"
                                  >
                                    <Switch
                                      defaultChecked={
                                        props.values.footer_text_info ===
                                          true ||
                                        props.isOpen.footerField === true
                                          ? true
                                          : false
                                      }
                                      onChange={props.handleChange(
                                        "footer_text_info"
                                      )}
                                    />
                                  </Form.Item>
                                </div>
                                {props.isOpen.footerField === true ||
                                props.values.footer_text_info === true ? (
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        getSuggestion(
                                          "footer-text",
                                          "Hardwood"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState8}
                                      onChange={onEditorStateChange8}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </Col>
                          </Panel>
                        </Collapse>
                      </Col>
                      <Col xs={24} md={8} xl={8} className="sticySidebar">
                        <Card className="custom-sidecard">
                          <h5 className="formGroupTitle1">
                            Applied Industry Standards
                          </h5>
                          <div className="searchWrapper">
                            <Input
                              prefix={<SearchOutlined />}
                              placeholder="Search for names.."
                              className="formControl"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <p>
                              Your citations will start appearing here when you
                              will start writing under observation or industry
                              standards.
                            </p>
                            {loading ? (
                              <div className="spinDiv">
                                <SkeletonIndustryStandard />
                              </div>
                            ) : (
                              <div className="innerCardWrap">
                                {filterStandards && search
                                  ? filterStandards?.map((item, index) => {
                                      return (
                                        <Card
                                          className="industryCard mb-2 sidebarCard"
                                          key={index}
                                        >
                                          <div className="industryCardBody">
                                            <div className="cardTopSection">
                                              <div className="headerSec">
                                                <h3
                                                  className="industryTitle"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.title,
                                                  }}
                                                ></h3>
                                                <p
                                                  className="industryDesc"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.excerpt,
                                                  }}
                                                ></p>
                                              </div>
                                            </div>
                                            <div className="contentSec">
                                              <div className="btnGroup">
                                                <Tooltip title="Copy/Paste">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn viewBtn"
                                                    onClick={() =>
                                                      sendTextToEditor(
                                                        item.description,
                                                        item.label
                                                      )
                                                    }
                                                  >
                                                    <CopyOutlined />
                                                  </Button>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                  >
                                                    <FormOutlined />
                                                  </Button>
                                                </Tooltip>
                                                <Tooltip title="View More">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                    onClick={() =>
                                                      handleClick(item.id)
                                                    }
                                                  >
                                                    <EyeOutlined />
                                                  </Button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                      );
                                    })
                                  : sidebarState?.map((item, index) => {
                                      return (
                                        <Card
                                          className="industryCard mb-2 sidebarCard"
                                          key={index}
                                        >
                                          <div className="industryCardBody">
                                            <div className="cardTopSection">
                                              <div className="headerSec">
                                                <h3
                                                  className="industryTitle"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.title,
                                                  }}
                                                ></h3>
                                                <p
                                                  className="industryDesc"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.excerpt,
                                                  }}
                                                ></p>
                                              </div>
                                            </div>
                                            <div className="contentSec">
                                              <div className="btnGroup">
                                                <Tooltip title="Copy/Paste">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn viewBtn"
                                                    onClick={() =>
                                                      sendTextToEditor(
                                                        item.description,
                                                        item.label
                                                      )
                                                    }
                                                  >
                                                    <CopyOutlined />
                                                  </Button>
                                                </Tooltip>
                                                {/* <Tooltip title="Edit">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                  >
                                                    <FormOutlined />
                                                  </Button>
                                                </Tooltip> */}
                                                <Tooltip title="View More">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                    onClick={() =>
                                                      handleClick(item.id)
                                                    }
                                                  >
                                                    <EyeOutlined />
                                                  </Button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                      );
                                    })}
                              </div>
                            )}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="attachmentSection">
                  <div className="attachmentHead">
                    <h4 className="formGroupTitle">Add Attachments</h4>
                  </div>
                  <Row className="custom-dropzone-row" gutter={16}>
                    <Upload1
                      takefileList={takefileList}
                      values={props.values}
                      setLoadings={props.setLoadings}

                    ></Upload1>
                  </Row>
                </div>
                <div className="attachmentSection">
                  <div className="CertificationHead">
                    <h4 className="formGroupTitle">Certification</h4>
                  </div>
                  <Row className="my-2 footerRow" gutter={6}>
                    {Object.keys(footerLogo).map((keyName, index) => (
                      <Form.Item name={[`footerLogo`, index]} key={index}>
                        <Col className="col-auto h-100" key={index}>
                          <div className="logoDiv">
                            <input
                              type="checkbox"
                              id={footerLogo[keyName].logo_id}
                              name="logoSelect[]"
                              defaultChecked={selectedlogs?.includes(
                                footerLogo[keyName].logo_id?.toString()
                              )}
                              onChange={props.handleChange("footerLogo")}
                              value={[footerLogo[keyName].logo_id]}
                            />

                            <label htmlFor={footerLogo[keyName].logo_id}>
                              <Image
                                src={footerLogo[keyName].logo_url}
                                className="img-fluid"
                                alt="logos"
                                preview={false}
                              />
                            </label>
                          </div>
                        </Col>
                      </Form.Item>
                    ))}
                  </Row>
                </div>
                <div className="attachmentSection">
                  <div className="CertificationHead">
                    <h4 className="formGroupTitle">Private Notes</h4>
                  </div>
                  <div className="p-3">
                    <div className="formGroup">
                      <Form.Item
                        label="Private Notes"
                        name="private_notes_togg"
                      >
                        <Switch
                          defaultChecked={
                            props.values.private_notes_togg === true ||
                            props.isOpen.notesField === true
                              ? true
                              : false
                          }
                          onChange={props.handleChange("private_notes_togg")}
                        />
                      </Form.Item>
                    </div>
                    {props.isOpen.notesField === true ||
                    props.values.private_notes_togg === true ? (
                      <div className="text-editor-wrapper">
                        <SunEditor
                          onFocus={(event) => {
                            getSuggestion("private_notes", "Carpet");
                          }}
                          setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                          setOptions={SunEditorOpts}
                          onImageUploadBefore={onImageUploadBefore}
                          setContents={editorState9}
                          onChange={onEditorStateChange9}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Form>
      <Modal
        title={industryContent.title}
        centered
        open={modalIndustry}
        closable={false}
        className="inspectionCategoryModal industryModal"
        width={1000}
        footer={[
          <Button
            key="back"
            onClick={() => setModalIndustry(false)}
            className="submitBtn"
          >
            Ok
          </Button>,
        ]}
      >
        <div className="modalContent">
          <div className="formGroup">
            <p
              className="mcDescription"
              dangerouslySetInnerHTML={{ __html: industryContent.description }}
            ></p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FourthStep;
