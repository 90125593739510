import React from 'react';
import CommonFourthStep from '../CommonFourthStep';

function FourthStep(props) {
  return (
    <CommonFourthStep
      reportType="Carpet"
      props={props}
    />
  );
}

export default FourthStep;