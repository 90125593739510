import React, { useState, useEffect, useCallback } from "react";
import { Table, Row, Col, Card, Divider, Switch, Skeleton, Empty } from "antd";
import { IconButton } from "../../components/Buttons/buttons";
import { EyeFilled } from "@ant-design/icons";
import * as ClaimAnalystData from "../../services/ClaimAnalystData";
import * as Clientsrvices from "../../services/AllClients";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ClaimAnalyst = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchClaimAnalystData = useCallback(() => {
    setLoading(true);
    dispatch(ClaimAnalystData.getClaimAnalystData())
      .then((res) => {
        setInspections(res);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    fetchClaimAnalystData();
    window.scrollTo(0, 0);
  }, [fetchClaimAnalystData, page, pageSize]);

  const handleToggleStatus = (status, id) => {
    Swal.fire({
      icon: "warning",
      title: "Are You Sure?",
      confirmButtonText: "Yes",
      showDenyButton: true,
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatus(status, id);
      } else if (result.isDenied) {
        Swal.fire({
          icon: "info",
          text: status ? "Client Not Enabled." : "Client Not Disabled.",
        });
      }
    });
  };

  const updateStatus = (status, id) => {
    setLoading(true);
    const vals = { id, val: status };
    dispatch(Clientsrvices.disable_client(vals))
      .then(() => {
        // Update the state directly after success
        const updatedInspections = inspections.map((item) =>
          item.user_id === id ? { ...item, user_status: status ? "0" : "1" } : item
        );
        setInspections(updatedInspections);
        Swal.fire({
          icon: "success",
          title: status ? "Enabled!" : "Disabled!",
          text: `Client has been ${status ? "enabled" : "disabled"}.`,
        });
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const columns = [
    {
      title: "Claim Analyst ID",
      dataIndex: "user_id",
      key: "Claim_Analyst_ID",
    },
    {
      title: "Claim Analyst Name",
      dataIndex: "fullname",
      key: "Claim_Analyst_Name",
      sorter: (a, b) => a.fullname.localeCompare(b.fullname),
    },
    {
      title: "Completed Inspections",
      dataIndex: "req_count",
      key: "Completed_Inspections",
      sorter: (a, b) => a.req_count - b.req_count,
    },
    {
      title: "Status",
      dataIndex: "user_status",
      key: "status",
      render: (status, record) => (
        <Switch
          checkedChildren="ENABLE"
          unCheckedChildren="DISABLE"
          checked={status === "1" ? false : true}
          onChange={(checked) => handleToggleStatus(checked, record.user_id)}
        />
      ),
      sorter: (a, b) => a.user_status - b.user_status,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record) => (
        <IconButton
          icon={<EyeFilled />}
          type="primary"
          tooltip="View"
          text="View"
          handleClick={() => navigate(`/single-claim-analyst/?user_id=${record.user_id}`)}
        />
      ),
    },
  ];

  return (
    <Card className="reportCard">
      <Row gutter={16}>
        <Col>
          <div className="d-flex align-items-center">
            <p className="tableHeaderText">Claim Analysts Listing</p>
          </div>
          <div>
            <p className="paragSubtitle">{inspections.length} Claim Analysts</p>
          </div>
        </Col>
      </Row>

      <Divider />
      <Table
        bordered
        className="clientsDataTable"
        tableLayout="auto"
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={inspections}
        loading={loading}
        locale={{
          emptyText: loading ? (
            <Skeleton.Input
              style={{ marginTop: "2px", height: "48px", padding: "0px" }}
              block
              active
            />
          ) : (
            <Empty />
          ),
        }}
        pagination={{
          current: page,
          pageSize,
          onChange: (newPage, newPageSize) => {
            setPage(newPage);
            setPageSize(newPageSize);
          },
          position: ["bottomCenter"],
        }}
      />
    </Card>
  );
};

export default ClaimAnalyst;
