import React, { useState, useEffect } from 'react';
import PageHeader from '../../../components/PageHeader';
import FirstStep from '../NwfaInstallationInspectionReport/FirstStep';
import SecondStep from '../NwfaInstallationInspectionReport/SecondStep';
import ThirdStep from '../NwfaInstallationInspectionReport/ThirdStep';
import FourthStep from '../NwfaInstallationInspectionReport/FourthStep';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { Form, Button, notification, Row, Col, Skeleton } from 'antd';
import moment from 'moment';
import * as inspectorService from '../../../services/InspectorDataService';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../store/actions/inspection';
import { useNavigate } from 'react-router-dom';
import * as createClientService from '../../../services/AllClients';
import * as getClientdropService from '../../../services/getClientdrop';
import { FilePdfOutlined, CheckCircleOutlined } from '@ant-design/icons';
import * as NwfaSandFinishService from '../../../services/createNwfaService';
import * as SingleRequestServices from '../../../services/SingleRequest';
import ReportSpin from '../../../components/ReportSpinner';

const NwfaInstallationInspection = () => {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const reduxData = useSelector((state) => state.Inspection.reportDetails);
  const Data = useSelector((state) => state.Inspection.reqId);
  const [loading, setLoading] = useState(true);
  const [clientDropdown, setclientDropdown] = useState([]);
  const [vals, setVals] = useState({});
  const [loadings, setLoadings] = useState(false);
  const [SpinnerReport, setSpinnerReport] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [calOpen, setCalOpen] = useState(false);
  const [calOpen1, setCalOpen1] = useState(false);
  const [calOpen2, setCalOpen2] = useState(false);
  const [calOpen3, setCalOpen3] = useState(false);
  const [calOpen4, setCalOpen4] = useState(false);
  const [calOpen5, setCalOpen5] = useState(false);
  const [calOpen6, setCalOpen6] = useState(false);
  const [calOpen7, setCalOpen7] = useState(false);
  const [calOpen8, setCalOpen8] = useState(false);
  const [calOpen9, setCalOpen9] = useState(false);
  const [calOpen10, setCalOpen10] = useState(false);
  const [calOpen11, setCalOpen11] = useState(false);
  const [calOpen12, setCalOpen12] = useState(false);
  const [calOpen13, setCalOpen13] = useState(false);
  const [calOpen14, setCalOpen14] = useState(false);
  const [calOpen15, setCalOpen15] = useState(false);
  const [calOpen16, setCalOpen16] = useState(false);
  const [calOpen17, setCalOpen17] = useState(false);
  const [footerLogo, setFooterLogo] = useState([]);
  const [bathArea, setBathArea] = useState([
    { bath_area: '', bath_area_unit: '' },
  ]);
  const [masterBedroomArea, setMasterBedroomArea] = useState([
    { master_bedroom_area: '', master_bedroom_area_unit: '' },
  ]);
  const [values, setValues] = useState({
    add_testing_information: '',
    conclusion_cause: '',
    masterbedroom: '',
    powderBath: '',
    h_bi_ins_date: '',
    h_bui_occupied: 'HideThisField',
    building_type: '',
    building_style: '',
    application_method_glue_down: '',
    claim_number: '',
    inspection_name: '',
    inspection_cp: '',
    inspection_date: '',
    comm_party_drop: '',
    below_meter_used: '',
    commisioning_name: '',
    commisioning_party_street: '',
    // commisioning_party_address: "",
    commisioning_party_city: '',
    commisioning_party_state: '',
    commisioning_party_country: '',
    commisioning_party_zip: '',
    commisioning_party_contact_number: '',
    commisioning_party_email: '',
    homeowner_Name: '',
    homeowner_street: '',
    // homeowner_address: "",
    any_special_or_unique_use: '',
    homeowner_city: '',
    homeowner_state: '',
    homeowne_country: '',
    homeowner_zip: '',
    homeowner_contact_number: '',
    homeowner_email: '',
    flooring_contractor_Name: '',
    flooring_contractor_street: '',
    // flooring_contractor_address: "",
    flooring_contractor_city: '',
    flooring_contractor_state: '',
    flooring_contractor_country: '',
    flooring_contractor_zip: '',
    flooring_contractor_contact_number: '',
    flooring_contractor_email: '',
    manufacturer_name: '',
    manufacturer_street: '',
    //manufacturer_address: "",
    manufacturer_city: '',
    manufacturer_state: '',
    manufacturer_country: '',
    manufacturer_zip: '',
    manufacturer_contact_info: '',
    manufacturer_title: '',
    manufacturer_contact_number: '',
    manufacturer_email: '',
    retailer_name: '',
    retailer_street: '',
    // retailer_address: "",
    retailer_city: '',
    retailer_state: '',
    retailer_country: '',
    retailer_zip: '',
    retailer_contact_info: '',
    retailer_title: '',
    retailer_contact_number: '',
    retailer_email: '',
    jobsite_date: '',
    jobsite_time: '',
    property_dropdown: '',
    build_type_other: '',
    other_building_style: '',
    jobsite__age_building: '',
    h_bui_cons:  'HideThisField',
    closing_date: '',
    moving_date: '',
    operational_date: '',
    adults: '',
    childrens: '',
    pets: '',
    concerns: '',
    date_sold_to_end_user: '',
    date_deliver_to_jobsite: '',
    date_of_installation: '',
    date_completion: '',
    date_occupied: '',
    date_completion_first: '',
    date_reported: '',
    clean_product: '',
    seam_overlapped: '',
    mois_mtr_usedsettings: '',
    reported_to: '',
    how_often: '',
    unusual_events: '',
    process_acclimation: '',
    temp_during_ins: '',
    rh_during_ins: '',
    hvac_oper_during_storage: 'HideThisField',
    hvac_oper_during_install: 'HideThisField',
    loc_des_stor_area: '',
    mois_meter_type: '',
    mois_cont_name: '',
    mois_species_cor: '',
    mois_content_reading: '',
    mois_cont_model: '',
    car_i2_inst_type: '',
    car_i2_other_inst_mthd: '',
    inst_method_fastner_type: '',
    inst_method_length: '',
    inst_method_gauge: '',
    inst_method_pne_air_pres: '',
    inst_method_nailers_used_floor_install: '',
    inst_method_type_brand_nailers: '',
    inst_method_type_underlayment_used: '',
    inst_method_type_movement_with_floor_sys: '',
    inst_method_type_add_ins_inf: '',
    inst_metho_flatn_measured: '',
    inst_method_Toler: '',
    inst_method_Toler3: '',
    inst_method_add_ins: '',
    adhesive_sys_used: '',
    spread_rate: '',
    mois_cont_sys_used: '',
    type_trowel_used: '',
    trowel_used: '',
    subfloor_measured_flat: 'HideThisField',
    num_trowel_used: '',
    meth_flat_meas: '',
    glue_tol: '',
    glu_tolerance: '',
    age_con: '',
    con_joints: '',
    glu_loc: '',
    glu_met_add: '',
    glu_am_ins_floor: '',
    glu_mov_with_flr_sys: '',
    glue_type: '',
    glu_add_inst_inf: '',
    float_adhesive_used: '',
    float_per_expa: '',
    float_underlayment_used: '',
    float_floor_measured_for_flat: 'HideThisField',
    float_metho_flatn_measured: '',
    flo_Toler: '',
    float_Toler3: '',
    float_movement_with_floor_sys: '',
    float_add_ins: '',
    float_ins_met: '',
    float_comm_party_comm: '',
    float_end_user_comm: '',
    float_floor_con_comm: '',
    float_oth_inv_comm: '',
    concern_con_exi_flr: '',
    concern_manuf: '',
    concern_spec: '',
    concern_spec_drop: '',
    flooringtype: '',
    concern_floor_thick: '',
    concern_add_inf_flr_con: '',
    produc_lot_number: '',
    concern_prod_date: '',
    exter_home_face: '',
    sqft_per_cont: '',
    total_sqft: '',
    square_foot_deli: '',
    per_flo_aff: '',
    app_over_radiant_heat: 'HideThisField',
    app_below_grade: 'HideThisField',
    date_home_completed: '',
    date_hvac_fully_oper: '',
    rele_lot_street: '',
    rele_lot_neigh: '',
    slope_al_meas: '',
    prox_to_stru: '',
    lot_drainage_away_foun: 'HideThisField',
    gutters_down: 'HideThisField',
    roof_overhang: 'HideThisField',
    soil_damp: 'HideThisField',
    wind_wells: '',
    signs_mois: 'HideThisField',
    shrubs_flowers: 'HideThisField',
    sprinklers_irri: 'HideThisField',
    Esta_lands: 'HideThisField',
    water_sour_swimm: 'HideThisField',
    visible_cracks: 'HideThisField',
    visible_exter: 'HideThisField',
    buil_over: '',
    hvac_sys_oper: 'HideThisField',
    type_heating_sys: '',
    type_heating_sys_oth: '',
    oth: '',
    typ: '',
    loc_heating: '',
    traff_use: '',
    humidification_dehum: 'HideThisField',
    h_dehu_syst: 'HideThisField',
    opera: 'HideThisField',
    date_last_serv: '',
    ther_control_sett: '',
    programma: 'HideThisField',
    data_logger: 'HideThisField',
    has_floor_full_cyc: 'HideThisField',
    other_trades: '',
    audible_noises: '',
    desc_sound: '',
    fac_finish_issue: '',
    rooms_entry: '',
    rooms_formal: '',
    rooms_kit: '',
    rooms_study: '',
    rooms_hall: '',
    rooms_library: '',
    rooms_great_room: '',
    rooms_powder_bath: '',
    rooms_living_room: '',
    rooms_dining_roo: '',
    rooms_nook: '',
    rooms_part: '',
    subfloor_rela_slab_surface: '',
    basement_evaluation: 'HideThisField',
    base_finish: '',
    walk_out: 'HideThisField',
    sump_pump: 'HideThisField',
    operat: '',
    hvac_vents_open_basement: 'HideThisField',
    wall_cracks_present: 'HideThisField',
    peeling_paint: 'HideThisField',
    floor_stains: 'HideThisField',
    rusty_nails: 'HideThisField',
    gapped_trim: 'HideThisField',
    locat: '',
    sign_mois_dam: '',
    specify_side_below: '',
    summ_phy_des: '',
    crawl_space_evelu: '',
    cond_present: 'HideThisField',
    stand_water_present: 'HideThisField',
    Alkali_dis: 'HideThisField',
    vapor_retarder_present: 'HideThisField',
    seams_sealed: 'HideThisField',
    meth_sealing: '',
    plast_tap: 'HideThisField',
    per_ground_cover: '',
    ins_bw_joists: 'HideThisField',
    ins_found_wall: 'HideThisField',
    vents_prese: 'HideThisField',
    num_vents: '',
    opens_closed: '',
    humidistat_ins: 'HideThisField',
    other_rooms: '',
    create_slab_evaluation: 'HideThisField',
    Moist_ret_use_slab: '',
    pre_tension: '',
    light_weight_gypsum: '',
    Normal_weight_con: '',
    wood_subfloor_evaluations: 'HideThisField',
    type_wood_subfloor: '',
    joist_spa: '',
    jois_ty: '',
    visible_rot: 'HideThisField',
    type_wood_subfloor_other: '',
    avr_mois_cont: 'HideThisField',
    avr_floor: '',
    avr_top: '',
    avr_back: '',
    avr_moist_meter: '',
    avr_name: '',
    avr_model: '',
    avr_meter: '',
    avr_num_reading: '',
    avr_hight_read: '',
    avr_low_read: '',
    avr_loca_high: '',
    mois_t_res: '',
    com_readings: '',
    oth_mois_test: '',
    avg_meas_indi_boa: '',
    meas_acr_board: '',
    twe_boards: '',
    avverage: '',
    avr_m_gaps: '',
    cupp_meas: '',
    bucking_meas: '',
    does_patr_issue: 'HideThisField',
    Co_s_test:'HideThisField',
    Mois_req_flo_man: '',
    Mois_req_flo_man_other: '',
    res_req: '',
    num_of_tets: '',
    l_of_tets: '',
    hvac_tsti: 'HideThisField',
    Temp_hvac: '',
    m_use: '',
    rel_humidity: '',
    crawlspace_test: 'HideThisField',
    crawl_temp: '',
    crawl_rel_humidity: '',
    crawl_m_use: '',
    dist_groun_un: '',
    j_m_cont: '',
    m_under_sub: '',
    mee_us: '',
    baseTesting: 'HideThisField',
    basementTemper: '',
    basemnt_rel_humidity: '',
    basmnt_m_use: '',
    basemnt_j_m_cont: '',
    bsmntm_under_sub: '',
    bsmntmee_us: '',
    Mois_req_adhesive_manu: '',
    res_req_adhes_man: '',
    num_of_tets_adh_manu: '',
    l_of_tets_adhe_manu: '',
    wood_sub_test: 'HideThisField',
    wood_mois_cont_read: '',
    wood_sub_low_read: '',
    wood_sub_low_read_hight_read: '',
    res_rt: '',
    other_moisture_test_cond: '',
    locati_of_tests: '',
    oth_moi_test_conducted: '',
    wood_mois_t_res: '',
    wood_mois_on_top: 'HideThisField',
    wood_mois_in_core: 'HideThisField',
    wood_mois_on_underside: 'HideThisField',
    mois_name: '',
    mois_mtrtype: '',
    mois_mtr_settings: '',
    mois_mod: '',
    joismoiscont: '',
    concern_con_exi_flr_other: '',
    com_reading: '',
    crawl_space_evelu_other: '',
    comm_party_drop_other: '',
    concern_spec_drop_width: '',
    footer_text_info: '',
    private_notes_togg: '',
    upload_sign_toggle: true,
    concern_spec_drop_other: '',
    inst_metho_floot_measured_flat: 'HideThisField',
    type_cooling_sys: '',
    air_on_typ: '',
    Mois_req_adhesive_man_other: '',
  });
  const [isOpen, setIsOpen] = useState({
    acField: false,
    buildField: false,
    buildingOther: false,
    buildingStyleOther: false,
    constructionField: false,
    installationOther: false,
    stapleField: false,
    installationGlue: false,
    installationFloat: false,
    cleanedField: false,
    base_finishField: false,
    create_slabField: false,
    wood_subfloorField: false,
    type_wood_open: false,
    avr_mois_contField: false,
    Co_s_testField: false,
    hvac_tstiField: false,
    crawlspace_testField: false,
    baseTestingField: false,
    wood_sub_testField: false,
    Mois_req_flo_open: false,
    concern_con_exi_flrField: false,
    type_heating_sysField: false,
    crawl_space_evelu_Field: false,
    comm_party_drop_Field: false,
    concern_spec_dropField: false,
    notesField: false,
    footerField: false,
    signatureField: false,
    concern_spec_drop_otherField: false,
    humidification_dehumField: false,
    humi_dehum_sysField: false,
  });
  useEffect(() => {
    if (
      Object.keys(reduxData).length !== 0 &&
      reduxData.constructor === Object &&
      reduxData.currentUrl !== ''
    ) {
      setLoading(true);
      fetchclientData();
      fetchInspectorData();
      setDefaultPanel();
      setLoading(false);
    } else {
      setLoading(true);
      fetchReqData();
      fetchclientData();
      fetchInspectorData();
      setDefaultPanel();
      console.warn('useeffect loading',loading)
      
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFill = (val) => {
    setValues({ ...values, ...val });
    setVals({ ...values, currentUrl: window.location.href, attachments: [] });

    dispatch(Actions.create_report(vals));
  };

  const fetchInspectorData = (params = {}) => {
   // setLoading(true);
    dispatch(inspectorService.getInspectorData())
      .then((res) => {
       // setLoading(true);
        values.signId = res.sign_for_report;
        values.signature = res.sign_for_report;
        values.signurl = res.signurl;
        values.footer_text = res.footer_text;
        if (res.footerlogos) {
          var sphValues = res.footerlogos;
          setFooterLogo(res.footerlogos);
          values.footerLogo = sphValues.map(function (e) {
            return e.toString();
          });
        }

       // setLoading(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
        setLoading(false);
      });
  };

  const handleBathChange = (e, index, input) => {
    console.warn({ e, index, input });
    let bath_area_val = '';
    if (input === 'bath_area') {
      bath_area_val = e.target.value;
    } else {
      bath_area_val = e;
    }
    const list = [...bathArea];
    list[index][input] = bath_area_val;
    setBathArea(list);
    console.warn('bathArea', bathArea);
  };

  const handleBathRemove = (index) => {
    const list = [...bathArea];
    list.splice(index, 1);
    setBathArea(list);
  };

  const handleBathAdd = () => {
    setBathArea([...bathArea, { bath_area: '', bath_area_unit: '' }]);
  };
  const handleMasterChange = (e, index, input) => {
    console.warn({ e, index, input });
    let master_bedroom_area_val = '';
    if (input === 'master_bedroom_area') {
      master_bedroom_area_val = e.target.value;
    } else {
      master_bedroom_area_val = e;
    }
    const list = [...masterBedroomArea];
    list[index][input] = master_bedroom_area_val;
    setMasterBedroomArea(list);
    console.warn('bathArea', bathArea);
  };

  const handleMasterRemove = (index) => {
    const list = [...masterBedroomArea];
    list.splice(index, 1);
    setMasterBedroomArea(list);
  };

  const handleMasterAdd = () => {
    setMasterBedroomArea([
      ...masterBedroomArea,
      { master_bedroom_area: '', master_bedroom_area_unit: '' },
    ]);
  };

  const handleChange = (input) => (e) => {
    console.warn('Event on select', input, e);
    console.warn('isOpen', isOpen);
    if (
      input === 'humidification_dehum' ||
      input === 'h_dehu_syst' ||
      input === 'h_bui_occupied' ||
      input === 'building_style' ||
      input === 'building_type' ||
      input === 'inst_method' ||
      input === 'car_i2_inst_type' ||
      input === 'flooringtype' ||
      input === 'loc_heating' ||
      input === 'basement_evaluation' ||
      input === 'base_finish' ||
      input === 'create_slab_evaluation' ||
      input === 'wood_subfloor_evaluations' ||
      input === 'type_wood_subfloor' ||
      input === 'avr_mois_cont' ||
      input === 'Co_s_test' ||
      input === 'hvac_tsti' ||
      input === 'crawlspace_test' ||
      input === 'baseTesting' ||
      input === 'Mois_req_adhesive_manu' ||
      input === 'concern_con_exi_flr' ||
      input === 'wood_sub_test' ||
      input === 'Mois_req_flo_man' ||
      input === 'crawl_space_evelu' ||
      input === 'type_heating_sys' ||
      input === 'comm_party_drop' ||
      input === 'private_notes_togg' ||
      input === 'upload_sign_toggle' ||
      input === 'footer_text_info' ||
      input === 'type_cooling_sys' ||
      input === 'hvac_oper_during_storage' ||
      input === 'hvac_oper_during_install' ||
      input === 'inst_metho_floot_measured_flat' ||
      input === 'float_floor_measured_for_flat' ||
      input === 'subfloor_measured_flat' ||
      input === 'app_over_radiant_heat' ||
      input === 'app_below_grade' ||
      input === 'lot_drainage_away_foun' ||
      input === 'water_sour_swimm' ||
      input === 'gutters_down' ||
      input === 'roof_overhang' ||
      input === 'soil_damp' ||
      input === 'signs_mois' ||
      input === 'sprinklers_irri' ||
      input === 'Esta_lands' ||
      input === 'visible_cracks' ||
      input === 'visible_exter' ||
      input === 'shrubs_flowers' ||
      input === 'data_logger' ||
      input === 'programma' ||
      input === 'has_floor_full_cyc' ||
      input === 'hvac_sys_oper' ||
      input === 'visible_rot' ||
      input === 'seams_sealed' ||
      input === 'vents_prese' ||
      input === 'cond_present' ||
      input === 'stand_water_present' ||
      input === 'Alkali_dis' ||
      input === 'vapor_retarder_present' ||
      input === 'plast_tap' ||
      input === 'ins_bw_joists' ||
      input === 'ins_found_wall' ||
      input === 'humidistat_ins' ||
      input === 'wall_cracks_present' ||
      input === 'walk_out' ||
      input === 'sump_pump' ||
      input === 'opera' ||
      input === 'hvac_vents_open_basement' ||
      input === 'peeling_paint' ||
      input === 'floor_stains' ||
      input === 'rusty_nails' ||
      input === 'gapped_trim' ||
      input === 'does_patr_issue' ||
      input === 'wood_mois_on_top' ||
      input === 'wood_mois_in_core' ||
      input === 'wood_mois_on_underside' ||
      input === 'h_bui_cons'
    ) {
      if (input === 'wood_mois_on_top') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'wood_mois_in_core') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'wood_mois_on_underside') {
        setValues({ ...values, [input]: e });
      }

      if (input === 'does_patr_issue') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'seams_sealed') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'vents_prese') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'cond_present') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'stand_water_present') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'Alkali_dis') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'vapor_retarder_present') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'plast_tap') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'ins_bw_joists') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'ins_found_wall') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'humidistat_ins') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'wall_cracks_present') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'walk_out') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'sump_pump') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'opera') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'hvac_vents_open_basement') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'peeling_paint') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'floor_stains') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'rusty_nails') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'gapped_trim') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'basement_evaluation') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            cleanedField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            cleanedField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }








      if (input === 'data_logger') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'programma') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'has_floor_full_cyc') {
        setValues({ ...values, [input]: e });
      }


      if (input === 'hvac_sys_oper') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'gutters_down') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'roof_overhang') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'soil_damp') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'signs_mois') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'sprinklers_irri') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'Esta_lands') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'visible_cracks') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'visible_exter') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'shrubs_flowers') {
        setValues({ ...values, [input]: e });
      }

      if (input === 'water_sour_swimm') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'lot_drainage_away_foun') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'app_over_radiant_heat') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'app_below_grade') {
        setValues({ ...values, [input]: e });
      }

      if (input === 'inst_metho_floot_measured_flat') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'float_floor_measured_for_flat') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'subfloor_measured_flat') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'hvac_oper_during_storage') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'hvac_oper_during_install') {
        setValues({ ...values, [input]: e });
      }




      if (input === 'type_cooling_sys' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          type_cooling_sysField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'type_cooling_sys' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          type_cooling_sysField: false,
        });
        setValues({ ...values, [input]: e });
      }

 
      if (input === 'loc_heating' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          acField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'loc_heating' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          acField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'flooringtype' && e === 'Plank') {
        setIsOpen({
          ...isOpen,
          concern_spec_dropField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'flooringtype' && e !== 'Plank') {
        setIsOpen({
          ...isOpen,
          concern_spec_dropField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'flooringtype' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          concern_spec_drop_otherField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'flooringtype' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          concern_spec_drop_otherField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'h_bui_cons') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            constructionField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            constructionField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'h_bui_occupied') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            buildField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            buildField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }
      if (input === 'h_dehu_syst') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            humi_dehum_sysField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            humi_dehum_sysField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'humidification_dehum') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            humidification_dehumField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            humidification_dehumField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }

      if (input === 'visible_rot') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'create_slab_evaluation') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            create_slabField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            create_slabField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'wood_subfloor_evaluations') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            wood_subfloorField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            wood_subfloorField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'avr_mois_cont') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            avr_mois_contField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            avr_mois_contField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'hvac_tsti') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            hvac_tstiField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            hvac_tstiField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'Co_s_test') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            Co_s_testField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            Co_s_testField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'wood_sub_test') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            wood_sub_testField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            wood_sub_testField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }

      if (input === 'crawlspace_test') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            crawlspace_testField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            crawlspace_testField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'baseTesting') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            baseTestingField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            baseTestingField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }

      if (input === 'building_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          buildingOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'building_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          buildingOther: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'building_style' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          buildingStyleOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'building_style' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          buildingStyleOther: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_i2_inst_type' && e === 'Glue down') {
        setIsOpen({
          ...isOpen,
          installationGlue: true,
          installationOther: false,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          installationGlue: false,
          installationOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e !== 'Glue down') {
        setIsOpen({
          ...isOpen,
          installationGlue: false,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          installationOther: false,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e === 'Nail/staple') {
        setIsOpen({
          ...isOpen,
          stapleField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e !== 'Nail/staple') {
        setIsOpen({
          ...isOpen,
          stapleField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'base_finish' && e === 'Finished') {
        setIsOpen({
          ...isOpen,
          base_finishField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'base_finish' && e !== 'Finished') {
        setIsOpen({
          ...isOpen,
          base_finishField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'type_wood_subfloor' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          type_wood_open: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'type_wood_subfloor' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          type_wood_open: false,
        });
        setValues({ ...values, [input]: e });
      }


      if (input === 'Mois_req_flo_man' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          Mois_req_flo_open: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'Mois_req_flo_man' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          Mois_req_flo_open: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'Mois_req_adhesive_manu' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          Mois_req_adhesive_manu_open: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'Mois_req_adhesive_manu' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          Mois_req_adhesive_manu_open: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'concern_con_exi_flr' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          concern_con_exi_flrField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'concern_con_exi_flr' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          concern_con_exi_flrField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'type_heating_sys' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          type_heating_sysField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'type_heating_sys' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          type_heating_sysField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'crawl_space_evelu' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          crawl_space_evelu_Field: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'crawl_space_evelu' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          crawl_space_evelu_Field: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'comm_party_drop' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          comm_party_drop_Field: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'comm_party_drop' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          comm_party_drop_Field: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'footer_text_info' && e === true) {
        setIsOpen({
          ...isOpen,
          footerField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'footer_text_info' && e === false) {
        setIsOpen({
          ...isOpen,
          footerField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'private_notes_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          notesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'private_notes_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          notesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'upload_sign_toggle' && e === true) {
        setIsOpen({
          ...isOpen,
          signatureField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'upload_sign_toggle' && e === false) {
        setIsOpen({
          ...isOpen,
          signatureField: false,
        });
        setValues({ ...values, [input]: e });
      }
    } else if (
      input === 'time_type' ||
      input === 'time_type1' ||
      input === 'measure_type1' ||
      input === 'trowel_used' ||
      input === 'powder_bath' ||
      input === 'formal_temp' ||
      input === 'measure_type2' ||
      input === 'measure_type3' ||
      input === 'measure_type4' ||
      input === 'measure_type5' ||
      input === 'measure_type6' ||
      input === 'measure_type7' ||
      input === 'measure_type8' ||
      input === 'measure_type9' ||
      input === 'measure_type10' ||
      input === 'measure_type11' ||
      input === 'measure_type12' ||
      input === 'measure_type14' ||
      input === 'measure_type13' ||
      input === 'measure_type15' ||
      input === 'measure_type16' ||
      input === 'measure_type17' ||
      input === 'property_dropdown' ||
      input === 'float_ins_met' ||
      input === 'temp_f_c' ||
      input === 'wind_wells' ||
      input === 'glue_type' ||
    
      input === 'exter_home_face' ||
      input === 'Normal_weight_con' ||
      input === 'traff_use' ||
      input === 'light_weight_gypsum' ||
      input === 'buil_over' ||
      input === 'Moist_ret_use_slab' ||
      input === 'pre_tension' ||
      input === 'specify_side_below' ||
      input === 'h_bi_ins_date' ||
      input === 'stepOneTogg' ||
      input === 'stepTwoTogg1' ||
      input === 'stepTwoTogg2' ||
      input === 'stepThreeTogg' ||
      input === 'stepFourTogg1' ||
      input === 'stepFourTogg2'
    ) {
      input === 'time_type' ||
      input === 'time_type1' ||
      input === 'powder_bath' ||
      input === 'formal_temp' ||
      input === 'measure_type1' ||
      input === 'measure_type2' ||
      input === 'measure_type3' ||
      input === 'measure_type4' ||
      input === 'measure_type5' ||
      input === 'measure_type6' ||
      input === 'measure_type7' ||
      input === 'measure_type8' ||
      input === 'measure_type9' ||
      input === 'measure_type10' ||
      input === 'measure_type11' ||
      input === 'measure_type12' ||
      input === 'measure_type14' ||
      input === 'measure_type15' ||
      input === 'measure_type16' ||
      input === 'measure_type13' ||
      input === 'measure_type17' ||
      input === 'temp_f_c' ||
      input === 'h_bi_ins_date' ||
      input === 'h_bi_ins_date'
        ? setValues({ ...values, [input]: e[0] })
        : setValues({ ...values, [input]: e });
    } else if (input === 'footerLogo') {
      //start
      let footerlogo1 = [];
      let id = e.target.value;
      footerlogo1 = [...footerLogo];
      if (footerlogo1?.includes(id)) {
        let footer_idex = footerlogo1.indexOf(id);
        delete footerlogo1[footer_idex];
      } else {
        footerlogo1.push(id);
      }
      footerlogo1 = footerlogo1?.filter((e) => e);
      setFooterLogo(footerlogo1);
      values.footerLogo = footerlogo1;
    } else {
      setValues({ ...values, [input]: e.target.value });
    }
    setVals({
      ...values,
      currentUrl: window.location.href,
      attachments: [],
    });
    dispatch(Actions.create_report(vals));
  };

  const handleDateformate = (e, name, type) => {
    console.log('ok', e);
    if (name === 'h_bi_ins_date') {
      if (type === 'date') {
        setCalOpen(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'concern_prod_date') {
      if (type === 'date') {
        setCalOpen17(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'jobsite_date') {
      if (type === 'date') {
        setCalOpen1(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'closing_date') {
      if (type === 'date') {
        setCalOpen3(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'moving_date') {
      if (type === 'date') {
        setCalOpen4(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'operational_date') {
      if (type === 'date') {
        setCalOpen5(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_sold_to_end_user') {
      if (type === 'date') {
        setCalOpen6(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_deliver_to_jobsite') {
      if (type === 'date') {
        setCalOpen7(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_of_installation') {
      if (type === 'date') {
        setCalOpen8(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_completion') {
      if (type === 'date') {
        setCalOpen9(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_occupied') {
      if (type === 'date') {
        setCalOpen10(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_completion_first') {
      if (type === 'date') {
        setCalOpen11(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_reported') {
      if (type === 'date') {
        setCalOpen12(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_home_completed') {
      if (type === 'date') {
        setCalOpen13(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_hvac_fully_oper') {
      if (type === 'date') {
        setCalOpen14(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_last_serv') {
      if (type === 'date') {
        setCalOpen15(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_completed') {
      if (type === 'date') {
        setCalOpen16(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
  };
  const handleEditor = (valEditor, label) => {
    setValues((l) => ({ ...l, [label]: valEditor }));
    setVals({
      ...values,
      currentUrl: window.location.href,
      attachments: [],
    });

    dispatch(Actions.create_report(vals));
  };
  const fetchclientData = (params = {}) => {
    dispatch(getClientdropService.getClientdrop())
      .then((res) => {
        setclientDropdown(res);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };

  const addClient = () => {
    console.warn('addClient', values);
    let clientData = {
      agent_claim_name: values.commisioning_name,
      comm_party_drop: values.comm_party_drop,
      comm_email: values.commisioning_party_email,
      street_name_number: values.street_name_number,
      state: values.commisioning_party_state,
      city: values.commisioning_party_city,
      zip_code: values.commisioning_party_zip,
      phone_no_party: values.commisioning_party_contact_number,
    };
    if (clientData.company_name_qr !== '' && clientData.comm_email !== '') {
      dispatch(createClientService.create_client(clientData))
        .then((res) => {
          console.warn('createClientService', res);
          if (res.status === 'client added') {
            fetchclientData();
            notification.open({
              message: 'Client successfully created!',
              description: 'You have successfully created a client.',
              icon: (
                <CheckCircleOutlined
                  style={{
                    color: '#108ee9',
                  }}
                />
              ),
            });
          } else {
            fetchclientData();
            notification.error({
              message: `${res.status}`,
              description:
                'The client that you are trying to create already exists.”',
              className: 'custom-class',
            });
          }
        })
        .catch((err) => {
          notification.error('This is an error message');
        });
    } else {
      notification.error('Client data is not valid');
    }
  };
  const setDefaultPanel = () => {
    values.stepOneTogg = ['1', '2', '3', '4', '5', '6', '7'];
    values.stepTwoTogg1 = ['1', '2', '3', '4'];
    values.stepTwoTogg2 = ['1', '2', '3', '4'];
    values.stepThreeTogg = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
    values.stepFourTogg1 = ['1', '2', '3', '4'];
    values.stepFourTogg2 = ['1', '2', '3', '4'];
  };
  function getStepContent(steps) {
    switch (steps) {
      case 0:
        return (
          <FirstStep
            handleChange={handleChange}
            values={values}
            isOpen={isOpen}
            clientDropdown={clientDropdown}
            handleFill={handleFill}
            addClient={addClient}
            handleDateformate={handleDateformate}
            calOpen={calOpen}
            setCalOpen={setCalOpen}
            calOpen1={calOpen1}
            setCalOpen1={setCalOpen1}
            calOpen2={calOpen2}
            setCalOpen2={setCalOpen2}
            calOpen3={calOpen3}
            setCalOpen3={setCalOpen3}
            calOpen4={calOpen4}
            setCalOpen4={setCalOpen4}
            calOpen5={calOpen5}
            setCalOpen5={setCalOpen5}
          />
        );
      case 1:
        return (
          <SecondStep
            handleChange={handleChange}
            handleDateformate={handleDateformate}
            values={values}
            isOpen={isOpen}
            loading={loading}
            calOpen6={calOpen6}
            setCalOpen6={setCalOpen6}
            calOpen7={calOpen7}
            setCalOpen7={setCalOpen7}
            calOpen8={calOpen8}
            setCalOpen8={setCalOpen8}
            calOpen9={calOpen9}
            setCalOpen9={setCalOpen9}
            calOpen10={calOpen10}
            setCalOpen10={setCalOpen10}
            calOpen11={calOpen11}
            setCalOpen11={setCalOpen11}
            calOpen12={calOpen12}
            setCalOpen12={setCalOpen12}
            handleEditor={handleEditor}
          />
        );
      case 2:
        return (
          <ThirdStep
            handleChange={handleChange}
            values={values}
            isOpen={isOpen}
            handleDateformate={handleDateformate}
            calOpen13={calOpen13}
            setCalOpen13={setCalOpen13}
            calOpen14={calOpen14}
            setCalOpen14={setCalOpen14}
            calOpen15={calOpen15}
            setCalOpen15={setCalOpen15}
            calOpen16={calOpen16}
            setCalOpen16={setCalOpen16}
            calOpen17={calOpen17}
            setCalOpen17={setCalOpen17}
            bathArea={bathArea}
            handleBathChange={handleBathChange}
            handleBathAdd={handleBathAdd}
            handleBathRemove={handleBathRemove}
            masterBedroomArea={masterBedroomArea}
            handleMasterChange={handleMasterChange}
            handleMasterAdd={handleMasterAdd}
            handleMasterRemove={handleMasterRemove}
          />
        );
      case 3:
        return (
          <FourthStep
            handleChange={handleChange}
            values={values}
            handleEditor={handleEditor}
            isOpen={isOpen}
            setLoadings={setLoadings}

          />
        );
      default:
        return <FirstStep />;
    }
  }

  function getSteps() {
    return [
      'GENERAL INFORMATION',
      'CLAIM HISTORY',
      'PHYSICAL DESCRIPTION ',
      'TESTING AND CONCLUSION',
    ];
  }
  const steps = getSteps();
  const onFinish = () => {
    console.log('Success:', values);
    values.req_id = Data.req_id;
    values.category = Data.floor_category;
    dispatch(Actions.create_report({}));
    // return;
    setLoadings(true);

    values.activeStep = activeStep;
    values.bathArea = bathArea;
    values.masterBedroomArea = masterBedroomArea;
    values.private_notes_togg =
      values.private_notes_togg === true ? 'yes' : 'no';
    values.upload_sign_toggle =
      values.upload_sign_toggle === true ? 'yes' : 'no';
    values.footer_text_info = values.footer_text_info === true ? 'yes' : 'no';
    // values.general_info_toggle = values.stepOneTogg?.includes("1")
    //   ? "yes"
    //   : "no";
    values.comm_party_toggle = values.stepOneTogg?.includes('1') ? 'yes' : 'no';
    values.homeowner_building_toggle = values.stepOneTogg?.includes('2')
      ? 'yes'
      : 'no';
    values.flooring_cont_toggle = values.stepOneTogg?.includes('3')
      ? 'yes'
      : 'no';
    values.manuf_info_toggle = values.stepOneTogg?.includes('4') ? 'yes' : 'no';
    values.retailer_info_toggle = values.stepOneTogg?.includes('5')
      ? 'yes'
      : 'no';
    values.jobsite_info_toggle = values.stepOneTogg?.includes('6')
      ? 'yes'
      : 'no';
    values.claim_history = values.stepTwoTogg1?.includes('1') ? 'yes' : 'no';
    values.jobsite_condition_ins_toggle = values.stepTwoTogg1?.includes('2')
      ? 'yes'
      : 'no';
    values.mois_content_ins_toggle = values.stepTwoTogg1?.includes('3')
      ? 'yes'
      : 'no';
    values.installation_toggle = values.stepTwoTogg1?.includes('4')
      ? 'yes'
      : 'no';
    values.comm_party_comm_toggle = values.stepTwoTogg2?.includes('1')
      ? 'yes'
      : 'no';
    values.enduser_toggle = values.stepTwoTogg2?.includes('2') ? 'yes' : 'no';
    values.flooring_contractor_info_toggle = values.stepTwoTogg2?.includes('3')
      ? 'yes'
      : 'no';
    values.other_invol_toggle = values.stepTwoTogg2?.includes('4')
      ? 'yes'
      : 'no';
    values.concern = values.stepThreeTogg?.includes('1') ? 'yes' : 'no';
    values.flooring_toggle = values.stepThreeTogg?.includes('2') ? 'yes' : 'no';
    values.rooms_being_toggle = values.stepThreeTogg?.includes('3')
      ? 'yes'
      : 'no';
    values.new_constructed_info_toggle = values.stepThreeTogg?.includes('4')
      ? 'yes'
      : 'no';
    values.exterior_info_toggle = values.stepThreeTogg?.includes('5')
      ? 'yes'
      : 'no';
    values.hvac_evalution_toggle = values.stepThreeTogg?.includes('6')
      ? 'yes'
      : 'no';
    values.special_consideration_info_toggle = values.stepThreeTogg?.includes(
      '7'
    )
      ? 'yes'
      : 'no';
    values.subfloor_evalution_toggle = values.stepThreeTogg?.includes('8')
      ? 'yes'
      : 'no';
    values.below_flooring_sys_toggle = values.stepThreeTogg?.includes('9')
      ? 'yes'
      : 'no';
    values.jobsite_con_ins = values.stepFourTogg1?.includes('1') ? 'yes' : 'no';
    values.floor_test_toggle = values.stepFourTogg1?.includes('2')
      ? 'yes'
      : 'no';
    values.substrate_testing_toggle = values.stepFourTogg1?.includes('3')
      ? 'yes'
      : 'no';
    values.below_flooring_system_toggle = values.stepFourTogg1?.includes('4')
      ? 'yes'
      : 'no';
    values.additional_testing_info_toggle = values.stepFourTogg2?.includes('1')
      ? 'yes'
      : 'no';
    values.apply_industry_stndr_toggle = values.stepFourTogg2?.includes('2')
      ? 'yes'
      : 'no';
    values.conclusion_cause_toggle = values.stepFourTogg2?.includes('3')
      ? 'yes'
      : 'no';
    values.footer_toggle = values.stepFourTogg2?.includes('4') ? 'yes' : 'no';
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed', errorInfo);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const fetchReqData = () => {
    dispatch(SingleRequestServices.getSingleRequest(Data.req_id))
      .then((res) => {
       // setLoading(true);
        values.client_type = res.client_type;
        values.claim_number = res.claim_number;
        values.concerns = res.concerns;
        values.dealer_name = res.dealer_name;
        values.dealer_phone = res.dealer_phone;
        values.dealer_email = res.dealer_email;
        values.homeowner_email = res.eu_email;
        values.homeowner_Name = res.eu_name;
        values.homeowner_street = res.eu_street;
        values.homeowner_city = res.eu_city;
        values.homeowne_country = res.eu_country;
        values.homeowner_state = res.eu_state;
        values.h_eu_phone_number = res.comm_party_phone_number;
        values.h_eu_alt_phone_number = res.eu_phone2;
        values.h_eu_email = res.eu_email;
        values.end_address = res.eu_address;
        values.homeowner_zip = res.eu_zip;
        values.end_mobile = res.eu_mob;
        values.homeowner_contact_number = res.eu_phone2;
        values.h_eu_contact = res.manufacturer_contact;
        values.product_date_of_ins = res.installation_date;
        values.date_problem_noticed = res.problem_date;
        values.h_bi_manu_name = res.manufacturer_name;
        values.style_name = res.style_name;
        values.product_color = res.color;
        values.total_claim_qt = res.quanty;
        values.area_room_ins = res.room_installed;
        values.manf_name = res.manufacturer_name;
        values.manf_contact = res.manufacturer_contact;
        values.manf_phone = res.manufacturer_phone_number;
        values.manf_email = res.manufacturer_email;
        values.product_manf = res.manufacturer_name;
        values.dealer_contact = res.dealer_contact;
        values.product_phone = res.dealer_phone;
        values.product_email = res.dealer_email;
        values.commisioning_name = res.comm_party_company_name;
        values.agent_claim_name = res.comm_party_first_last_name;
        values.commisioning_party_email = res.comm_party_email;
        values.commisioning_party_street = res.comm_party_street_name_number;
        values.commisioning_party_city = res.comm_party_city;
        values.commisioning_party_state = res.comm_party_state;
        values.commisioning_party_country = res.comm_party_country;
        values.commisioning_party_zip = res.comm_party_zip_code;
        values.commisioning_party_contact_number = res.comm_party_phone_number;
        setLoading(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...', loading);
        setLoading(false);
      });
  };
  const handleBack = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const saveDraft = () => {
    setSpinnerReport(true);
    onFinish();
    values.status = 'Draft';
    console.warn('saveDraftvalues', values);
    dispatch(NwfaSandFinishService.createNwfaInstReport(values))
      .then((res) => {
        console.warn('Submit', res);
        setSpinnerReport(false);
        navigate(
          '/update-nwfa-inspector-report/?report_id=' + res.data.report_id
        );
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const handleNavigate = () => {
    window.open(
      'https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/01/13063023/NWFA-Inspector-Installation-REPORT-Form-1.pdf',
      '_blank'
    );
  };

  const buttonSubmit = () => {
    setSpinnerReport(true);
    values.status = 'Saved';
    onFinish();
    dispatch(NwfaSandFinishService.createNwfaInstReport(values))
      .then((res) => {
        console.warn('Submit', res);
        notification.open({
          message: 'Report successfully created!',
          description: 'You have successfully created a report.',
          icon: (
            <CheckCircleOutlined
              style={{
                color: '#108ee9',
              }}
            />
          ),
        });
        //return;
        setSpinnerReport(false);
        navigate('/single-nwfa-installation/?report_id=' + res.data.report_id);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };
  return (
    <>
      {SpinnerReport ? (
        <ReportSpin />
      ) : (
        <>
          {loading ? (
            <div className="container-xxl">
            <div className="Skeleton_space">
              <Row gutter={16} className="mb-3" justify="center">
                <Col xs={24} md={6} xl={6} className="sklpad">
                  <Skeleton.Button
                    active
                    size="large"
                    shape="circle"
                    block={false}
                    className="abccc"
                  />
                  <Skeleton.Input
                    active
                    size="large"
                    block={false}
                    className="abccd"
                  />
                </Col>
                <Col xs={24} md={6} xl={6} className="sklpad">
                  <Skeleton.Button
                    active
                    size="large"
                    shape="circle"
                    block={false}
                    className="abccc"
                  />
                  <Skeleton.Input
                    active
                    size="large"
                    block={false}
                    className="abccd"
                  />
                </Col>
                <Col xs={24} md={6} xl={6} className="sklpad">
                  <Skeleton.Button
                    active
                    size="large"
                    shape="circle"
                    block={false}
                    className="abccc"
                  />
                  <Skeleton.Input
                    active
                    size="large"
                    block={false}
                    className="abccd"
                  />
                </Col>
                <Col xs={24} md={6} xl={6} className="sklpad">
                  <Skeleton.Button
                    active
                    size="large"
                    shape="circle"
                    block={false}
                    className="abccc"
                  />
                  <Skeleton.Input
                    active
                    size="large"
                    block={false}
                    className="abccd"
                  />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
            </div>
          </div>
          ) : (
            <>
              <div className="quickReportWrapper">
                <div className="pageHeader">
                  <PageHeader title="NWFA Installation Inspection Report" />
                </div>
                <div className="buttonSection">
                  <div className="container-xxl">
                    <Row>
                      <Col xs={24} md={24} className="text-end">
                        <Button
                          type="success"
                          className="btn inspectionAdd"
                          onClick={() => handleNavigate()}
                          icon={<FilePdfOutlined />}
                        >
                          Print Form
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="container-xxl CarpetDetailed">
                  <div className="reportInner">
                    <Box sx={{ my: 5 }}>
                      <div>
                        <Stepper
                          activeStep={activeStep}
                          alternativeLabel
                          nonLinear
                        >
                          {steps.map((label, index) => (
                            <Step key={label} className="cusutom-stepp">
                              <StepButton
                                color="inherit"
                                onClick={handleStep(index)}
                              >
                                {label}
                              </StepButton>
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                    </Box>
                    <Form
                      name="basic"
                      labelCol={{
                        span: 8,
                      }}
                      wrapperCol={{
                        span: 16,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="custom-form"
                    >
                      <div className="step-content">
                        {getStepContent(activeStep)}
                        <div className="custum-btngrp">
                          <Button
                            className="nxtbtn"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                          {activeStep === steps.length - 1 ? (
                            <Button
                              onClick={() => buttonSubmit()}
                              className="submitBtn"
                              loading={loadings}
                            >
                              Save And Continue To Preview Report
                            </Button>
                          ) : (
                            <Button
                              className="custom-nxtbtn"
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                            >
                              Next
                            </Button>
                          )}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>

              <div className="saveDraftBtn">
                <Button
                  className="float floatBtn sc_button_hover_fade"
                  id="draft"
                  name="update_button"
                  onClick={() => saveDraft()}
                      disabled={loadings}

                >
                  Save Draft
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default NwfaInstallationInspection;
