import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  Tag,
  Button,
  Modal,
  Spin,
  Form,
  Checkbox,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { SecondaryButton } from "../../components/Buttons/buttons";
import * as IndustryStandardsServices from "../../services/IndustryStandards";
import { useDispatch } from "react-redux";
import PagesHeader from "../../components/PageHeader";
import ButtonBar from "../../components/ButtonNavigation";
import { MEDIA_UPLOAD_URL } from "../../Helper";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {  useSelector } from "react-redux";
import SkletonIndustry from "./skletonIndustry";
import SunEditor from 'suneditor-react';
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link

} from "suneditor/src/plugins";

function IndustryStandards() {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState([]);
  const [search, setSearch] = useState("");
  const [modalIndustry, setModalIndustry] = useState(false);
  const [modalAddIndustry, setModalAddIndustry] = useState(false);
  const [modalUpdateIndustry, setModalUpdateIndustry] = useState(false);
  const [industryContent, setindustryContent] = useState("");
  const [standards, setStandards] = useState([]);
  const [filterStandards, setFilterStandards] = useState([]);
  const [singleStandardData, setSingleStandardData] = useState([]);
  // const [editorState1, setEditorState1] = useState(EditorState.createEmpty());
  const [editorState1, setEditorState1] = useState("");

  const [form] = Form.useForm();
  const userDetails = useSelector((state) => state.User.userDetails);
  const [currentUser, setCurrentUser] = useState({});
 

  console.warn({ standards: standards });

  useEffect(() => {
    setLoading(true);
    fetchData();
    setCurrentUser(userDetails);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const SunEditorOpts = {
    showPathLabel: false,
    imageMultipleFile: true,
    imageWidth: '150px',
    imageHeight: '150px',
    height: "auto",
    minHeight: "200px",
    requestHeaders: {
      "X-Sample": "sample"
    },
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      image,
      link
    ],
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle"],
      [
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript"
      ],
      ["fontColor", "hiliteColor"],
      ["removeFormat"],
      ["align", "horizontalRule","lineHeight"],
      ["outdent", "indent"],["list"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks", "preview",],
    ],
    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
    font: [
      "Arial",
      "Calibri",
      "Comic Sans",
      "Courier",
      "Garamond",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Palatino Linotype",
      "Segoe UI",
      "Tahoma",
      "Times New Roman",
      "Trebuchet MS",
      "Roboto"
    ]
  };
  const onImageUploadBefore = (files, info, uploadHandler) => {
    const formData = new FormData()
     const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    formData.append('file', files[0])
    axios.post(MEDIA_UPLOAD_URL, formData, config)
      .then((res)=>{
         const response = {
                // The response must have a "result" array.
                "result": [
                    {
                        "url": res.data.source_url,
                        "name": files[0].name,
                        "size": files[0].size
                    },
            ]}
        uploadHandler(response)
      })
  }
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(IndustryStandardsServices.getStandards())
      .then((res) => {
        setStandards(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const fetchSingleStandard = (pid) => {
    setLoading(true);
    let val = [];
    val.pid = pid;
    dispatch(IndustryStandardsServices.singleStandard(val))
      .then((res) => {
        console.info("setSingleStandardData", res);
        setSingleStandardData(res.data);
        setLoading(false);
        form.setFieldsValue({
          title: res.data.suggetion_title,
         
          suggetion_category: res.data.suggetion_categorylist,
          suggetion_field_name: res.data.suggetion_field_list,
          share_with_public : res.data.share_with_public
        });
        setEditorState1(res.data.suggetion_content);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };


  const handleClick = (item1) => {
    setModalIndustry(true);
    standards.filter((item) =>
      item.id === item1 ? setindustryContent(item) : ""
    );
  };


  const onEditorStateChange1 = (editorState1) => {
    setEditorState1(editorState1);
  };


  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  const onChangeLabel = (checkedValuesLabel) => {
    console.log("checked = ", checkedValuesLabel);
  };

  const handleClickModal = () => {
    setModalAddIndustry(true);
  };

  const onFinish = (values) => {
 
    enterLoading(0);

    
    values.share_with_public = values.share_with_public ? "yes" : "no";

    console.log("Success:", values);
    dispatch(IndustryStandardsServices.createStandards(values))
      .then((res) => {
        setLoading(false);
        setModalAddIndustry(false);
        window.location.reload();
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    setModalAddIndustry(false);
  };

  const handleModalUpdate = (pid) => {
    fetchSingleStandard(pid);
    setModalUpdateIndustry(true);
  };

  const handleCancelUpdate = () => {
    setModalUpdateIndustry(false);
  };

  const onFinishUpdate = (values) => {
    
    enterLoading(0);

    values.content = editorState1;
    values.share_with_public = values.share_with_public ? "yes" : "no";
    values.pid = singleStandardData.pid;
    console.log("Success:", values);
    dispatch(IndustryStandardsServices.updateStandards(values))
      .then((res) => {
        setLoading(false);
        setModalUpdateIndustry(false);
        window.location.reload();
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const deleteCitation = (pid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStandards(pid);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const deleteStandards = (pid) => {
    setLoading(true);
    let val = [];
    val.pid = pid;
    dispatch(IndustryStandardsServices.deleteStandards(val))
      .then((res) => {
        console.info("Delete", res);
        window.location.reload();
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const publicLibraryRoute = () => {
    navigate(`/public-industry-standards`);
  };

  useEffect(() => {
    console.info('Useffect Called')
    if(standards) {
      const filteredData = standards.filter((entry) =>
      entry?.suggetion_title?.toLowerCase().includes(search.toLowerCase())
      );
      setFilterStandards(filteredData); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="industryWrapper">
      <div className="industryHeader">
        <PagesHeader
          title="Industry Standards"
        />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={18} className="text-end">
              <ButtonBar />
            </Col>
            {currentUser?.user_role === 'um_inspector' ? 
              <Col xs={24} md={6} className="text-end">
                <Button
                  type='success'
                  className="btn inspectionAdd" 
                  onClick={() => handleClickModal()}
                  icon={<PlusOutlined />}
                >
                  New Industry Standard/Citation
                </Button>
              </Col> : ''
            }
          </Row>
        </div>
      </div>
      <div className="industryInnerWrapper">
        <div className="container-xxl">
          <div className="searchWrap">
            <Row gutter={16} justify="space-between">
              <Col xs={24} md={6}>
                <div className="searchWrapper">
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="Search..."
                    className="formControl"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={4} xs={24}>
                <div className="libraryBtnSection text-end">
                  <SecondaryButton
                    type="secondary"
                    handleClick={() => publicLibraryRoute()}
                    text="Public Library"
                    style={{ marginRight: "0" }}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="standardsWrapper">
            <Row gutter={[16, 16]}>
              {loading ? (
                <div className="spinDiv">
                  <SkletonIndustry />
                </div>
              ) : (
                <>
                  {filterStandards && search ? filterStandards?.map((item, index) => {
                    return (
                      <Col xs={24} md={8} key={index} className="gutter-row">
                        <Card className="industryCard">
                          <div className="industryCardBody">
                            <div className="cardTopSection">
                              <div className="headerSec">
                                <h3
                                  className="industryTitle"
                                  dangerouslySetInnerHTML={{
                                    __html: item.suggetion_title,
                                  }}
                                ></h3>
                                <p className="industryDesc">{item?.excerpt}</p>
                              </div>
                            </div>
                            <div className="contentSec">
                              <p className="categoryRow">
                                <span className="catLabel">Category- </span>
                                <p>
                                  {item?.suggetion_categorylist?.map(
                                    (catName, i) => {
                                      return (
                                        <Tag className="categoryTag" key={i}>
                                          <Link
                                            to={"#"}
                                            className="categoryLink"
                                          >
                                            {catName}
                                          </Link>
                                        </Tag>
                                      );
                                    }
                                  )}
                                </p>
                              </p>
                              <p className="categoryRow">
                                <span className="catLabel">Label- </span>
                                <span className="industryLable">
                                  {/* <Link to={"#"} className="lblLink">
                                    {item?.suggetion_field_list.join(", ")}
                                  </Link> */}
                                  {item.suggetion_field_list.map((suggLable, index) => {
                                    return(
                                      <>
                                        {suggLable}{", "}
                                      </>
                                    )
                                  })}
                                </span>
                              </p>
                              <p className="categoryRow">
                                <span className="catLabel">Created- </span>
                                <span className="industryLable">
                                  {item?.created_at}
                                </span>
                              </p>
                              <div className="btnGroup">
                                <Button
                                  type="text"
                                  id={item.id}
                                  className="indstryBtn viewBtn"
                                  onClick={() => handleClick(item.id)}
                                >
                                  View More
                                </Button>
                                <Button
                                  type="text"
                                  id={item.id}
                                  className="indstryBtn editBtn"
                                  onClick={() => handleModalUpdate(item.pid)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  type="text"
                                  id={item.id}
                                  className="indstryBtn deleteBtn"
                                  onClick={() => deleteCitation(item.pid)}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    );
                  }):
                  standards?.map((item, index) => {
                    return (
                      <Col xs={24} md={8} key={index} className="gutter-row">
                        <Card className="industryCard">
                          <div className="industryCardBody">
                            <div className="cardTopSection">
                              <div className="headerSec">
                                <h3
                                  className="industryTitle"
                                  dangerouslySetInnerHTML={{
                                    __html: item.suggetion_title,
                                  }}
                                ></h3>
                                <p className="industryDesc">{item?.excerpt}</p>
                              </div>
                            </div>
                            <div className="contentSec">
                              <p className="categoryRow">
                                <span className="catLabel">Category- </span>
                                <p>
                                  {item?.suggetion_categorylist?.map(
                                    (catName, i) => {
                                      return (
                                        <Tag className="categoryTag" key={i}>
                                          <Link
                                            to={"#"}
                                            className="categoryLink"
                                          >
                                            {catName}
                                          </Link>
                                        </Tag>
                                      );
                                    }
                                  )}
                                </p>
                              </p>
                              <p className="categoryRow">
                                <span className="catLabel">Label- </span>
                                <span className="industryLable">
                                  {item.suggetion_field_list.map((suggLable, index) => {
                                    return(
                                      <>
                                        {suggLable}{", "}
                                      </>
                                    )
                                  })}
                                </span>
                              </p>
                              <p className="categoryRow">
                                <span className="catLabel">Created- </span>
                                <span className="industryLable">
                                  {item?.created_at}
                                </span>
                              </p>
                              <div className="btnGroup">
                                <Button
                                  type="text"
                                  id={item.id}
                                  className="indstryBtn viewBtn"
                                  onClick={() => handleClick(item.id)}
                                >
                                  View More
                                </Button>
                                <Button
                                  type="text"
                                  id={item.id}
                                  className="indstryBtn editBtn"
                                  onClick={() => handleModalUpdate(item.pid)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  type="text"
                                  id={item.id}
                                  className="indstryBtn deleteBtn"
                                  onClick={() => deleteCitation(item.pid)}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
                </>
              )}
            </Row>
          </div>
        </div>
      </div>

      {/* Industry View Modal */}
      <Modal
        title={industryContent.suggetion_title}
        centered
        open={modalIndustry}
        closable={false}
        className="inspectionCategoryModal industryModal"
        width={680}
        footer={[
          <Button
            key="back"
            onClick={() => setModalIndustry(false)}
            className="submitBtn"
          >
            Ok
          </Button>,
        ]}
      >
        <div className="modalContent">
          <div className="formGroup">
            <p
              className="mcDescription"
              dangerouslySetInnerHTML={{
                __html: industryContent.suggetion_content,
              }}
            ></p>
          </div>
        </div>
      </Modal>

      {/* Industry Create Modal */}
      <Modal
        title="Add Industry Standards"
        centered
        open={modalAddIndustry}
        closable={true}
        className="inspectionCategoryModal"
        width={980}
        footer={false}
        onCancel={handleCancel}
      >
        <div className="modalContent">
          <Form
            name="industryForm"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="formGroup">
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input your title!",
                  },
                ]}
              >
                <Input placeholder="Title" className="formControl" />
              </Form.Item>
            </div>
            <div className="formGroup text-editor-wrapper">
              <Form.Item label="Industry Standard/Citation" name="content">
              
                  <SunEditor
                         
                          setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                          setOptions={SunEditorOpts}
                          onImageUploadBefore={onImageUploadBefore}
                          setContents={editorState1}
                          onChange={onEditorStateChange1}

                        />
              </Form.Item>
            </div>
            <div className="formGroup">
              <Form.Item
                label="Select Category"
                name="suggetion_category"
                rules={[
                  {
                    required: true,
                    message: "Please select category",
                  },
                ]}
              >
                <Checkbox.Group onChange={onChange} className="checkBoxGroup">
                  <Checkbox value="carpet">Carpet</Checkbox>
                  <Checkbox value="hardwood">Hardwood</Checkbox>
                  <Checkbox value="laminate">Laminate</Checkbox>
                  <Checkbox value="lvt">LVT</Checkbox>
                  <Checkbox value="quick-report">Quick Report</Checkbox>
                  <Checkbox value="resilient">Resilient</Checkbox>
                  <Checkbox value="tile">Tile</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </div>
            <div className="formGroup">
              <Form.Item
                label="Select Label"
                name="suggetion_field_name"
                rules={[
                  {
                    required: true,
                    message: "Please select label",
                  },
                ]}
              >
                <Checkbox.Group
                  onChange={onChangeLabel}
                  className="checkBoxGroupLabel"
                >
                  <Checkbox value="applicable-field-tests">
                    Applicable Field Tests
                  </Checkbox>
                  <Checkbox value="cause">Cause</Checkbox>
                  <Checkbox value="claim-history">Claim History</Checkbox>
                  <Checkbox value="conclusion">Conclusion</Checkbox>
                  <Checkbox value="confidential-information">
                    Confidential Information
                  </Checkbox>
                  <Checkbox value="footer-text">Footer Text</Checkbox>
                  <Checkbox value="industry_standards">
                    Industry Standards/Manufacturing Standards
                  </Checkbox>
                  <Checkbox value="observations_and_comments">
                    Observations and comments
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </div>
             {currentUser?.share_ind_stds === true ? 
               <div className="formGroup">
               <Form.Item
                name="share_with_public"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
                >
                <Checkbox>Share with public?</Checkbox>
                      
                </Form.Item>
              </div>
              :"" }
            <div className="formGroup">
              <Button
                className="btnPrimary"
                htmlType="submit"
                loading={loadings[0]}
              >
                Save and Exit
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Industry Update Modal */}
      <Modal
        title="Update Industry Standards"
        centered
        open={modalUpdateIndustry}
        closable={true}
        className="inspectionCategoryModal"
        width={980}
        footer={false}
        onCancel={handleCancelUpdate}
      >
        {loading ? (
          <div className="spinDiv">
            <Spin size="large" />
          </div>
        ) : (
          <div className="modalContent">
            <Form
              name="industryFormUpdate"
              layout="vertical"
              onFinish={onFinishUpdate}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              form={form}
            >
              <div className="formGroup">
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input your title!",
                    },
                  ]}
                >
                  <Input placeholder="Title" className="formControl" />
                </Form.Item>
              </div>
              <div className="formGroup text-editor-wrapper">
                <Form.Item label="Industry Standard/Citation" name="content">
                 
                   <SunEditor
                         
                          setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                          setOptions={SunEditorOpts}
                          onImageUploadBefore={onImageUploadBefore}
                          setContents={editorState1}
                          onChange={onEditorStateChange1}

                        />
                </Form.Item>
              </div>
              <div className="formGroup">
                <Form.Item
                  label="Select Category"
                  name="suggetion_category"
                  rules={[
                    {
                      required: true,
                      message: "Please select category",
                    },
                  ]}
                >
                  <Checkbox.Group onChange={onChange} className="checkBoxGroup">
                    <Checkbox value="carpet">Carpet</Checkbox>
                    <Checkbox value="hardwood">Hardwood</Checkbox>
                    <Checkbox value="laminate">Laminate</Checkbox>
                    <Checkbox value="lvt">LVT</Checkbox>
                    <Checkbox value="quick-report">Quick Report</Checkbox>
                    <Checkbox value="resilient">Resilient</Checkbox>
                    <Checkbox value="tile">Tile</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </div>

              <div className="formGroup">
                <Form.Item
                  label="Select Label"
                  name="suggetion_field_name"
                  rules={[
                    {
                      required: true,
                      message: "Please select label",
                    },
                  ]}
                >
                  <Checkbox.Group
                    onChange={onChangeLabel}
                    className="checkBoxGroupLabel"
                  >
                    <Checkbox value="applicable-field-tests">
                      Applicable Field Tests
                    </Checkbox>
                    <Checkbox value="cause">Cause</Checkbox>
                    <Checkbox value="claim-history">Claim History</Checkbox>
                    <Checkbox value="conclusion">Conclusion</Checkbox>
                    <Checkbox value="confidential-information">
                      Confidential Information
                    </Checkbox>
                    <Checkbox value="footer-text">Footer Text</Checkbox>
                    <Checkbox value="industry_standards">
                      Industry Standards/Manufacturing Standards
                    </Checkbox>
                    <Checkbox value="observations_and_comments">
                      Observations and comments
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </div>
              {currentUser?.share_ind_stds === true ? 
               <div className="formGroup">
               <Form.Item
                name="share_with_public"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
                >
                <Checkbox>Share with public?</Checkbox>
                      
                </Form.Item>
              </div>
              :"" }
              <div className="formGroup">
                <Button
                  className="btnPrimary"
                  htmlType="submit"
                  loading={loadings[0]}
                >
                  Save and Exit
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default IndustryStandards;
