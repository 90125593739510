import React from 'react';
import { Layout, Menu, Button } from 'antd';
import { HomeOutlined, TeamOutlined } from '@ant-design/icons';  // Ant Design icons
import { useNavigate } from 'react-router-dom';

const { Header, Content } = Layout;

export const PageLayout = ({ children, showManageInspectors = true }) => {
    const navigate = useNavigate();

    const menuItems = [
        { key: '1', path: '/', label: 'Home', icon: <HomeOutlined /> },
        ...(showManageInspectors
            ? [{ key: '2', path: '/field-service-inspectors', label: 'Manage Inspectors', icon: <TeamOutlined /> }]
            : [])
    ];

    return (
        <Layout className="inspectionWrapper" 
        // style={{ minHeight: '100vh' }}
        >
            {/* Sticky Header */}
            <Header style={{ position: 'sticky', top: 0, zIndex: 1000, width: '100%', background: '#fff', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                {/* Navigation Menu */}
                <Menu theme="light" mode="horizontal" className="container-xxl" style={{ marginBottom: '16px' }}>
                    {menuItems.map(item => (
                        <Menu.Item key={item.key} icon={item.icon}>
                            <Button type="link" onClick={() => navigate(item.path)}>
                                {item.label}
                            </Button>
                        </Menu.Item>
                    ))}
                </Menu>
            </Header>



            {/* Main Content */}
            <Content className="container-xxl" style={{ padding: '24px',
                //  backgroundColor: '#f0f2f5'
                  }}>
                {children}
            </Content>
        </Layout>
    );
};

export default PageLayout;
