import React, { useEffect, useState } from "react";
import { Row, Card, Col, Divider, Empty, Skeleton, List, Image, Typography } from "antd";
import PageHeader from "../../components/PageHeader";
import * as SingleRequestServices from '../../services/SingleRequest';
import "./FlooringManufacturerRequest";
import { useDispatch } from 'react-redux';
import FlooringManufacturerRequest from "./FlooringManufacturerRequest";

function SingleInspectionRequest() {
  var dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [requestData, setRequestData] = useState([]);
  const [attachments, setAttachments] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    setAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchData = (params = {}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const req_id = queryParams.get('req_id');

    setLoading(true);
    dispatch(SingleRequestServices.getSingleRequest(req_id))
      .then((res) => {
        setRequestData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
        setLoading(false);
      });
  };

  useEffect(() => {
    setAttachments(requestData?.img_array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData?.img_array]);

  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader title="Single Inspection Request Report" type="secondary" />
      </div>
      <div className="container-xxl">
        {loading ?
          (
            <div className="">
              <Row gutter={16} className="mb-1 mt-5 skel_req">
                <Col xs={24} md={2} xl={2} className="">
                  <Skeleton.Input block={true} active size="large" style={{ height: "120px" }} />
                </Col>
                <Col xs={24} md={2} xl={2} className="">

                </Col>
                <Col xs={24} md={3} xl={3} className="">
                  <Skeleton.Input block={true} active size="small" />
                  <hr></hr>
                </Col>
                <hr></hr>
                <Col xs={24} md={17} xl={17} className="">
                </Col>
              </Row>

              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />

              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>

              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
            </div>
          ) :
          (
            <div className="srWrapperInner">
              {requestData.request_type === 'Manual request' ?
                (
                  <Card className="reportCard">
                    <div className="reportCardBody">
                      <Empty description="NO DATA AVAILABLE FOR MANUAL REQUESTS! - YOU HAVE NOT REQUESTED AN INSPECTION BY FILLING THE FORM THROUGH THE WEBSITE." />
                    </div>
                  </Card>
                ) :
                requestData.request_type === 'Flooring dealer' ?
                  (
                    <Card className="reportCard">
                      <div className="reportCardBody">
                        <div className="inspectorInfo">
                          <Row gutter={30}>
                            <Col xs={24} md={8}>
                              <div className="inspectorDetails">
                                <div className="inspecDetailHead">
                                  <h5 className="inspcTitle">
                                    Client Type:- {requestData?.client_type}
                                  </h5>
                                </div>
                                <div className="inspcInfo"></div>
                              </div>
                            </Col>
                            <Col xs={24} md={8}>
                              <div className="inspectorDetails">
                                <div className="inspecDetailHead">
                                  <h5 className="inspcTitle">
                                    Claim Number:- {requestData?.claim_number}
                                  </h5>
                                </div>
                                <div className="inspcInfo"></div>
                              </div>
                            </Col>
                          </Row>
                        </div>

                        {/*  Dealer */}
                        <div className="inspectionDetails">
                          <Divider orientation="left">
                            <Typography.Text strong style={{ fontSize: '18px' }}>

                              Dealer
                            </Typography.Text>
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Dealer Name</label>
                                  <p>{requestData?.dealer_name}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Dealer Address</label>
                                  <p>{requestData?.dealer_address}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Dealer Contact</label>
                                  <p>{requestData?.dealer_contact}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Dealer Phone Number</label>
                                  <p>{requestData?.dealer_phone ? requestData?.dealer_phone : " -"}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Dealer Email</label>
                                  <p>{requestData?.dealer_email}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        {/* Inspection Site */}
                        <div className="inspectionDetails">
                          <Divider orientation="left">
                            <Typography.Text strong style={{ fontSize: '18px' }}>

                              Inspection Site
                            </Typography.Text>
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Homeowner Name</label>
                                  <p>{requestData?.eu_name}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={12}>
                                <div className="formGroup">
                                  <label>Address</label>
                                  <p>{requestData?.eu_address}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Zip Code</label>
                                  <p>{requestData?.eu_zip}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Phone Number</label>
                                  <p>{requestData?.homeowner_phone_number}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Second Phone Number</label>
                                  <p>{requestData?.homeowner_phone_number}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Email Address</label>
                                  <p>{requestData?.eu_email}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        {/* Product To Inspect  */}
                        <div className="inspectionDetails">
                          <Divider orientation="left">
                            <Typography.Text strong style={{ fontSize: '18px' }}>

                              Product To Inspect
                            </Typography.Text>
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Manufacturer Name</label>
                                  <p>{requestData?.manufacturer_name}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Style Name/Style Number</label>
                                  <p>{requestData?.style_name}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Color</label>
                                  <p>{requestData?.color}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Quantity</label>
                                  <p>{requestData?.quanty}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Subfloor Type</label>
                                  <p>{requestData?.subfloor_type ? requestData?.subfloor_type : '-'}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Underlayment</label>
                                  <p>{requestData?.underlayment}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Moisture Testing</label>
                                  <p>{requestData?.moisture_testing}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Meter of Method</label>
                                  <p>{requestData?.meter_of_method}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Test Results</label>
                                  <p>{requestData?.test_results}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        {/* Flooring Category */}
                        <div className="inspectionDetails">
                          <Divider orientation="left">
                            <Typography.Text strong style={{ fontSize: '18px' }}>

                              Flooring Category
                            </Typography.Text>
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Category</label>
                                  <p>{requestData?.category}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        {/* Concerns */}
                        <div className="inspectionDetails">
                          <Divider orientation="left">
                            <Typography.Text strong style={{ fontSize: '18px' }}>

                              Concerns
                            </Typography.Text>
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup">
                                  <label>Concerns</label>
                                  <p>{requestData?.concerns}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Type</label>
                                  <p>{requestData?.concern_type}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        {/* Installation Details */}
                        <div className="inspectionDetails">
                          <Divider orientation="left">
                            <Typography.Text strong style={{ fontSize: '18px' }}>

                              Installation Details
                            </Typography.Text>
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Installed by</label>
                                  <p>{requestData?.installed_by}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Installation Date</label>
                                  <p>{requestData?.installation_date}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Date Problem Noticed</label>
                                  <p>{requestData?.problem_date}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Room Installed</label>
                                  <p>{requestData?.room_installed}</p>
                                </div>
                              </Col>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Comments</label>
                                  <p>{requestData?.comments}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div >
                        <Card className="innerCard mb-3">
                          <div className="inspectionDetails">
                            <Col xs={24} md={24}>
                              <div className="formGroup">
                                <label>Uploaded Photos :</label>
                                <Row gutter={16}>

                                  {attachments &&
                                    Object.keys(attachments).map((keyName, index) => (
                                      <Col xs={24} md={8} key={index} className="my-2">
                                        <Image
                                          src={attachments[keyName]}
                                          alt="image1"
                                          className="img-fluid"
                                          preview
                                        />
                                      </Col>
                                    ))}
                                </Row>
                              </div>
                            </Col>
                          </div>
                        </Card>
                        <Row gutter={16}>
                          {requestData?.installation_instructions ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Installation Instructions :</label>
                                <p>
                                  <a href={requestData?.installation_instructions} target="_blank" rel="noreferrer" className="downloadLink">View installation instructions</a>
                                </p>
                              </div>
                            </Col> : ''
                          }
                          {requestData?.maintenence_instructions ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Care And Maintenance Instructions :</label>
                                <p>
                                  <a href={requestData?.maintenence_instructions} target="_blank" rel="noreferrer" className="downloadLink">View care And maintenance instructions</a>
                                </p>
                              </div>
                            </Col> : ''
                          }
                          {requestData?.warranty_information ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Warranty Information :</label>
                                <p>
                                  <a href={requestData?.warranty_information} target="_blank" rel="noreferrer" className="downloadLink">View warranty information</a>
                                </p>
                              </div>
                            </Col> : ''
                          }
                          {requestData?.hard_surface_checklist ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Hardsurface Checklist :</label>
                                <p>
                                  <a href={requestData?.hard_surface_checklist} target="_blank" rel="noreferrer" className="downloadLink">Hardsurface Checklist</a>
                                </p>
                              </div>
                            </Col> : ''
                          }
                        </Row>
                      </div >
                    </Card >
                  ) :
                  requestData.request_type === 'Flooring Manufacturer' ?
                    (
                      < FlooringManufacturerRequest
                        requestData={requestData}
                        attachments={attachments}
                      />

                    ) :
                    requestData.request_type === 'Home owner' ?
                      (
                        <Card className="reportCard">
                          <div className="reportCardBody">
                            <div className="inspectorInfo">
                              <Row gutter={30}>
                                {/* <Col xs={24} md={4}>
                            <div className="inspectorLogo">
                              <Image
                                src={Logo}
                                className="img-fluid"
                                alt="InspectorLogo"
                                preview={false}
                              />
                            </div>
                          </Col> */}
                                <Col xs={24} md={6}>
                                  <div className="inspectorDetails">
                                    <div className="inspecDetailHead">
                                      <h5 className="inspcTitle">
                                        Client Type:- {requestData?.client_type}
                                      </h5>
                                    </div>
                                    <div className="inspcInfo"></div>
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            {/*  Inspection Site */}
                            <div className="inspectionDetails">
                              <Divider orientation="left">
                                <Typography.Text strong style={{ fontSize: '18px' }}>

                                  Inspection Site
                                </Typography.Text>
                              </Divider>
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Homeowner Name</label>
                                      <p>{requestData?.eu_name}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={12}>
                                    <div className="formGroup">
                                      <label>Address</label>
                                      <p>{requestData?.eu_address ? requestData?.eu_address : '-'}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Zip Code</label>
                                      <p>{requestData?.eu_zip}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Phone Number</label>
                                      <p>{requestData?.eu_phone2}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Second Phone Number</label>
                                      <p>{requestData?.eu_phone2}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Email Address</label>
                                      <p>{requestData?.eu_email}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            {/* Dealer  */}
                            <div className="inspectionDetails">
                              <Divider orientation="left">
                                <Typography.Text strong style={{ fontSize: '18px' }}>

                                  Dealer
                                </Typography.Text>
                              </Divider>
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Dealer Name</label>
                                      <p>{requestData?.dealer_name}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Dealer Contact</label>
                                      <p>{requestData?.dealer_contact}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Dealer Phone Number</label>
                                      <p>{requestData?.dealer_phone}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            {/* Product to Inspect */}
                            <div className="inspectionDetails">
                              <Divider orientation="left">
                                <Typography.Text strong style={{ fontSize: '18px' }}>

                                  Product to Inspect
                                </Typography.Text>
                              </Divider>
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Manufacturer Name</label>
                                      <p>{requestData?.manufacturer_name}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Style Name/Number</label>
                                      <p>{requestData?.style_name ? requestData?.style_name : '-'}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Color</label>
                                      <p>{requestData?.color}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Quantity</label>
                                      <p>{requestData?.quanty}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Subfloor Type</label>
                                      <p>{requestData?.quanty}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Underlayment</label>
                                      <p>{requestData?.underlayment}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Moisture Testing</label>
                                      <p>{requestData?.moisture_testing}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Meter of Method</label>
                                      <p>{requestData?.meter_of_method}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Test Results</label>
                                      <p>{requestData?.test_results}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div >

                            {/* Flooring Category */}
                            < div className="inspectionDetails" >
                              <Divider orientation="left">
                                <Typography.Text strong style={{ fontSize: '18px' }}>

                                  Flooring Category
                                </Typography.Text>
                              </Divider >
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Category</label>
                                      <p>{requestData?.category ? requestData?.category : '-'}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div >

                            {/* Concern */}
                            < div className="inspectionDetails" >
                              <Divider orientation="left">
                                <Typography.Text strong style={{ fontSize: '18px' }}>

                                  Concerns
                                </Typography.Text>
                              </Divider >
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={24}>
                                    <div className="formGroup">
                                      <label>Concerns</label>
                                      <p>{requestData?.concerns}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Type</label>
                                      <p>{requestData?.concern_type}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div >

                            {/* Installation Details  */}
                            < div className="inspectionDetails" >
                              <Divider orientation="left">
                                <Typography.Text strong style={{ fontSize: '18px' }}>

                                  Installation Details
                                </Typography.Text>
                              </Divider >
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Installed By</label>
                                      <p>{requestData?.installed_by}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Installation Date</label>
                                      <p>{requestData?.installation_date}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Date Problem Noticed</label>
                                      <p>{requestData?.problem_date}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Room Installed</label>
                                      <p>{requestData?.room_installed}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div >

                            {/* Number of adults */}
                            < div className="inspectionDetails" >
                              <Divider orientation="left">
                                <Typography.Text strong style={{ fontSize: '18px' }}>

                                  Numbers of Adults, Children, Dogs, Cats
                                </Typography.Text>
                              </Divider >
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Number of Adults</label>
                                      <p>{requestData?.adults}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Number of Children</label>
                                      <p>{requestData?.childrens}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Number of Dogs</label>
                                      <p>{requestData?.dogs}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Number of Cats</label>
                                      <p>{requestData?.cats}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div >

                            {/* Type of Heating or Cooling */}
                            < div className="inspectionDetails" >
                              <Divider orientation="left">
                                <Typography.Text strong style={{ fontSize: '18px' }}>

                                  Type of Heating or Cooling
                                </Typography.Text>
                              </Divider >
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Type of Heating and Cooling</label>
                                      <p>{requestData?.typeof_heating_cooling}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Is there and operating a humidifier on the furnace?</label>
                                      <p>{requestData?.humidifier_on_furnace}</p>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Do you run a dehumidifier?</label>
                                      <p>{requestData?.dehumidifier}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div >

                            {/* Comments */}
                            < div className="inspectionDetails" >
                              <Divider orientation="left">
                                <Typography.Text strong style={{ fontSize: '18px' }}>

                                  Comments
                                </Typography.Text>
                              </Divider >
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={24}>
                                    <div className="formGroup">
                                      <label>Comments</label>
                                      <p>{requestData?.comments}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div >
                            <Card className="innerCard mb-3">
                              <div className="inspectionDetails">
                                <Col xs={24} md={24}>
                                  <div className="formGroup">
                                    <label>Uploaded Photos :</label>
                                    <Row gutter={16}>
                                      {attachments &&
                                        Object.keys(attachments).map((keyName, index) => (
                                          <Col xs={24} md={8} key={index} className="my-2">
                                            <Image
                                              src={attachments[keyName]}
                                              alt="image1"
                                              className="img-fluid"
                                              preview
                                            />
                                          </Col>
                                        ))}
                                    </Row>
                                  </div>
                                </Col>
                              </div>
                            </Card>
                            <Row gutter={16}>
                              {requestData?.installation_instructions ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Installation Instructions :</label>
                                    <p>
                                      <a href={requestData?.installation_instructions} target="_blank" rel="noreferrer" className="downloadLink">View installation instructions</a>
                                    </p>
                                  </div>
                                </Col> : ''
                              }
                              {requestData?.maintenence_instructions ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Care And Maintenance Instructions :</label>
                                    <p>
                                      <a href={requestData?.maintenence_instructions} target="_blank" rel="noreferrer" className="downloadLink">View care And maintenance instructions</a>
                                    </p>
                                  </div>
                                </Col> : ''
                              }
                              {requestData?.warranty_information ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Warranty Information :</label>
                                    <p>
                                      <a href={requestData?.warranty_information} target="_blank" rel="noreferrer" className="downloadLink">View warranty information</a>
                                    </p>
                                  </div>
                                </Col> : ''
                              }
                            </Row>
                          </div >
                        </Card >
                      ) :
                      (
                        ''
                      )
              }
            </div >
          )
        }
      </div >
    </div >
  );
}

export default SingleInspectionRequest;
