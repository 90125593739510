import {
    Row,
    Col,
    Image,
    Typography,
    Tooltip,
    message
} from 'antd';
import {
    DownloadOutlined,
    FullscreenOutlined,
    PlusOutlined,
    MinusOutlined,
    RotateRightOutlined
} from '@ant-design/icons';

const ImageGallery = ({ attachments }) => {
    // Utility function to download image
    const handleDownloadImage = (imageUrl) => {
        try {
            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = imageUrl;

            // Extract filename from URL or generate a unique name
            const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
            link.download = filename || `image_${Date.now()}.jpg`;

            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            message.success('Image downloaded successfully');
        } catch (error) {
            console.error('Download failed:', error);
            message.error('Failed to download image');
        }
    };

    // Render nothing if no attachments
    if (!attachments || Object.keys(attachments).length === 0) {
        return (
            <Typography.Text
                type="secondary"
                style={{
                    textAlign: 'center',
                    display: 'block',
                    padding: '20px'
                }}
            >
                No images available
            </Typography.Text>
        );
    }

    return (
        <div>
            <Image.PreviewGroup>
                <Row gutter={[16, 16]}>
                    {Object.keys(attachments).map((keyName, index) => (
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            key={index}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                                }}
                            >
                                <Image
                                    width="100%"
                                    height={250}
                                    src={attachments[keyName]}
                                    alt={`Attachment ${index + 1}`}
                                    style={{
                                        objectFit: 'cover',
                                        transition: 'transform 0.3s ease-in-out'
                                    }}
                                    preview={{
                                        // Customize preview actions
                                        toolbarRender: (
                                            _,
                                            {
                                                transform: { scale },
                                                currentSrc,
                                                actions: { onZoomIn, onZoomOut, onRotateRight, onRotateLeft }
                                            }
                                        ) => (
                                            <div>
                                                <Tooltip title="Download">
                                                    <DownloadOutlined
                                                        onClick={() => handleDownloadImage(currentSrc)}
                                                        style={{
                                                            fontSize: '20px',
                                                            color: 'white',
                                                            margin: '0 10px',
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Zoom In">
                                                    <PlusOutlined onClick={onZoomIn} />
                                                </Tooltip>
                                                <Tooltip title="Zoom Out">
                                                    <MinusOutlined onClick={onZoomOut} />
                                                </Tooltip>
                                                <Tooltip title="Rotate Right">
                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                </Tooltip>
                                            </div>
                                        ),
                                        // Optional: add mask for hover effect
                                        mask: (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '100%',
                                                    backgroundColor: 'rgba(0,0,0,0.5)'
                                                }}
                                            >
                                                <FullscreenOutlined
                                                    style={{
                                                        fontSize: '24px',
                                                        color: 'white'
                                                    }}
                                                />
                                            </div>
                                        )
                                    }}
                                />
                                {/* Image Caption */}
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                        color: 'white',
                                        padding: '8px',
                                        textAlign: 'center'
                                    }}
                                >
                                    <Typography.Text
                                        style={{
                                            color: 'white',
                                            fontSize: '12px'
                                        }}
                                    >
                                        Attachment {index + 1}
                                    </Typography.Text>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Image.PreviewGroup>
        </div>
    );
};

export default ImageGallery;