import React,{useState} from "react";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import * as getManufPanelData from "../../services/getManuPanelData";
import {
    Row,
    Col,
    Card,
    Divider,
    Spin
   } from "antd";

const SingleDealer = () => {
  const [loading, setLoading] = useState(true);
  const [dealerData, setDealerData] = useState();

  var dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    fetchDealer();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const queryParams = new URLSearchParams(window.location.search);
  const user_id = queryParams.get("user_id");
  
 const fetchDealer = (params = {}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const user_id = queryParams.get("user_id");
    setLoading(true);
    dispatch(getManufPanelData.getManufPanelReportData(user_id))
      .then((res) => {
        setDealerData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  }

  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader
          title="Inspection Request | Professional Report"
          type="secondary"
          text="Edit Form"
          handleClick={() =>
            navigate("/dealer-update/?user_id=" + user_id)}
        />
      </div>
      <div className="container-xxl">
        <div className="srWrapperInner">
        {loading ? (
            <div className="spinDiv fullHeight">
              <Spin size="large" />
            </div>
          ) : (
          <Card className="reportCard">
            <div className="reportCardBody">
              <div className="formSection">
                <div className="inspectionSection">
                  <div className="inspecDetailHead">
                    <h5 className="inspcTitle">Dealer Details</h5>
                  </div>
                  <Divider />
                  <div className="inspectorDetails">
                    <div className="inovicecard">
                      <div className="inspecContent">
                      {dealerData?.company_information === 'enable' ?
                        <div className="inspectionDetails">
                         <Divider orientation="left" plain>
                          Company Information
                         </Divider>
                         <div className="reportData">
                          <Row gutter={16}>
                          {dealerData?.company_name ? 
                         <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Company Name</label>
                              <p>{dealerData?.company_name}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.website ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Website</label>
                              <p>{dealerData?.website}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.email ?
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Email</label>
                              <p>{dealerData?.email}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.phone ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Phone</label>
                              <p>{dealerData?.phone}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.company_type_ ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Company Type</label>
                              <p>{dealerData?.company_type_}</p>
                            </div>
                          </Col>
                          : ''}
                        </Row>
                      </div>
                    </div> : ''
                      }
                    </div> 
                    </div>
                    <div className="inovicecard">
                      <div className="inspecContent">
                      {dealerData?.mailing_and_shipping_address === "enable" ? 
                          <div className="inspectionDetails">
                         <Divider orientation="left" plain>
                         Mailing and Shipping Address
                         </Divider>
                       <div className="reportData">
                          <Row gutter={16}>
                            {dealerData?.po_box ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>PO Box</label>
                              <p>{dealerData?.po_box}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.street ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Street</label>
                              <p>{dealerData?.street}</p>
                            </div>
                          </Col>
                          : ''}
                           {dealerData?.country ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Country</label>
                              <p>{dealerData?.country}</p>
                            </div>
                          </Col>
                          : ''}


                          {dealerData?.city ?
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>City</label>
                              <p>{dealerData?.city}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.states ?
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>State</label>
                              <p>{dealerData?.states}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.zip_code ?
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Zip Code</label>
                              <p>{dealerData?.zip_code}</p>
                            </div>
                          </Col>
                          : ''}
                        </Row>
                      </div>
                    </div> : ''
                      }
                    </div>
                    </div>
                    <div className="inovicecard">
                      <div className="inspecContent">
                     <div className="inspectionDetails">
                         <Divider orientation="left" plain>
                         Login Information
                         </Divider>
                       <div className="reportData">
                          <Row gutter={16}>
                          {dealerData?.user_name ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Username</label>
                              <p>{dealerData?.user_name}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.password ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Password</label>
                              <p>{dealerData?.password}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.first_name ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>First Name</label>
                              <p>{dealerData?.first_name}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.last_name ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Last Name</label>
                              <p>{dealerData?.last_name}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.forgot_email ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Email Address</label>
                              <p>{dealerData?.forgot_email}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.phone_personal ?  
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Phone</label>
                              <p>{dealerData?.phone_personal}</p>
                            </div>
                          </Col>
                          : ''}
                          {dealerData?.send_copies_of_reports_or_invoice_to ? 
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Send copies of reports or invoice to</label>
                              <p>{dealerData?.send_copies_of_reports_or_invoice_to}</p>
                            </div>
                          </Col>
                          : ''}
                        </Row>
                      </div>
                    </div> 
                   </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>)}
          </div>
      </div>
    </div>
  );
};

export default SingleDealer;
