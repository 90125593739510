import React from 'react';
import { PageLayout } from '../../components/Layout/PageLayout';
import FsmanagerPanelDataTable from '../../datatables/Manufacturer/FsmanagerPanelDataTable';

const FsmanagerPanel = () => {
  return (
    <PageLayout>
      <div className="inspectionWrapInner">
        <FsmanagerPanelDataTable />
      </div>
    </PageLayout>
  );
};

export default FsmanagerPanel;