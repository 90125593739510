import React from 'react';
import { Typography } from 'antd';
import './improvedSectionTitle.css'; // Assuming you've saved the CSS in this file

const { Title } = Typography;

const SectionTitle = ({ children }) => (
    <Title level={3} className="section-title">
        {children}
    </Title>
);

export default SectionTitle;

// Usage in your form component:
// <SectionTitle>Claim Information</SectionTitle>