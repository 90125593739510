import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export function getInspectionFsmanagerPanelData() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'getinspection_requests_fsmanagerpanel')
        .then((res) => {
          // You can log or process the data here if needed
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function deletInspection(req_id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + "delete_inspection", req_id)
        .then((res) => {
          console.log("Res", res);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}
