import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export const inspectorService = {
    // Get all inspectors
    getAllInspectors: async (status = null) => {
        try {
            const params = status ? { status } : {};
            const response = await Http.get(`${INSPECTION_URL}fs-inspectors`, { params });
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },

    // Get single inspector
    getInspector: async (id) => {
        try {
            const response = await Http.get(`${INSPECTION_URL}fs-inspectors/${id}`);
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },

    // Create inspector
    createInspector: async (inspectorData) => {
        try {
            const response = await Http.post(`${INSPECTION_URL}fs-inspectors`, inspectorData);
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },

    // Update inspector
    updateInspector: async (id, inspectorData) => {
        try {
            const response = await Http.put(`${INSPECTION_URL}fs-inspectors/${id}`, inspectorData);
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },

    // Update inspector status
    updateInspectorStatus: async (id, status, reason = '') => {
        try {
            const response = await Http.put(`${INSPECTION_URL}fs-inspectors/${id}/status`, {
                status,
                reason
            });
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },

    // Assign inspector
    assignInspector: async (requestId, inspectorId) => {
        try {
            const response = await Http.post(`${INSPECTION_URL}assign-inspector`, {
                request_id: requestId,
                inspector_id: inspectorId,
            });
            return response.data;
        } catch (error) {
            throw handleApiError(error);
        }
    },

};

// Error handler
const handleApiError = (error) => {
    if (error.response) {
        const message = error.response.data.message || 'An error occurred';
        throw new Error(message);
    } else if (error.request) {
        throw new Error('No response received from server');
    } else {
        throw new Error('Error setting up request');
    }
};

export default inspectorService;