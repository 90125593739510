import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Spin } from 'antd';

const { Paragraph } = Typography;

const ImageGridPreviewPage = ({ uploadedImages = [] }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Clear any previous timeouts
        let timeoutId;

        if (uploadedImages.length > 0) {
            // Set loading to false if images are present
            setLoading(false);
        } else if (loading) {
            // Only set a timeout if loading is still true
            timeoutId = setTimeout(() => setLoading(false), 1000);
            console.warn('timeoutId', timeoutId);
        }

        // Cleanup function to clear the timeout
        return () => clearTimeout(timeoutId);

    }, [uploadedImages, loading]); // Include 'loading' in dependencies

    return (
        <div style={{ padding: 20 }}>
            {loading ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <Spin size="large" tip="Loading images..." />
                </div>
            ) : (
                <div className="inspectionDetails">
                    <Row gutter={[16, 16]}>
                        <Image.PreviewGroup>
                            {uploadedImages.map((image, index) => (
                                <Col xs={24} sm={12} md={8} lg={6} key={index}>
                                    <div className="imageContainer">
                                        <Image
                                            src={image.url}
                                            preview
                                            alt={`Preview ${image.id}`}
                                            className="img-fluid"
                                            style={{ width: '100%', borderRadius: 8 }}
                                        />
                                        {image.desc && (
                                            <Paragraph className="description" ellipsis={{ rows: 2 }}>
                                                {image.desc}
                                            </Paragraph>
                                        )}
                                    </div>
                                </Col>
                            ))}
                        </Image.PreviewGroup>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default ImageGridPreviewPage;
