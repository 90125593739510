import React from 'react';
import { Form, Input, Select } from 'antd';

const InspectorForm = ({ form, editMode, inspectorData }) => {
    return (
        <Form
            form={form}
            layout="vertical"
            className="space-y-4"
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please enter name' }]}
            >
                <Input style={styles.formItem} className="w-full border-gray-300 focus:ring focus:ring-blue-200 rounded-md" />
            </Form.Item>

            <Form.Item
                name="email"
                label="Email"
                rules={[
                    { required: true, message: 'Please enter email' },
                    { type: 'email', message: 'Please enter a valid email' }
                ]}
            >
                <Input style={styles.formItem}
                    type="email"
                    className="w-full border-gray-300 focus:ring focus:ring-blue-200 rounded-md"
                    disabled={editMode}
                />
            </Form.Item>

            <Form.Item
                name="phone_number"
                label="Phone Number"
            >
                <Input style={styles.formItem}
                    type="tel"
                    className="w-full border-gray-300 focus:ring focus:ring-blue-200 rounded-md"
                />
            </Form.Item>

            <Form.Item
                name="location"
                label="Location"
                rules={[{ required: true, message: 'Please enter location' }]}
            >
                <Input style={styles.formItem} className="w-full border-gray-300 focus:ring focus:ring-blue-200 rounded-md" />
            </Form.Item>

            <Form.Item
                name="expertise"
                label="Expertise"
                rules={[{ required: true, message: 'Please enter expertise' }]}
            >
                <Input style={styles.formItem} className="w-full border-gray-300 focus:ring focus:ring-blue-200 rounded-md" />
            </Form.Item>

            <Form.Item
                name="inspector_type"
                label="Inspector Type"
                rules={[{ required: true, message: 'Please select inspector type' }]}
            >
                <Select
                    placeholder="Select inspector type"
                    className="w-full"
                >
                    <Select.Option value="internal">Internal</Select.Option>
                    <Select.Option value="external">External</Select.Option>
                </Select>
            </Form.Item>

            {editMode && inspectorData && (
                <div className="bg-gray-50 p-4 rounded-md text-sm text-gray-600 mt-4">
                    <p>Registered: {new Date(inspectorData.date_registered).toLocaleString()}</p>
                </div>
            )}
        </Form>
    );
};

const styles = {
    formItem: {
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for input fields
        borderRadius: '6px', // Rounded corners for input fields
        border: '1px solid #d9d9d9', // Light border around inputs
        padding: '8px', // Padding inside the inputs
    },
    input: {
        borderRadius: '6px', // Rounded corners for the inputs themselves
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Soft shadow for inputs
        border: '1px solid #d9d9d9',
    },
};
export default InspectorForm;