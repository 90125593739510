import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Card,
  Form,
  Divider,
  Button,
  message,
  Spin,
  Upload,
} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import * as EmailDataService from "../../services/sendEmail";
import { useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MEDIA_UPLOAD_URL } from "../../Helper";

const SendDicision = () => {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [emailData, setEmailData] = useState([]);
  const [loadings, setLoadings] = useState([]);
  const [additionalAttachments, setAdditionalAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const req_id = queryParams.get("req_id");

    dispatch(EmailDataService.getEmailData(req_id))
      .then((res) => {
        form.setFieldsValue({
          email_to : res.comm_email
        });
        console.info("Email data", res);
        setEmailData(res);
        console.info("checked data", res.checked);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    fmData.append("file", file);
    try {
      const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
      onSuccess("Ok");
      let uploadAttachments = [...additionalAttachments];
      uploadAttachments.push(res?.data.id);
      setAdditionalAttachments(uploadAttachments);
      console.info("Attachments", uploadAttachments);
      console.log("server res: ", res);
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
      console.warn(error);
    }
  };




  const handleChangeImage = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = (values) => {
    enterLoading(1);
    values.additionalAttachments = additionalAttachments;
    values.report_id = emailData.report_id;

    dispatch(EmailDataService.sendDecision(values))
      .then((res) => {
        setEmailData(res);
        if (res === "success") {
          message.success("Email sent successfully.");
          setTimeout(() => {
            navigate(`/inspection-reports`);
          }, 1000);
        } else {
          message.error("Email not sent.");
        }
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { TextArea } = Input;

  return (
    <div className="quickReportWrapper emailWrapper">
      <div className="pageHeader">
        <PageHeader title="Create Email" />
      </div>
      {loading ? (
        <div className="spinDiv fullHeight">
          <Spin size="large" />
        </div>
      ) : (
        <div className="container">
          <Row gutter={16} justify="center">
            <Col xs={24} md={16}>
              <div className="reportInner">
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                  form={form}
                >
                  <Card className="reportCard">
                    <div className="reportCardBody">
                      <div className="formSection">
                        <div className="inspectionSection">
                          <div className="headerTitle">
                            <p>Send Decision letter to dealer?</p>
                          </div>
                          <Divider />
                          <div className="inspecContent">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Email To :"
                                    name="email_to"
                                    className="mb-0"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter email",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Email"
                                      className="formControl"
                                    />
                                  </Form.Item>
                                  <p className="inputnote mb-3">
                                    Only 1 Email Address per field, comma
                                    separated emails are no longer supported.
                                  </p>
                                </div>
                              </Col>
                              <Col xs={24} md={24}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Send to CC Email :"
                                    name="cc_email"
                                  >
                                    <Input
                                      placeholder="Send to CC Email"
                                      className="formControl"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={24}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Send a Copy To :"
                                    name="copy_to"
                                  >
                                    <Input
                                      placeholder="Send a Copy To"
                                      className="formControl"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={24}>
                                <div className="formGroup">
                                  <Form.Item label="Message :" name="msg-claim">
                                    <TextArea
                                      rows={4}
                                      placeholder="Message"
                                      className="formControl"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={10} className="mailTemplateBtn" justify="space-between">
                              <Col
                                xs={24}
                                md={12}
                                className="d-flex justify-content-between bottomBtnRow"
                              >
                                <Upload
                                  name="additionalAttachments"
                                  showUploadList={true}
                                  customRequest={uploadImage}
                                  onChange={handleChangeImage}
                                  className="me-2"
                                  accept='.doc,.docx,application/pdf'
                                >
                                  <Button
                                    icon={<UploadOutlined />}
                                  >
                                    Attach Decision Letter
                                  </Button>
                                </Upload>
                              </Col>
                              <Col
                                xs={24}
                                md={4}
                                className="d-flex justify-content-between bottomBtnRow"
                              >
                                <Button
                                  className="bottomBtn"
                                  htmlType="submit"
                                  loading={loadings[1]}
                                >
                                  Email Now
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default SendDicision;
