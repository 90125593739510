import React, { useState, useEffect } from 'react';
import PageHeader from '../../../components/PageHeader';
import FirstStep from '../NwfaInstallationInspectionReport/FirstStep';
import SecondStep from '../NwfaInstallationInspectionReport/SecondStep';
import ThirdStep from '../NwfaInstallationInspectionReport/ThirdStep';
import FourthStep from '../NwfaInstallationInspectionReport/FourthStep';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { Form, Button, Row, Col, Skeleton, notification } from 'antd';
import moment from 'moment';
import * as inspectorService from '../../../services/InspectorDataService';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../store/actions/inspection';
import { useNavigate } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import * as NwfaSandFinishService from '../../../services/createNwfaService';
import * as getClientdropService from '../../../services/getClientdrop';
import * as createClientService from '../../../services/AllClients';
import ReportSpin from '../../../components/ReportSpinner';

const UpdateNwfaInstallation = () => {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const Data = useSelector((state) => state.Inspection.reqId);
  const [loading, setLoading] = useState(false);
  const [vals, setVals] = useState({});
  const [loadings, setLoadings] = useState(false);
  const [SpinnerReport, setSpinnerReport] = useState(false);
  const [clientDropdown, setclientDropdown] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [calOpen, setCalOpen] = useState(false);
  const [calOpen1, setCalOpen1] = useState(false);
  const [calOpen2, setCalOpen2] = useState(false);
  const [calOpen3, setCalOpen3] = useState(false);
  const [calOpen4, setCalOpen4] = useState(false);
  const [calOpen5, setCalOpen5] = useState(false);
  const [calOpen6, setCalOpen6] = useState(false);
  const [calOpen7, setCalOpen7] = useState(false);
  const [calOpen8, setCalOpen8] = useState(false);
  const [calOpen9, setCalOpen9] = useState(false);
  const [calOpen10, setCalOpen10] = useState(false);
  const [calOpen11, setCalOpen11] = useState(false);
  const [calOpen12, setCalOpen12] = useState(false);
  const [calOpen13, setCalOpen13] = useState(false);
  const [calOpen14, setCalOpen14] = useState(false);
  const [calOpen15, setCalOpen15] = useState(false);
  const [calOpen16, setCalOpen16] = useState(false);
  const [calOpen17, setCalOpen17] = useState(false);
  const [footerLogo, setFooterLogo] = useState([]);
  const [bathArea, setBathArea] = useState([
    { bath_area: '', bath_area_unit: '' },
  ]);
  const [masterBedroomArea, setMasterBedroomArea] = useState([
    { master_bedroom_area: '', master_bedroom_area_unit: '' },
  ]);
  const [values, setValues] = useState({
    apply_industry_stndr: '',
    flooringtype: '',
    add_testing_information: '',
    conclusion_cause: '',
    masterbedroom: '',
    powderBath: '',
    h_bi_ins_date: '',
    h_bui_occupied: '',
    building_type: '',
    building_style: '',
    claim_num: '',
    inspection_name: '',
    inspection_cp: '',
    inspection_date: '',
    below_meter_used: '',
    application_method_glue_down: '',
    comm_party_drop: '',
    commisioning_name: '',
    commisioning_party_street: '',
    commisioning_party_address: '',
    commisioning_party_city: '',
    commisioning_party_state: '',
    commisioning_party_country: '',
    commisioning_party_zip: '',
    commisioning_party_contact_number: '',
    commisioning_party_email: '',
    homeowner_Name: '',
    homeowner_street: '',
    homeowner_address: '',
    homeowner_city: '',
    homeowner_state: '',
    homeowne_country: '',
    homeowner_zip: '',
    homeowner_contact_number: '',
    homeowner_email: '',
    flooring_contractor_Name: '',
    flooring_contractor_street: '',
    flooring_contractor_address: '',
    flooring_contractor_city: '',
    flooring_contractor_state: '',
    flooring_contractor_country: '',
    flooring_contractor_zip: '',
    flooring_contractor_contact_number: '',
    flooring_contractor_email: '',
    manufacturer_name: '',
    manufacturer_street: '',
    manufacturer_address: '',
    manufacturer_city: '',
    manufacturer_state: '',
    manufacturer_country: '',
    manufacturer_zip: '',
    manufacturer_contact_info: '',
    manufacturer_title: '',
    manufacturer_contact_number: '',
    manufacturer_email: '',
    retailer_name: '',
    retailer_street: '',
    retailer_address: '',
    retailer_city: '',
    retailer_state: '',
    retailer_zip: '',
    retailer_contact_info: '',
    retailer_title: '',
    retailer_contact_number: '',
    retailer_email: '',
    jobsite_date: '',
    jobsite_time: '',
    property_dropdown: '',
    build_type_other: '',
    other_building_style: '',
    jobsite__age_building: '',
    h_bui_cons: '',
    closing_date: '',
    moving_date: '',
    operational_date: '',
    adults: '',
    childrens: '',
    pets: '',
    concerns: '',
    date_sold_to_end_user: '',
    date_deliver_to_jobsite: '',
    date_of_installation: '',
    date_completion: '',
    date_occupied: '',
    date_completion_first: '',
    date_reported: '',
    clean_product: '',
    reported_to: '',
    how_often: '',
    unusual_events: '',
    process_acclimation: '',
    temp_during_ins: '',
    rh_during_ins: '',
    hvac_oper_during_storage: '',
    hvac_oper_during_install: '',
    loc_des_stor_area: '',
    mois_meter_type: '',
    mois_cont_name: '',
    mois_species_cor: '',
    mois_content_reading: '',
    mois_cont_model: '',
    car_i2_inst_type: '',
    car_i2_other_inst_mthd: '',
    inst_method_fastner_type: '',
    inst_method_length: '',
    inst_method_gauge: '',
    inst_method_pne_air_pres: '',
    inst_method_nailers_used_floor_install: '',
    inst_method_type_brand_nailers: '',
    inst_method_type_underlayment_used: '',
    inst_method_type_movement_with_floor_sys: '',
    inst_method_type_add_ins_inf: '',
    inst_metho_flatn_measured: '',
    inst_method_Toler: '',
    inst_method_Toler3: '',
    inst_method_add_ins: '',
    adhesive_sys_used: '',
    spread_rate: '',
    mois_cont_sys_used: '',
    type_trowel_used: '',
    trowel_used: '',
    subfloor_measured_flat: '',
    num_trowel_used: '',
    meth_flat_meas: '',
    glue_tol: '',
    glu_tolerance: '',
    age_con: '',
    con_joints: '',
    glu_loc: '',
    glu_met_add: '',
    glu_am_ins_floor: '',
    glu_mov_with_flr_sys: '',
    glue_type: '',
    glu_add_inst_inf: '',
    float_adhesive_used: '',
    float_per_expa: '',
    float_underlayment_used: '',
    float_floor_measured_for_flat: '',
    float_metho_flatn_measured: '',
    flo_Toler: '',
    float_Toler3: '',
    float_movement_with_floor_sys: '',
    float_add_ins: '',
    float_ins_met: '',
    float_comm_party_comm: '',
    float_end_user_comm: '',
    float_floor_con_comm: '',
    float_oth_inv_comm: '',
    concern_con_exi_flr: '',
    concern_manuf: '',
    concern_spec: '',
    concern_spec_drop: '',
    concern_floor_thick: '',
    concern_add_inf_flr_con: '',
    produc_lot_number: '',
    concern_prod_date: '',
    exter_home_face: '',
    sqft_per_cont: '',
    total_sqft: '',
    square_foot_deli: '',
    per_flo_aff: '',
    app_over_radiant_heat: '',
    app_below_grade: '',
    date_home_completed: '',
    date_hvac_fully_oper: '',
    rele_lot_street: '',
    rele_lot_neigh: '',
    slope_al_meas: '',
    prox_to_stru: '',
    lot_drainage_away_foun: '',
    gutters_down: '',
    roof_overhang: '',
    soil_damp: '',
    wind_wells: '',
    signs_mois: '',
    shrubs_flowers: '',
    sprinklers_irri: '',
    Esta_lands: '',
    water_sour_swimm: '',
    visible_cracks: '',
    visible_exter: '',
    buil_over: '',
    hvac_sys_oper: '',
    type_heating_sys: '',
    type_heating_sys_oth: '',
    oth: '',
    typ: '',
    loc_heating: '',
    traff_use: '',
    humidification_dehum: '',
    opera: '',
    date_last_serv: '',
    ther_control_sett: '',
    programma: '',
    data_logger: '',
    has_floor_full_cyc: '',
    other_trades: '',
    audible_noises: '',
    any_special_or_unique_use: '',
    desc_sound: '',
    seam_overlapped: '',
    mois_mtr_usedsettings: '',
    fac_finish_issue: '',
    rooms_entry: '',
    rooms_formal: '',
    rooms_kit: '',
    rooms_study: '',
    rooms_hall: '',
    rooms_library: '',
    rooms_great_room: '',
    rooms_powder_bath: '',
    rooms_living_room: '',
    rooms_dining_roo: '',
    rooms_nook: '',
    rooms_part: '',
    subfloor_rela_slab_surface: '',
    basement_evaluation: '',
    base_finish: '',
    walk_out: '',
    sump_pump: '',
    operat: '',
    hvac_vents_open_basement: '',
    wall_cracks_present: '',
    peeling_paint: '',
    floor_stains: '',
    rusty_nails: '',
    gapped_trim: '',
    locat: '',
    sign_mois_dam: '',
    specify_side_below: '',
    summ_phy_des: '',
    crawl_space_evelu: '',
    cond_present: '',
    stand_water_present: '',
    Alkali_dis: '',
    vapor_retarder_present: '',
    seams_sealed: '',
    meth_sealing: '',
    plast_tap: '',
    per_ground_cover: '',
    ins_bw_joists: '',
    ins_found_wall: '',
    vents_prese: '',
    num_vents: '',
    opens_closed: '',
    humidistat_ins: '',
    other_rooms: '',
    create_slab_evaluation: '',
    Moist_ret_use_slab: '',
    pre_tension: '',
    light_weight_gypsum: '',
    Normal_weight_con: '',
    wood_subfloor_evaluations: '',
    type_wood_subfloor: '',
    joist_spa: '',
    jois_ty: '',
    visible_rot: '',
    type_wood_subfloor_other: '',
    avr_mois_cont: '',
    avr_floor: '',
    avr_top: '',
    avr_back: '',
    avr_moist_meter: '',
    avr_name: '',
    avr_model: '',
    avr_meter: '',
    avr_num_reading: '',
    avr_hight_read: '',
    avr_low_read: '',
    avr_loca_high: '',
    mois_t_res: '',
    com_readings: '',
    oth_mois_test: '',
    avg_meas_indi_boa: '',
    meas_acr_board: '',
    twe_boards: '',
    avverage: '',
    avr_m_gaps: '',
    cupp_meas: '',
    bucking_meas: '',
    does_patr_issue: '',
    Co_s_test: '',
    Mois_req_flo_man: '',
    Mois_req_flo_man_other: '',
    res_req: '',
    num_of_tets: '',
    l_of_tets: '',
    hvac_tsti: '',
    Temp_hvac: '',
    m_use: '',
    rel_humidity: '',
    crawlspace_test: '',
    crawl_temp: '',
    crawl_rel_humidity: '',
    crawl_m_use: '',
    dist_groun_un: '',
    j_m_cont: '',
    m_under_sub: '',
    mee_us: '',
    baseTesting: '',
    basementTemper: '',
    basemnt_rel_humidity: '',
    basmnt_m_use: '',
    basemnt_j_m_cont: '',
    bsmntm_under_sub: '',
    bsmntmee_us: '',
    Mois_req_adhesive_manu: '',
    res_req_adhes_man: '',
    num_of_tets_adh_manu: '',
    l_of_tets_adhe_manu: '',
    wood_sub_test: '',
    wood_mois_cont_read: '',
    wood_sub_low_read: '',
    wood_sub_low_read_hight_read: '',
    res_rt: '',
    other_moisture_test_cond: '',
    locati_of_tests: '',
    oth_moi_test_conducted: '',
    wood_mois_t_res: '',
    wood_mois_on_top: '',
    wood_mois_in_core: '',
    wood_mois_on_underside: '',
    mois_name: '',
    mois_mtrtype: '',
    mois_mtr_settings: '',
    mois_mod: '',
    joismoiscont: '',
    concern_con_exi_flr_other: '',
    com_reading: '',
    crawl_space_evelu_other: '',
    comm_party_drop_other: '',
    concern_spec_drop_width: '',
    footer_text_info: '',
    private_notes_togg: '',
    upload_sign_toggle: true,
    concern_spec_drop_other: '',
    inst_metho_floot_measured_flat: '',
    type_cooling_sys: '',
    air_on_typ: '',
    Mois_req_adhesive_man_other: '',
    h_dehu_syst: '',
  });
  const [isOpen, setIsOpen] = useState({
    humi_dehum_sysField: false,
    acField: false,
    buildField: false,
    buildingOther: false,
    buildingStyleOther: false,
    constructionField: false,
    installationOther: false,
    stapleField: false,
    installationGlue: false,
    installationFloat: false,
    cleanedField: false,
    base_finishField: false,
    create_slabField: false,
    wood_subfloorField: false,
    type_wood_open: false,
    avr_mois_contField: false,
    Co_s_testField: false,
    hvac_tstiField: false,
    crawlspace_testField: false,
    baseTestingField: false,
    wood_sub_testField: false,
    Mois_req_flo_open: false,
    concern_con_exi_flrField: false,
    type_heating_sysField: false,
    crawl_space_evelu_Field: false,
    comm_party_drop_Field: false,
    concern_spec_dropField: false,
    notesField: false,
    footerField: false,
    signatureField: false,
    concern_spec_drop_otherField: false,
    humidification_dehumField: false,
  });

  const fetchclientData = (params = {}) => {
    dispatch(getClientdropService.getClientdrop())
      .then((res) => {
        setclientDropdown(res);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };

  const queryParams = new URLSearchParams(window.location.search);
  const report_id = queryParams.get('report_id');

  useEffect(() => {
    setLoading(true);
    fetchInspectorData();
    fetchclientData();
    getReportData();
    // setDefaultPanel();
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBathChange = (e, index, input) => {
    console.warn({ e, index, input });
    let bath_area_val = '';
    if (input === 'bath_area') {
      bath_area_val = e.target.value;
    } else {
      bath_area_val = e;
    }
    const list = [...bathArea];
    list[index][input] = bath_area_val;
    setBathArea(list);
    console.warn('bathArea', bathArea);
  };
  const handleBathRemove = (index) => {
    const list = [...bathArea];
    list.splice(index, 1);
    setBathArea(list);
  };
  const handleBathAdd = () => {
    setBathArea([...bathArea, { bath_area: '', bath_area_unit: '' }]);
  };
  const handleMasterChange = (e, index, input) => {
    console.warn({ e, index, input });
    let master_bedroom_area_val = '';
    if (input === 'master_bedroom_area') {
      master_bedroom_area_val = e.target.value;
    } else {
      master_bedroom_area_val = e;
    }
    const list = [...masterBedroomArea];
    list[index][input] = master_bedroom_area_val;
    setMasterBedroomArea(list);
    console.warn('bathArea', bathArea);
  };

  const handleMasterRemove = (index) => {
    const list = [...masterBedroomArea];
    list.splice(index, 1);
    setMasterBedroomArea(list);
  };

  const handleMasterAdd = () => {
    setMasterBedroomArea([
      ...masterBedroomArea,
      { master_bedroom_area: '', master_bedroom_area_unit: '' },
    ]);
  };
  const getReportData = () => {
    setLoading(true);
    dispatch(NwfaSandFinishService.getSingleNwfaInstaReport(report_id))
      .then((res) => {
        let reportRes = res;
        setActiveStep(
          parseInt(reportRes.activeStep) ? parseInt(reportRes.activeStep) : 0
        );

        reportRes.avr_mois_cont =
        reportRes?.avr_mois_cont === 'yes' || reportRes?.avr_mois_cont === 'Yes' ? 'Yes' : reportRes?.avr_mois_cont;
        reportRes.hvac_tsti =
        reportRes?.hvac_tsti === 'yes' || reportRes?.hvac_tsti === 'Yes' ? 'Yes' : reportRes?.hvac_tsti;
        reportRes.Co_s_test =
        reportRes?.Co_s_test === 'yes' || reportRes?.Co_s_test === 'Yes' ? 'Yes' : reportRes?.Co_s_test;
        reportRes.wood_sub_test =
        reportRes?.wood_sub_test === 'yes' || reportRes?.wood_sub_test === 'Yes' ? 'Yes' : reportRes?.wood_sub_test;
        reportRes.crawlspace_test =
        reportRes?.crawlspace_test === 'yes' || reportRes?.crawlspace_test === 'Yes' ? 'Yes' : reportRes?.crawlspace_test;
        reportRes.baseTesting =
        reportRes?.baseTesting === 'yes' || reportRes?.baseTesting === 'Yes' ? 'Yes' : reportRes?.baseTesting;
        
        reportRes.h_bui_occupied =
        reportRes?.h_bui_occupied === 'yes' || reportRes?.h_bui_occupied === 'Yes' ? 'Yes' : reportRes?.h_bui_occupied;
        reportRes.h_bui_cons =
        reportRes?.h_bui_cons === 'yes' || reportRes?.h_bui_cons === 'Yes' ? 'Yes' : reportRes?.h_bui_cons;


        reportRes.inst_metho_floot_measured_flat =
        reportRes?.inst_metho_floot_measured_flat === 'yes' || reportRes?.inst_metho_floot_measured_flat === 'Yes' ? 'Yes' : reportRes?.inst_metho_floot_measured_flat;

        reportRes.subfloor_measured_flat =
        reportRes?.subfloor_measured_flat === 'yes' || reportRes?.subfloor_measured_flat === 'Yes' ? 'Yes' : reportRes?.subfloor_measured_flat;
        reportRes.float_floor_measured_for_flat =
        reportRes?.float_floor_measured_for_flat === 'yes' || reportRes?.float_floor_measured_for_flat === 'Yes' ? 'Yes' : reportRes?.float_floor_measured_for_flat;
        reportRes.hvac_oper_during_storage =
        reportRes?.hvac_oper_during_storage === 'yes' || reportRes?.hvac_oper_during_storage === 'Yes' ? 'Yes' : reportRes?.hvac_oper_during_storage;
        reportRes.hvac_oper_during_install =
        reportRes?.hvac_oper_during_install === 'yes' || reportRes?.hvac_oper_during_install === 'Yes' ? 'Yes' : reportRes?.hvac_oper_during_install;

        reportRes.app_below_grade =
        reportRes?.app_below_grade === 'yes' || reportRes?.app_below_grade === 'Yes' ? 'Yes' : reportRes?.app_below_grade;
        reportRes.app_over_radiant_heat =
        reportRes?.app_over_radiant_heat === 'yes' || reportRes?.app_over_radiant_heat === 'Yes' ? 'Yes' : reportRes?.app_over_radiant_heat;
        reportRes.lot_drainage_away_foun =
        reportRes?.lot_drainage_away_foun === 'yes' || reportRes?.lot_drainage_away_foun === 'Yes' ? 'Yes' : reportRes?.lot_drainage_away_foun;
        reportRes.water_sour_swimm =
        reportRes?.water_sour_swimm === 'yes' || reportRes?.water_sour_swimm === 'Yes' ? 'Yes' : reportRes?.water_sour_swimm;
        reportRes.gutters_down =
        reportRes?.gutters_down === 'yes' || reportRes?.gutters_down === 'Yes' ? 'Yes' : reportRes?.gutters_down;
        reportRes.roof_overhang =
        reportRes?.roof_overhang === 'yes' || reportRes?.roof_overhang === 'Yes' ? 'Yes' : reportRes?.roof_overhang;
        reportRes.soil_damp =
        reportRes?.soil_damp === 'yes' || reportRes?.soil_damp === 'Yes' ? 'Yes' : reportRes?.soil_damp;
        reportRes.signs_mois =
        reportRes?.signs_mois === 'yes' || reportRes?.signs_mois === 'Yes' ? 'Yes' : reportRes?.signs_mois;
        reportRes.sprinklers_irri =
        reportRes?.sprinklers_irri === 'yes' || reportRes?.sprinklers_irri === 'Yes' ? 'Yes' : reportRes?.sprinklers_irri;
        reportRes.Esta_lands =
        reportRes?.Esta_lands === 'yes' || reportRes?.Esta_lands === 'Yes' ? 'Yes' : reportRes?.Esta_lands;
        reportRes.visible_cracks =
        reportRes?.visible_cracks === 'yes' || reportRes?.visible_cracks === 'Yes' ? 'Yes' : reportRes?.visible_cracks;
        reportRes.visible_exter =
        reportRes?.visible_exter === 'yes' || reportRes?.visible_exter === 'Yes' ? 'Yes' : reportRes?.visible_exter;
        reportRes.shrubs_flowers =
        reportRes?.shrubs_flowers === 'yes' || reportRes?.shrubs_flowers === 'Yes' ? 'Yes' : reportRes?.shrubs_flowers;

        reportRes.h_dehu_syst =
        reportRes?.h_dehu_syst === 'yes' || reportRes?.h_dehu_syst === 'Yes' ? 'Yes' : reportRes?.h_dehu_syst;
        reportRes.hvac_sys_oper =
        reportRes?.hvac_sys_oper === 'yes' || reportRes?.hvac_sys_oper === 'Yes' ? 'Yes' : reportRes?.hvac_sys_oper;

        reportRes.humidification_dehum =
        reportRes?.humidification_dehum === 'yes' || reportRes?.humidification_dehum === 'Yes' ? 'Yes' : reportRes?.humidification_dehum;
        reportRes.data_logger =
        reportRes?.data_logger === 'yes' || reportRes?.data_logger === 'Yes' ? 'Yes' : reportRes?.data_logger;
        reportRes.programma =
        reportRes?.programma === 'yes' || reportRes?.programma === 'Yes' ? 'Yes' : reportRes?.programma;
        reportRes.has_floor_full_cyc =
        reportRes?.has_floor_full_cyc === 'yes' || reportRes?.has_floor_full_cyc === 'Yes' ? 'Yes' : reportRes?.has_floor_full_cyc;

        reportRes.create_slab_evaluation =
        reportRes?.create_slab_evaluation === 'yes' || reportRes?.create_slab_evaluation === 'Yes' ? 'Yes' : reportRes?.create_slab_evaluation;
        reportRes.wood_subfloor_evaluations =
        reportRes?.wood_subfloor_evaluations === 'yes' || reportRes?.wood_subfloor_evaluations === 'Yes' ? 'Yes' : reportRes?.wood_subfloor_evaluations;
        reportRes.visible_rot =
        reportRes?.visible_rot === 'yes' || reportRes?.visible_rot === 'Yes' ? 'Yes' : reportRes?.visible_rot;

        reportRes.basement_evaluation =
        reportRes?.basement_evaluation === 'yes' || reportRes?.basement_evaluation === 'Yes' ? 'Yes' : reportRes?.basement_evaluation;
        reportRes.rusty_nails =
        reportRes?.rusty_nails === 'yes' || reportRes?.rusty_nails === 'Yes' ? 'Yes' : reportRes?.rusty_nails;
        reportRes.gapped_trim =
        reportRes?.gapped_trim === 'yes' || reportRes?.gapped_trim === 'Yes' ? 'Yes' : reportRes?.gapped_trim;
        reportRes.peeling_paint =
        reportRes?.peeling_paint === 'yes' || reportRes?.peeling_paint === 'Yes' ? 'Yes' : reportRes?.peeling_paint;
        reportRes.floor_stains =
        reportRes?.floor_stains === 'yes' || reportRes?.floor_stains === 'Yes' ? 'Yes' : reportRes?.floor_stains;
        reportRes.seams_sealed  =
        reportRes?.seams_sealed  === 'yes' || reportRes?.seams_sealed  === 'Yes' ? 'Yes' : reportRes?.seams_sealed ;
        reportRes.plast_tap  =
        reportRes?.plast_tap  === 'yes' || reportRes?.plast_tap  === 'Yes' ? 'Yes' : reportRes?.plast_tap ;
        reportRes.ins_bw_joists =
        reportRes?.ins_bw_joists === 'yes' || reportRes?.ins_bw_joists === 'Yes' ? 'Yes' : reportRes?.ins_bw_joists;
        reportRes.ins_found_wall =
        reportRes?.ins_found_wall === 'yes' || reportRes?.ins_found_wall === 'Yes' ? 'Yes' : reportRes?.ins_found_wall;
        reportRes.vents_prese =
        reportRes?.vents_prese === 'yes' || reportRes?.vents_prese === 'Yes' ? 'Yes' : reportRes?.vents_prese;
        reportRes.humidistat_ins =
        reportRes?.humidistat_ins === 'yes' || reportRes?.humidistat_ins === 'Yes' ? 'Yes' : reportRes?.humidistat_ins;
        reportRes.walk_out =
        reportRes?.walk_out === 'yes' || reportRes?.walk_out === 'Yes' ? 'Yes' : reportRes?.walk_out;
        reportRes.wall_cracks_present =
        reportRes?.wall_cracks_present === 'yes' || reportRes?.wall_cracks_present === 'Yes' ? 'Yes' : reportRes?.wall_cracks_present;
        reportRes.sump_pump =
        reportRes?.sump_pump === 'yes' || reportRes?.sump_pump === 'Yes' ? 'Yes' : reportRes?.sump_pump;
        reportRes.opera =
        reportRes?.opera === 'yes' || reportRes?.opera === 'Yes' ? 'Yes' : reportRes?.opera;
        reportRes.hvac_vents_open_basement =
        reportRes?.hvac_vents_open_basement === 'yes' || reportRes?.hvac_vents_open_basement === 'Yes' ? 'Yes' : reportRes?.hvac_vents_open_basement;
        reportRes.cond_present =
        reportRes?.cond_present === 'yes' || reportRes?.cond_present === 'Yes' ? 'Yes' : reportRes?.cond_present;
        reportRes.stand_water_present =
        reportRes?.stand_water_present === 'yes' || reportRes?.stand_water_present === 'Yes' ? 'Yes' : reportRes?.stand_water_present;
        reportRes.Alkali_dis =
        reportRes?.Alkali_dis === 'yes' || reportRes?.Alkali_dis === 'Yes' ? 'Yes' : reportRes?.Alkali_dis;
        reportRes.vapor_retarder_present =
        reportRes?.vapor_retarder_present === 'yes' || reportRes?.vapor_retarder_present === 'Yes' ? 'Yes' : reportRes?.vapor_retarder_present;

        reportRes.does_patr_issue =
        reportRes?.does_patr_issue === 'yes' || reportRes?.does_patr_issue === 'Yes' ? 'Yes' : reportRes?.does_patr_issue;

        reportRes.wood_mois_on_top =
        reportRes?.wood_mois_on_top === 'yes' || reportRes?.wood_mois_on_top === 'Yes' ? 'Yes' : reportRes?.wood_mois_on_top;
        reportRes.wood_mois_in_core =
        reportRes?.wood_mois_in_core === 'yes' || reportRes?.wood_mois_in_core === 'Yes' ? 'Yes' : reportRes?.wood_mois_in_core;
        reportRes.wood_mois_on_underside =
        reportRes?.wood_mois_on_underside === 'yes' || reportRes?.wood_mois_on_underside === 'Yes' ? 'Yes' : reportRes?.wood_mois_on_underside;

        reportRes.upload_sign_toggle =
          reportRes?.upload_sign_toggle === 'yes' ? true : false;
        reportRes.footer_text_info =
          reportRes.footer_text_info === 'yes' ? true : false;
        reportRes.private_notes_togg =
          reportRes.private_notes_togg === 'yes' ? true : false;
        let data1 = [];
        if (reportRes.attachments) {
          Object.keys(reportRes.attachments).forEach(function (key, index) {
            data1.push(reportRes.attachments[key]);
          });
        }
        reportRes.attachments = data1;

        let Allimagedescription = {};
        reportRes.attachments.forEach((attachment) => {
          let indexVal = String(attachment['uid']);
          Allimagedescription[indexVal] = {};
          Allimagedescription[indexVal]['caption'] = attachment['desc'];
        });
        reportRes.stepOneTogg = reportRes.stepOneTogg[0]
          ? reportRes.stepOneTogg[0]
          : ['1', '2', '3', '4', '5', '6', '7'];
        reportRes.stepTwoTogg1 = reportRes.stepTwoTogg1[0]
          ? reportRes.stepTwoTogg1[0]
          : ['1', '2', '3', '4'];
        reportRes.stepTwoTogg2 = reportRes.stepTwoTogg2[0]
          ? reportRes.stepTwoTogg2[0]
          : ['1', '2', '3', '4'];
        reportRes.stepThreeTogg = reportRes.stepThreeTogg[0]
          ? reportRes.stepThreeTogg[0]
          : ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
        reportRes.stepFourTogg1 = reportRes.stepFourTogg1[0]
          ? reportRes.stepFourTogg1[0]
          : ['1', '2', '3', '4', '5', '6', '7'];
        reportRes.stepFourTogg2 = reportRes.stepFourTogg2[0]
          ? reportRes.stepFourTogg2[0]
          : ['1', '2', '3', '4', '5', '6', '7'];
        reportRes.Allimagedescription = Allimagedescription;

        //footerlogos
        let newlogos = [];
        // console.warn('response quick reports', typeof(res.selectededlogos))

        if (reportRes.selectededlogos) {
          Object.keys(reportRes.selectededlogos) &&
            Object.keys(reportRes.selectededlogos).forEach(function (
              key,
              index
            ) {
              newlogos.push(reportRes.selectededlogos[key].id?.toString());
            });
          //setselectedLogos(newlogos)
          reportRes.footerLogo = newlogos;
          setFooterLogo(newlogos);
        }
        console.log('serverDatttta', { reportRes });
        setBathArea(reportRes.bathArea);
        setMasterBedroomArea(
          reportRes.masterBedroomArea
            ? reportRes.masterBedroomArea
            : [{ master_bedroom_area: '', master_bedroom_area_unit: '' }]
        );
        setValues(reportRes);
        setLoading(false);
        console.info('Nwfa Sand Report Data Get', reportRes);
      })
      .catch((err) => {
        // alert("error",err);
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };
  const handleFill = (val) => {
    setValues({ ...values, ...val });
    setVals({ ...values, currentUrl: window.location.href, attachments: [] });

    dispatch(Actions.create_report(vals));
  };
  const fetchInspectorData = (params = {}) => {
    // setLoading(true);
    dispatch(inspectorService.getInspectorData())
      .then((res) => {
        //setLoading(true);
        values.signId = res.sign_for_report;
        values.signature = res.sign_for_report;
        values.signurl = res.signurl;
        values.footer_text = res.footer_text;
        if (res.footerlogos) {
          var sphValues = res.footerlogos;
          setFooterLogo(res.footerlogos);
          values.footerLogo = sphValues.map(function (e) {
            return e.toString();
          });
        }

        //setLoading(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
        setLoading(false);
      });
  };

  const saveDraft = () => {
    setSpinnerReport(true);
    values.status = 'Saved';
    onFinish();
    setLoading(true);
    dispatch(NwfaSandFinishService.updateNwfaInstReport(values))
      .then((res) => {
        console.warn('Submit', res);
        setSpinnerReport(false);
        window.location.reload(false);
        navigate(
          '/update-nwfa-inspector-report/?report_id=' + res.data.report_id
        );
        setLoading(false);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const handleChange = (input) => (e) => {
    console.warn('Event on select', input, e);
    console.warn('isOpen', isOpen);
    if (
      input === 'humidification_dehum' ||
      input === 'flooringtype' ||
      input === 'h_bui_occupied' ||
      input === 'building_style' ||
      input === 'building_type' ||
      input === 'inst_method' ||
      input === 'car_i2_inst_type' ||
      input === 'loc_heating' ||
      input === 'basement_evaluation' ||
      input === 'base_finish' ||
      input === 'create_slab_evaluation' ||
      input === 'wood_subfloor_evaluations' ||
      input === 'type_wood_subfloor' ||
      input === 'avr_mois_cont' ||
      input === 'Co_s_test' ||
      input === 'hvac_tsti' ||
      input === 'crawlspace_test' ||
      input === 'baseTesting' ||
      input === 'Mois_req_adhesive_manu' ||
      input === 'concern_con_exi_flr' ||
      input === 'wood_sub_test' ||
      input === 'Mois_req_flo_man' ||
      input === 'crawl_space_evelu' ||
      input === 'type_heating_sys' ||
      input === 'comm_party_drop' ||
      input === 'private_notes_togg' ||
      input === 'upload_sign_toggle' ||
      input === 'footer_text_info' ||
      input === 'type_cooling_sys' ||
      input === 'h_bui_cons' ||
      input === 'hvac_oper_during_storage' ||
      input === 'hvac_oper_during_install' ||
      input === 'inst_metho_floot_measured_flat' ||
      input === 'float_floor_measured_for_flat' ||
      input === 'subfloor_measured_flat' ||
      input === 'app_over_radiant_heat' ||
      input === 'app_below_grade' ||
      input === 'lot_drainage_away_foun' ||
      input === 'water_sour_swimm' ||
      input === 'gutters_down' ||
      input === 'roof_overhang' ||
      input === 'soil_damp' ||
      input === 'signs_mois' ||
      input === 'sprinklers_irri' ||
      input === 'Esta_lands' ||
      input === 'visible_cracks' ||
      input === 'visible_exter' ||
      input === 'shrubs_flowers' ||
      input === 'hvac_sys_oper' ||
      input === 'data_logger' ||
      input === 'programma' ||
      input === 'visible_rot' ||
      input === 'seams_sealed' ||
      input === 'vents_prese' ||
      input === 'cond_present' ||
      input === 'stand_water_present' ||
      input === 'Alkali_dis' ||
      input === 'vapor_retarder_present' ||
      input === 'plast_tap' ||
      input === 'ins_bw_joists' ||
      input === 'ins_found_wall' ||
      input === 'humidistat_ins' ||
      input === 'wall_cracks_present' ||
      input === 'walk_out' ||
      input === 'sump_pump' ||
      input === 'opera' ||
      input === 'hvac_vents_open_basement' ||
      input === 'peeling_paint' ||
      input === 'floor_stains' ||
      input === 'rusty_nails' ||
      input === 'gapped_trim' ||
      input === 'has_floor_full_cyc' ||
      input === 'does_patr_issue' ||
      input === 'wood_mois_on_top' ||
      input === 'wood_mois_in_core' ||
      input === 'wood_mois_on_underside' ||
      input === 'h_dehu_syst'
    ) {
      if (input === 'wood_mois_on_top') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'wood_mois_in_core') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'wood_mois_on_underside') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'does_patr_issue') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'seams_sealed') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'vents_prese') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'cond_present') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'stand_water_present') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'Alkali_dis') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'vapor_retarder_present') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'plast_tap') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'ins_bw_joists') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'ins_found_wall') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'humidistat_ins') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'wall_cracks_present') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'walk_out') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'sump_pump') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'opera') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'hvac_vents_open_basement') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'peeling_paint') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'floor_stains') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'rusty_nails') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'gapped_trim') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'basement_evaluation') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            cleanedField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            cleanedField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }






      if (input === 'data_logger') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'programma') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'has_floor_full_cyc') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'hvac_sys_oper') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'gutters_down') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'roof_overhang') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'soil_damp') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'signs_mois') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'sprinklers_irri') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'Esta_lands') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'visible_cracks') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'visible_exter') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'shrubs_flowers') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'water_sour_swimm') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'lot_drainage_away_foun') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'app_over_radiant_heat') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'app_below_grade') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'inst_metho_floot_measured_flat') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'float_floor_measured_for_flat') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'subfloor_measured_flat') {
        setValues({ ...values, [input]: e });
      }


      if (input === 'hvac_oper_during_storage') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'hvac_oper_during_install') {
        setValues({ ...values, [input]: e });
      }

      if (input === 'type_cooling_sys' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          type_cooling_sysField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'type_cooling_sys' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          type_cooling_sysField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'h_dehu_syst') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            humi_dehum_sysField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            humi_dehum_sysField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'humidification_dehum') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            humidification_dehumField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            humidification_dehumField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'loc_heating' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          acField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'loc_heating' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          acField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'flooringtype' && e === 'Plank') {
        setIsOpen({
          ...isOpen,
          concern_spec_dropField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'flooringtype' && e !== 'Plank') {
        setIsOpen({
          ...isOpen,
          concern_spec_dropField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'flooringtype' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          concern_spec_drop_otherField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'flooringtype' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          concern_spec_drop_otherField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'h_bui_cons') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            constructionField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            constructionField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'h_bui_occupied') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            buildField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            buildField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }
      if (input === 'building_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          buildingOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'building_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          buildingOther: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'building_style' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          buildingStyleOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'building_style' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          buildingStyleOther: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_i2_inst_type' && e === 'Glue down') {
        setIsOpen({
          ...isOpen,
          installationGlue: true,
          installationOther: false,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          installationGlue: false,
          installationOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e !== 'Glue down') {
        setIsOpen({
          ...isOpen,
          installationGlue: false,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          installationOther: false,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e === 'Nail/staple') {
        setIsOpen({
          ...isOpen,
          stapleField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e !== 'Nail/staple') {
        setIsOpen({
          ...isOpen,
          stapleField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'base_finish' && e === 'Finished') {
        setIsOpen({
          ...isOpen,
          base_finishField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'base_finish' && e !== 'Finished') {
        setIsOpen({
          ...isOpen,
          base_finishField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'visible_rot') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'create_slab_evaluation') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            create_slabField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            create_slabField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'wood_subfloor_evaluations') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            wood_subfloorField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            wood_subfloorField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'type_wood_subfloor' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          type_wood_open: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'type_wood_subfloor' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          type_wood_open: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'Co_s_test') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            Co_s_testField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            Co_s_testField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'wood_sub_test') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            wood_sub_testField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            wood_sub_testField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'avr_mois_cont') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            avr_mois_contField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            avr_mois_contField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'hvac_tsti') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            hvac_tstiField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            hvac_tstiField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'crawlspace_test') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            crawlspace_testField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            crawlspace_testField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'baseTesting') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            baseTestingField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            baseTestingField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }

      if (input === 'Mois_req_flo_man' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          Mois_req_flo_open: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'Mois_req_flo_man' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          Mois_req_flo_open: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'Mois_req_adhesive_manu' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          Mois_req_adhesive_manu_open: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'Mois_req_adhesive_manu' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          Mois_req_adhesive_manu_open: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'concern_con_exi_flr' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          concern_con_exi_flrField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'concern_con_exi_flr' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          concern_con_exi_flrField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'type_heating_sys' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          type_heating_sysField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'type_heating_sys' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          type_heating_sysField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'crawl_space_evelu' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          crawl_space_evelu_Field: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'crawl_space_evelu' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          crawl_space_evelu_Field: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'comm_party_drop' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          comm_party_drop_Field: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'comm_party_drop' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          comm_party_drop_Field: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'footer_text_info' && e === true) {
        setIsOpen({
          ...isOpen,
          footerField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'footer_text_info' && e === false) {
        setIsOpen({
          ...isOpen,
          footerField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'private_notes_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          notesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'private_notes_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          notesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'upload_sign_toggle' && e === true) {
        setIsOpen({
          ...isOpen,
          signatureField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'upload_sign_toggle' && e === false) {
        setIsOpen({
          ...isOpen,
          signatureField: false,
        });
        setValues({ ...values, [input]: e });
      }
    } else if (
      input === 'time_type' ||
      input === 'formal_temp' ||
      input === 'time_type1' ||
      input === 'measure_type1' ||
      input === 'trowel_used' ||
      input === 'powder_bath' ||
      input === 'measure_type2' ||
      input === 'measure_type3' ||
      input === 'measure_type4' ||
      input === 'measure_type5' ||
      input === 'measure_type6' ||
      input === 'measure_type7' ||
      input === 'measure_type8' ||
      input === 'measure_type9' ||
      input === 'measure_type10' ||
      input === 'measure_type11' ||
      input === 'measure_type12' ||
      input === 'measure_type14' ||
      input === 'measure_type13' ||
      input === 'measure_type15' ||
      input === 'measure_type16' ||
      input === 'measure_type17' ||
      input === 'property_dropdown' ||
      input === 'float_ins_met' ||
      input === 'temp_f_c' ||
      input === 'wind_wells' ||
      input === 'glue_type' ||
      input === 'exter_home_face' ||
      input === 'Normal_weight_con' ||
      input === 'traff_use' ||
      input === 'light_weight_gypsum' ||
      input === 'buil_over' ||
      input === 'Moist_ret_use_slab' ||
      input === 'pre_tension' ||
      input === 'specify_side_below' ||
      input === 'h_bi_ins_date' ||
      input === 'stepOneTogg' ||
      input === 'stepTwoTogg1' ||
      input === 'stepTwoTogg2' ||
      input === 'stepThreeTogg' ||
      input === 'stepFourTogg1' ||
      input === 'stepFourTogg2'
    ) {
      input === 'time_type' ||
      input === 'formal_temp' ||
      input === 'time_type1' ||
      input === 'powder_bath' ||
      input === 'measure_type1' ||
      input === 'measure_type2' ||
      input === 'measure_type3' ||
      input === 'measure_type4' ||
      input === 'measure_type5' ||
      input === 'measure_type6' ||
      input === 'measure_type7' ||
      input === 'measure_type8' ||
      input === 'measure_type9' ||
      input === 'measure_type10' ||
      input === 'measure_type11' ||
      input === 'measure_type12' ||
      input === 'measure_type14' ||
      input === 'measure_type15' ||
      input === 'measure_type16' ||
      input === 'measure_type13' ||
      input === 'measure_type17' ||
      input === 'temp_f_c' ||
      input === 'h_bi_ins_date'
        ? setValues({ ...values, [input]: e[0] })
        : setValues({ ...values, [input]: e });
    } else if (input === 'footerLogo') {
      //start
      let footerlogo1 = [];
      let id = e.target.value;
      footerlogo1 = [...footerLogo];
      if (footerlogo1?.includes(id)) {
        let footer_idex = footerlogo1.indexOf(id);
        delete footerlogo1[footer_idex];
      } else {
        footerlogo1.push(id);
      }
      footerlogo1 = footerlogo1?.filter((e) => e);
      setFooterLogo(footerlogo1);
      values.footerLogo = footerlogo1;
    } else {
      setValues({ ...values, [input]: e.target.value });
    }
    setVals({
      ...values,
      currentUrl: window.location.href,
      attachments: [],
    });
    dispatch(Actions.create_report(vals));
  };

  const handleDateformate = (e, name, type) => {
    console.log('ok', e);
    if (name === 'h_bi_ins_date') {
      if (type === 'date') {
        setCalOpen(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'concern_prod_date') {
      if (type === 'date') {
        setCalOpen17(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'jobsite_date') {
      if (type === 'date') {
        setCalOpen1(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'closing_date') {
      if (type === 'date') {
        setCalOpen3(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'moving_date') {
      if (type === 'date') {
        setCalOpen4(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'operational_date') {
      if (type === 'date') {
        setCalOpen5(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_sold_to_end_user') {
      if (type === 'date') {
        setCalOpen6(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_deliver_to_jobsite') {
      if (type === 'date') {
        setCalOpen7(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_of_installation') {
      if (type === 'date') {
        setCalOpen8(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_completion') {
      if (type === 'date') {
        setCalOpen9(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_occupied') {
      if (type === 'date') {
        setCalOpen10(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_completion_first') {
      if (type === 'date') {
        setCalOpen11(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_reported') {
      if (type === 'date') {
        setCalOpen12(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_home_completed') {
      if (type === 'date') {
        setCalOpen13(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_hvac_fully_oper') {
      if (type === 'date') {
        setCalOpen14(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_last_serv') {
      if (type === 'date') {
        setCalOpen15(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_completed') {
      if (type === 'date') {
        setCalOpen16(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
  };

  const handleEditor = (valEditor, label) => {
    setValues((l) => ({ ...l, [label]: valEditor }));
    setVals({
      ...values,
      currentUrl: window.location.href,
      attachments: [],
    });

    dispatch(Actions.create_report(vals));
  };

  const addClient = () => {
    console.warn('addClient', values);
    let clientData = {
      agent_claim_name: values.commisioning_name,
      comm_party_drop: values.comm_party_drop,
      comm_email: values.commisioning_party_email,
      street_name_number: values.street_name_number,
      state: values.commisioning_party_state,
      city: values.commisioning_party_city,
      zip_code: values.commisioning_party_zip,
      phone_no_party: values.commisioning_party_contact_number,
    };
    if (clientData.company_name_qr !== '' && clientData.comm_email !== '') {
      dispatch(createClientService.create_client(clientData))
        .then((res) => {
          console.warn('createClientService', res);
          if (res.status === 'client added') {
            fetchclientData();
            notification.open({
              message: 'Client successfully created!',
              description: 'You have successfully created a client.',
              icon: (
                <CheckCircleOutlined
                  style={{
                    color: '#108ee9',
                  }}
                />
              ),
            });
          } else {
            fetchclientData();
            notification.error({
              message: `${res.status}`,
              description:
                'The client that you are trying to create already exists.”',
              className: 'custom-class',
            });
          }
        })
        .catch((err) => {
          console.info('Get Error Edit State...', loading);
          notification.error('This is an error message');
        });
    } else {
      notification.error('Client data is not valid');
    }
  };

  function getStepContent(steps) {
    switch (steps) {
      case 0:
        return (
          <FirstStep
            handleChange={handleChange}
            clientDropdown={clientDropdown}
            addClient={addClient}
            handleFill={handleFill}
            values={values}
            isOpen={isOpen}
            loading={loading}
            handleDateformate={handleDateformate}
            calOpen={calOpen}
            setCalOpen={setCalOpen}
            calOpen1={calOpen1}
            setCalOpen1={setCalOpen1}
            calOpen2={calOpen2}
            setCalOpen2={setCalOpen2}
            calOpen3={calOpen3}
            setCalOpen3={setCalOpen3}
            calOpen4={calOpen4}
            setCalOpen4={setCalOpen4}
            calOpen5={calOpen5}
            setCalOpen5={setCalOpen5}
          />
        );
      case 1:
        return (
          <SecondStep
            handleChange={handleChange}
            handleDateformate={handleDateformate}
            values={values}
            isOpen={isOpen}
            calOpen6={calOpen6}
            setCalOpen6={setCalOpen6}
            calOpen7={calOpen7}
            setCalOpen7={setCalOpen7}
            calOpen8={calOpen8}
            setCalOpen8={setCalOpen8}
            calOpen9={calOpen9}
            setCalOpen9={setCalOpen9}
            calOpen10={calOpen10}
            setCalOpen10={setCalOpen10}
            calOpen11={calOpen11}
            setCalOpen11={setCalOpen11}
            calOpen12={calOpen12}
            setCalOpen12={setCalOpen12}
            handleEditor={handleEditor}
          />
        );
      case 2:
        return (
          <ThirdStep
            handleChange={handleChange}
            values={values}
            isOpen={isOpen}
            handleDateformate={handleDateformate}
            calOpen13={calOpen13}
            setCalOpen13={setCalOpen13}
            calOpen14={calOpen14}
            setCalOpen14={setCalOpen14}
            calOpen15={calOpen15}
            setCalOpen15={setCalOpen15}
            calOpen16={calOpen16}
            setCalOpen16={setCalOpen16}
            calOpen17={calOpen17}
            setCalOpen17={setCalOpen17}
            bathArea={bathArea}
            handleBathChange={handleBathChange}
            handleBathAdd={handleBathAdd}
            handleBathRemove={handleBathRemove}
            masterBedroomArea={masterBedroomArea}
            handleMasterChange={handleMasterChange}
            handleMasterAdd={handleMasterAdd}
            handleMasterRemove={handleMasterRemove}
          />
        );
      case 3:
        return (
          <FourthStep
            handleChange={handleChange}
            values={values}
            handleEditor={handleEditor}
            isOpen={isOpen}
            setLoadings={setLoadings}

          />
        );
      default:
        return <FirstStep />;
    }
  }

  function getSteps() {
    return [
      'GENERAL INFORMATION',
      'CLAIM HISTORY',
      'PHYSICAL DESCRIPTION ',
      'TESTING AND CONCLUSION',
    ];
  }
  const steps = getSteps();
  const onFinish = () => {
    console.log('Success:', values);
    dispatch(Actions.create_report({}));
    setLoadings(true);

    values.activeStep = activeStep;
    values.req_id = Data.req_id;
    values.bathArea = bathArea;
    values.masterBedroomArea = masterBedroomArea;
    values.category = Data.floor_category;
    values.upload_sign_toggle =
      values.upload_sign_toggle === true ? 'yes' : 'no';
    values.footer_text_info = values.footer_text_info === true ? 'yes' : 'no';
    values.private_notes_togg =
      values.private_notes_togg === true ? 'yes' : 'no';
    values.comm_party_toggle = values.stepOneTogg?.includes('1') ? 'yes' : 'no';
    values.homeowner_building_toggle = values.stepOneTogg?.includes('2')
      ? 'yes'
      : 'no';
    values.flooring_cont_toggle = values.stepOneTogg?.includes('3')
      ? 'yes'
      : 'no';
    values.manuf_info_toggle = values.stepOneTogg?.includes('4') ? 'yes' : 'no';
    values.retailer_info_toggle = values.stepOneTogg?.includes('5')
      ? 'yes'
      : 'no';
    values.jobsite_info_toggle = values.stepOneTogg?.includes('6')
      ? 'yes'
      : 'no';
    values.claim_history = values.stepTwoTogg1?.includes('1') ? 'yes' : 'no';
    values.jobsite_condition_ins_toggle = values.stepTwoTogg1?.includes('2')
      ? 'yes'
      : 'no';
    values.mois_content_ins_toggle = values.stepTwoTogg1?.includes('3')
      ? 'yes'
      : 'no';
    values.installation_toggle = values.stepTwoTogg1?.includes('4')
      ? 'yes'
      : 'no';
    values.comm_party_comm_toggle = values.stepTwoTogg2?.includes('1')
      ? 'yes'
      : 'no';
    values.enduser_toggle = values.stepTwoTogg2?.includes('2') ? 'yes' : 'no';
    values.flooring_contractor_info_toggle = values.stepTwoTogg2?.includes('3')
      ? 'yes'
      : 'no';
    values.other_invol_toggle = values.stepTwoTogg2?.includes('4')
      ? 'yes'
      : 'no';
    values.concern = values.stepThreeTogg?.includes('1') ? 'yes' : 'no';
    values.flooring_toggle = values.stepThreeTogg?.includes('2') ? 'yes' : 'no';
    values.rooms_being_toggle = values.stepThreeTogg?.includes('3')
      ? 'yes'
      : 'no';
    values.new_constructed_info_toggle = values.stepThreeTogg?.includes('4')
      ? 'yes'
      : 'no';
    values.exterior_info_toggle = values.stepThreeTogg?.includes('5')
      ? 'yes'
      : 'no';
    values.hvac_evalution_toggle = values.stepThreeTogg?.includes('6')
      ? 'yes'
      : 'no';
    values.special_consideration_info_toggle = values.stepThreeTogg?.includes(
      '7'
    )
      ? 'yes'
      : 'no';
    values.subfloor_evalution_toggle = values.stepThreeTogg?.includes('8')
      ? 'yes'
      : 'no';
    values.below_flooring_sys_toggle = values.stepThreeTogg?.includes('9')
      ? 'yes'
      : 'no';
    values.jobsite_con_ins = values.stepFourTogg1?.includes('1') ? 'yes' : 'no';
    values.floor_test_toggle = values.stepFourTogg1?.includes('2')
      ? 'yes'
      : 'no';
    values.substrate_testing_toggle = values.stepFourTogg1?.includes('3')
      ? 'yes'
      : 'no';
    values.below_flooring_system_toggle = values.stepFourTogg1?.includes('4')
      ? 'yes'
      : 'no';
    values.additional_testing_info_toggle = values.stepFourTogg2?.includes('1')
      ? 'yes'
      : 'no';
    values.apply_industry_stndr_toggle = values.stepFourTogg2?.includes('2')
      ? 'yes'
      : 'no';
    values.conclusion_cause_toggle = values.stepFourTogg2?.includes('3')
      ? 'yes'
      : 'no';
    values.footer_toggle = values.stepFourTogg2?.includes('4') ? 'yes' : 'no';
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed', errorInfo);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const buttonSubmit = () => {
    setSpinnerReport(true);
    values.status = 'Saved';
    onFinish();
    dispatch(NwfaSandFinishService.updateNwfaInstReport(values))
      .then((res) => {
        console.warn('Submit', res);
        notification.open({
          message: 'Report successfully updated!',
          description: 'You have successfully updated a report.',
          icon: (
            <CheckCircleOutlined
              style={{
                color: '#108ee9',
              }}
            />
          ),
        });
        //return;
        setSpinnerReport(false);
        navigate('/single-nwfa-installation/?report_id=' + res.data.report_id);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };
  return (
    <>
      {SpinnerReport ? (
        <ReportSpin />
      ) : (
        <>
          <div className="quickReportWrapper">
            <div className="pageHeader">
              <PageHeader title="NWFA Installation Inspection Report" />
            </div>
            {loading ? (
              <div className="container-xxl">
              <div className="Skeleton_space">
                <Row gutter={16} className="mb-3" justify="center">
                  <Col xs={24} md={6} xl={6} className="sklpad">
                    <Skeleton.Button
                      active
                      size="large"
                      shape="circle"
                      block={false}
                      className="abccc"
                    />
                    <Skeleton.Input
                      active
                      size="large"
                      block={false}
                      className="abccd"
                    />
                  </Col>
                  <Col xs={24} md={6} xl={6} className="sklpad">
                    <Skeleton.Button
                      active
                      size="large"
                      shape="circle"
                      block={false}
                      className="abccc"
                    />
                    <Skeleton.Input
                      active
                      size="large"
                      block={false}
                      className="abccd"
                    />
                  </Col>
                  <Col xs={24} md={6} xl={6} className="sklpad">
                    <Skeleton.Button
                      active
                      size="large"
                      shape="circle"
                      block={false}
                      className="abccc"
                    />
                    <Skeleton.Input
                      active
                      size="large"
                      block={false}
                      className="abccd"
                    />
                  </Col>
                  <Col xs={24} md={6} xl={6} className="sklpad">
                    <Skeleton.Button
                      active
                      size="large"
                      shape="circle"
                      block={false}
                      className="abccc"
                    />
                    <Skeleton.Input
                      active
                      size="large"
                      block={false}
                      className="abccd"
                    />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-3">
                  <Col md={24}>
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-3">
                  <Col md={24}>
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-3">
                  <Col md={24}>
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-3">
                  <Col md={24}>
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-3">
                  <Col md={24}>
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-3">
                  <Col md={24}>
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-3">
                  <Col md={24}>
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-3">
                  <Col md={24}>
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-3">
                  <Col md={24}>
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                </Row>
                <Row gutter={16} className="mb-3">
                  <Col md={24}>
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                </Row>
              </div>
            </div>
            ) : (
              <div className="container-xxl CarpetDetailed">
                <div className="reportInner">
                  <Box sx={{ my: 5 }}>
                    <div>
                      <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        nonLinear
                      >
                        {steps.map((label, index) => (
                          <Step key={label} className="cusutom-stepp">
                            <StepButton
                              color="inherit"
                              onClick={handleStep(index)}
                            >
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  </Box>
                  <Form
                    name="basic"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="custom-form"
                  >
                    <div className="step-content">
                      {getStepContent(activeStep)}
                      <div className="custum-btngrp">
                        <Button
                          className="nxtbtn"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        {activeStep === steps.length - 1 ? (
                          <Button
                            onClick={() => buttonSubmit()}
                            className="submitBtn"
                            loading={loadings}
                          >
                            Save And Continue To Preview Report
                          </Button>
                        ) : (
                          <Button
                            className="custom-nxtbtn"
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            Save And Continue To Preview Report
                          </Button>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </div>
          <div className="saveDraftBtn">
            <Button
              className="float floatBtn sc_button_hover_fade"
              id="draft"
              name="update_button"
              onClick={() => saveDraft()}
                disabled={loadings}

            >
              Save Draft
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default UpdateNwfaInstallation;
