import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, Divider, Input,Skeleton,Empty} from "antd";
import { IconButton } from "../../components/Buttons/buttons";
import { EyeFilled, SearchOutlined } from "@ant-design/icons";
import * as GetDealerPanelData from "../../services/dealerPanel";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import SkeltonTable from "../../pages/InspectionReport/SkeltonTable";

const DealerData = () => {

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [inspection, setInspections] = useState([])
  const [searchedText, setSearchedText] = useState("");
  let navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    fetchDealerPanelData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const fetchDealerPanelData = () => {
    setLoading(true);
    dispatch(GetDealerPanelData.getInspectionDealerPanelData())
      .then((res) => {
        setInspections(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State");
        setLoading(false);
      })
  }

  const columns = [
    {
      title: 'S.No',
      key: "index",
      dataIndex: 'Sno',
      sortDirections: ['descend'],
      render: (id, record, index) => { ++index; return index; },
    },
    {
      title: 'Inspection #',
      dataIndex: 'inspection_number',
      sorter: (a, b) => a.Inspection - b.Inspection,
      key: 'Inspection',
      sortDirections: ['descend'],
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.inspection_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.claim_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.dealer_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.date)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.concern)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.status)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Claim #",
      dataIndex: "claim_number",
      key: 'Claim',
      sorter: (a, b) => a.Claim - b.Claim,
    },
    {
      title: "Manufacturer Name",
      dataIndex: "dealer_name",
      key: 'Manufacturer_name',
      sorter: {
        compare: (a, b) => a.ManufacturerName - b.ManufacturerName,
      },
    },
    {
      title: 'Request Date',
      dataIndex: 'date',
      key: 'Request_date',
      sorter: (a, b) => a.RequestDate - b.RequestDate,
    },
    {
      title: "Descripition",
      dataIndex: "concern",
      key: 'descripition',
      sorter: (a, b) => {
        a = a.descripition || "";
        b = b.descripition || "";
        return a.localeCompare(b);
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: "status",
      sorter: (a, b) => {
        a = a.status_hidden || "";
        b = b.status_hidden || "";
        return a.localeCompare(b);
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (row, record) => {
        // console.log({ row });
        return (
          <div className="actionBtn">
            <IconButton
              icon={<EyeFilled />}
              type="primary"
              tooltip="View"
              text="View"
              handleClick={() =>
                navigate("/single-request/?req_id=" + record.action)
              }
            />
          </div>
        );
      },
    },
  ];

  return (
    <Card className="reportCard">
      <Row gutter={16} justify="space-between">
        <Col md={6} xs={24}>
          <div className="d-flex align-Items-center">
            <p className="tableHeaderText">Claim Listing </p>
          </div>
          <div>
            <p className="paragSubtitle">Claims {inspection.length}</p>
          </div>
        </Col>
        <Col md={6} xs={24}>
          <div className="searchWrapper">
            <Input
              className="formControl"
              prefix={<SearchOutlined />}
              allowClear
              placeholder="Search..."
              onSearch={(value) => {
                setSearchedText(value);
              }}
              onChange={(e) => {
                setSearchedText(e.target.value);
              }}
            />
          </div>
        </Col>
      </Row>

      <Divider />
      <Table
        bordered
        className="clientsDataTable"
        tableLayout="auto"
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={inspection}
        locale={{
          emptyText: loading ? columns.map(u => <Skeleton.Input height={50} style={{marginTop: '2px',height:'48px',padding:'0px'}} block={true}active={true} />): <Empty />
        }}
        showSorterTooltip={false}
        pagination={{
          current: page,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
          position: ["bottomCenter"],
        }}
      //   loading={{
      //     indicator: <SkeltonTable />,
      //    spinning: loading,
      //  }}
      />
    </Card>
  );
};

export default DealerData;
