import React, { useEffect, useState } from 'react';
import { Form, Input, DatePicker, InputNumber, Select, Checkbox, Button, Row, Col, Tooltip, Radio } from 'antd';
import { MinusCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import SectionTitle from '../../../components/SectionTitle';
import Upload1 from "../uploadMultistep";
import moment from 'moment';


const { TextArea } = Input;

const CustomerResolutionForm = ({ onFinish, initialData, loading, setLoading }) => {
    const [form] = Form.useForm();
    const DateFormat = 'MM-DD-YYYY';
    const [isOther, setIsOther] = useState(false);

    const handleTrowelChange = (value) => {
        if (value === 'Other') {
            setIsOther(true);
        } else {
            setIsOther(false);
        }
    };
    useEffect(() => {
        if (initialData && Object.keys(initialData).length > 0) {
            const dataToSet = {
                ...initialData,
                requestDate: initialData.requestDate ? moment(initialData.requestDate) : null,
                inspectionDate: initialData.inspectionDate ? moment(initialData.inspectionDate) : null,
                // Add more date fields here if applicable
            };


            form.setFieldsValue(dataToSet);
        }
    }, [initialData, form]);


    const takefileList = (list) => {
        initialData.attachments = list;
    };
    const styles = {
        formItem: {
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for input fields
            borderRadius: '6px', // Rounded corners for input fields
            border: '1px solid #d9d9d9', // Light border around inputs
            padding: '8px', // Padding inside the inputs
        },
        input: {
            borderRadius: '6px', // Rounded corners for the inputs themselves
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Soft shadow for inputs
            border: '1px solid #d9d9d9',
        },
    };
    const trowelOptions = [
        { value: '3/8” nap roller', label: '3/8” nap roller' },
        { value: '1/16”x1/32”x1/32” U', label: '1/16”x1/32”x1/32” U' },
        { value: '1/16”x1/16”x1/16” Square', label: '1/16”x1/16”x1/16” Square' },
        { value: '1/8”x1/8”x1/8” U', label: '1/8”x1/8”x1/8” U' },
        { value: '1/8”x1/8”x1/16” U', label: '1/8”x1/8”x1/16” U' },
        { value: 'Other', label: 'Other' },
    ];
    return (
        <Form className="field-service-resolution-form" form={form} layout="vertical" onFinish={onFinish} scrollToFirstError={true} >
            <SectionTitle level={3}>Claim Information</SectionTitle>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="claimNumber" label="Claim Number" rules={[{ required: true }]}>
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="requestDate" label="Request Date" rules={[{ required: true }]}>
                        <DatePicker format={DateFormat} style={{ ...styles.formItem, width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="jobStop" className="custom-radio-yes-no" label="Job Stop">

                        <Radio.Group >
                            <Radio value={'yes'}>Yes</Radio>
                            <Radio value={'no'}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="claimsAnalyst" label="Claims Analyst">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="analystExtension" label="Analyst Extension">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="turnKeyLabor" className="custom-radio-yes-no" label="Turn Key Labor">

                        <Radio.Group >
                            <Radio value={'yes'}>Yes</Radio>
                            <Radio value={'no'}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                    <Form.Item name="customerConcern" label="Customer Concern">
                        <TextArea style={styles.formItem} rows={3} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="projectJobName" label="Project/Job Name">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="accountExecutive" label="Account Executive">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="cell" label="Cell">
                        <Input style={styles.formItem} />
                    </Form.Item>

                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="hospitalityJobNumber" label="Hospitality Job Number">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
            </Row>

            <SectionTitle level={3}>Contact Information</SectionTitle>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="dealerContact" label="Dealer Contact">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="siteContact" label="Site Contact">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <Form.Item name="company" label="Company">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>

                <Col xs={24} sm={12} md={6}>
                    <Form.Item name="city" label="City">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item name="state" label="State">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={6}>
                    <Form.Item name="primaryPhoneNo" label="Primary Phone No">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={6}>
                    <Form.Item name="phoneNo" label="Phone No">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
            </Row>


            <SectionTitle level={3}>Product Information</SectionTitle>
            <Form.List name="products">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Row key={key} gutter={16} align="middle">
                                <Col xs={24} sm={12} md={6} xl={5}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'style']}
                                        rules={[{ required: true, message: 'Missing style' }]}
                                        label="Style"
                                    >
                                        <Input style={styles.formItem} placeholder="Style" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} xl={5}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'color']}
                                        rules={[{ required: true, message: 'Missing color' }]}
                                        label="Color"
                                    >
                                        <Input style={styles.formItem} placeholder="Color" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} xl={5}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'back']}
                                        rules={[{ required: true, message: 'Missing back' }]}
                                        label="Back"
                                    >
                                        <Input style={styles.formItem} placeholder="Back" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} xl={4}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'lot']}
                                        rules={[{ required: true, message: 'Missing lot' }]}
                                        label="Lot"
                                    >
                                        <Input style={styles.formItem} placeholder="Lot" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} xl={3}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'sysf']}
                                        rules={[{ required: true, message: 'Missing SY/SF' }]}
                                        label="SY/SF"
                                    >
                                        <InputNumber placeholder="SY/SF" style={{ ...styles.formItem, width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={2} sm={2} md={4} xl={2}>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Col>
                            </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Product
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <SectionTitle level={3}>Inspection Information</SectionTitle>
            <Row gutter={16}>
                <Col xs={24} sm={12} md={8} xl={8}>
                    <Form.Item name="fsManager" label="FS Manager">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} xl={8}>
                    <Form.Item name="inspectionDate" label="Inspection Date">
                        <DatePicker format={DateFormat} style={{ ...styles.formItem, width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} xl={8}>
                    <Form.Item name="projectJobName" label="Project/Job Name">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="personsPresent" label="Persons & Companies Present During Services">
                <TextArea style={styles.formItem} rows={4} />
            </Form.Item>

            <SectionTitle level={4}>Flooring Site Information</SectionTitle>
            <Row gutter={16}>
                <Col xs={24} sm={12} md={12} xl={8}>
                    <Form.Item name="siteType" label="Commercial/Residential">
                        <Select style={{
                            padding: '8px', // Padding inside the inputs
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Same box-shadow as the input
                            borderRadius: '6px', // Match the rounded corners
                        }}>
                            <Select.Option value="commercial">Commercial</Select.Option>
                            <Select.Option value="residential">Residential</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} xl={8}>
                    <Form.Item name="buildingAge" label="Age of Building">
                        <InputNumber style={{ ...styles.formItem, width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={24} xl={8}>
                    <Form.Item name="occupantsPerDay" label="Number of Occupants per Day">
                        <InputNumber style={{ ...styles.formItem, width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                    <Form.Item name="address" label="Address">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="buildingDescription" label="Building Description">
                <TextArea style={styles.formItem} rows={4} />
            </Form.Item>
            <Form.Item name="typeGrade" label="Grade">
                <Input style={styles.formItem} />
            </Form.Item>

            <SectionTitle level={4}>Subfloor & Underlayment Details</SectionTitle>
            <Row gutter={16}>
                <Col sm={24} md={12} xl={12}>
                    <Form.Item
                        name="subfloorThickness"
                        label="Substrate/Subfloor Thickness"
                    >
                        <Input
                            style={styles.formItem}
                            placeholder="Enter thickness (e.g. 6)"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} sm={8} md={8} xl={8}>
                    <Form.Item
                        name="hasUnderlayment"
                        label="Has Underlayment?"
                    >
                        <Select
                            style={styles.formItem}
                            placeholder="Select Yes/No"
                        >
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={16} md={16} xl={16}>
                    <Form.Item
                        name="underlaymentDescription"
                        label="Description"
                    >
                        <Input
                            style={styles.formItem}
                            placeholder="Enter description"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} sm={8} md={8} xl={8}>
                    <Form.Item
                        name="isSubfloorFlat"
                        label="Is Subfloor Flat? (Explain)"
                    >
                        <Select
                            style={styles.formItem}
                            placeholder="Select Yes/No"
                        >
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={16} md={16} xl={16}>
                    <Form.Item
                        name="subfloorFlatExplanation"
                        label="Explanation"
                        dependencies={['isSubfloorFlat']}
                    >
                        <Input
                            style={styles.formItem}
                            placeholder="e.g. within 1/8 inch over 10 feet"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} sm={8} md={8} xl={8}>
                    <Form.Item
                        name="subfloorCondition"
                        label="Subfloor Condition"
                    >
                        <Select style={styles.formItem} placeholder="Select condition">
                            <Select.Option value="good">Good</Select.Option>
                            <Select.Option value="average">Average</Select.Option>
                            <Select.Option value="poor">Poor</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={16} md={16} xl={16}>
                    <Form.Item name="previousFloor" label="What Was the Previous Installed Floor?">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
            </Row>
            <SectionTitle level={4}>Installation & Expansion Details</SectionTitle>
            <Row gutter={16}>
                <Col xs={24} sm={12} md={12} xl={12}>
                    <Form.Item
                        name="installationType"
                        label="Installation Type"
                    >
                        <Select style={styles.formItem} placeholder="Select installation type">
                            <Select.Option value="directGlue">Direct Glue</Select.Option>
                            <Select.Option value="doubleGlue">Double Glue</Select.Option>
                            <Select.Option value="tackless">Tackless</Select.Option>
                            <Select.Option value="floating">Floating</Select.Option>
                            <Select.Option value="nailDown">Nail Down</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} xl={12}>
                    <Form.Item
                        name="expansion"
                        label="Expansion"
                    >
                        <Select style={styles.formItem} placeholder="Expansion?">
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="measurements"
                label="List Measurements"
            >
                <TextArea
                    style={styles.formItem}
                    rows={4}
                    placeholder="Enter room measurements (e.g. Room 1: 30' x 40', Room 2: 25' x 35')"
                />
            </Form.Item>

            <SectionTitle level={4}>Adhesive Details</SectionTitle>

            <Row gutter={16}>
                <Col xs={24} sm={24} md={12} xl={12}>
                    <Form.Item name="adhesiveType" label="Adhesive Type/Brand">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={12}>
                    <Form.Item
                        name="trowelSize"
                        label="Trowel Size/Shape (Depth, Width, Spacing)"
                        rules={[{ required: true, message: 'Please select or specify the trowel size.' }]}
                    >
                        {isOther ? (
                            <Input
                                placeholder="Specify other trowel size"
                                style={{ width: '100%' }}
                                onChange={(e) =>
                                    form.setFieldsValue({ trowelSize: e.target.value }) // Updates the form value
                                }
                            />
                        ) : (
                            <Select
                                style={{
                                    padding: '8px',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '6px',
                                }}
                                onChange={(value) => {
                                    form.setFieldsValue({ trowelSize: value }); // Updates the form value
                                    handleTrowelChange(value); // Updates `isOther` state if needed
                                }}
                                options={trowelOptions}
                                placeholder="Select trowel size/shape"
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} sm={12} md={12} xl={12}>
                    <Form.Item name="adhesiveCoverage" label="Adhesive Coverage">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} xl={12}>
                    <Form.Item name="adhesiveBond" label="Adhesive Bond">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col sm={24} md={12} xl={8}>
                    <Form.Item name="floorPrepared" label="Was Floor and/or Adhesive Properly Prepared?">
                        <Select style={{
                            padding: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            borderRadius: '6px',
                        }}>
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={4}>
                    <Form.Item name="phReading" label="pH Reading of Floor">
                        <InputNumber style={{ ...styles.formItem, width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={6}>
                    <Form.Item name="tramexMoisture" label="Tramex Moisture Percentage">
                        <InputNumber style={{ ...styles.formItem, width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={6}>
                    <Form.Item name="relativeHumidity" label="RH% (Relative Humidity)">
                        <InputNumber style={{ ...styles.formItem, width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                    <Form.Item name="moistureIndications" label="Indications of Moisture at Site">
                        <TextArea style={styles.formItem} rows={4} />
                    </Form.Item>
                </Col>
            </Row>


            <SectionTitle level={4}>HVAC & Acclimation Information</SectionTitle>
            <Row gutter={16}>
                <Col xs={24} sm={12} md={12}>
                    <Form.Item name="hvacDuringInstallation" label="Was HVAC On During Installation?">
                        <Select style={{
                            padding: '8px', // Padding inside the inputs
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Same box-shadow as the input
                            borderRadius: '6px', // Match the rounded corners
                        }}>
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                    <Form.Item name="hvacOffDuringInactive" label="Is HVAC Turned Off During Inactive Hours?">
                        <Select style={{
                            padding: '8px', // Padding inside the inputs
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Same box-shadow as the input
                            borderRadius: '6px', // Match the rounded corners
                        }}>
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                    <Form.Item
                        name="acclimatedToSpecs"
                        label="Flooring, Adhesive, Site Acclimated to Specifications Prior to Installation"
                    >
                        <TextArea
                            style={{
                                padding: '8px', // Padding inside the inputs
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Same box-shadow as the input
                                borderRadius: '6px', // Match the rounded corners
                            }}
                            rows={4} // Adjust the rows for TextArea
                        />
                    </Form.Item>
                </Col>

            </Row>

            <SectionTitle level={4}>Affected Areas Information</SectionTitle>
            <Row gutter={16}>
                
                <Col md={24}>
                    <Form.Item name="areasAffected" label="Areas Affected">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} sm={12} md={12}>
                    <Form.Item name="roomAffected" label="Room Affected">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                    <Form.Item name="percentFloorAffected" label="Percent Floor Affected">
                        <InputNumber style={{ ...styles.formItem, width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} sm={12} md={12}>
                    <Form.Item name="floorRolled" label="Was Floor Rolled?">
                        <Select style={{
                            padding: '8px', // Padding inside the inputs
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Same box-shadow as the input
                            borderRadius: '6px', // Match the rounded corners
                        }}>
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                    <Form.Item name="rollerWeight" label="Weight of Roller">
                        <InputNumber style={{ ...styles.formItem, width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="affectedAreasPerimeter" label="Note if Affected Areas Are Just in Perimeter">
                <TextArea style={styles.formItem} rows={4} />
            </Form.Item>

            <SectionTitle level={4}>Maintenance Information</SectionTitle>
            <Row gutter={16}>
                <Col xs={24} sm={12} md={12} xl={8}>
                    <Form.Item name="dailyCleaning" label="Daily Cleaning">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} xl={8}>
                    <Form.Item name="regularCleaning" label="Regular Cleaning (How Often?)">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} xl={8}>
                    <Form.Item name="chemicalUsed" label="Chemical Used (Brand, Specific Product, pH)">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="maintenanceDelay" label="How Long After Floor Installed Was it Maintained?">
                <Input style={styles.formItem} />
            </Form.Item>
            <Form.Item name="floorCondition" label="Condition of Floor">
                <TextArea style={styles.formItem} rows={4} />
            </Form.Item>
            <Form.Item name="walkOffMats" label="Walk-off Mats">
                <Input style={styles.formItem} />
            </Form.Item>
            <Form.Item name="otherConditions" label="Other Conditions (e.g., Direct Sunlight)">
                <TextArea style={styles.formItem} rows={4} />
            </Form.Item>

            <SectionTitle level={4}>Furniture Details</SectionTitle>
            <Row gutter={16}>
                <Col md={24}>
                    <Form.Item name="systemsFurniture" label="Systems Furniture">
                        <Input style={styles.formItem} />
                    </Form.Item>
                </Col>      
            </Row>

            <SectionTitle level={4}>Problem Description</SectionTitle>
            <Form.Item
                name="problemDescription"
                label="Description of Problem Area (Explain in Detailed Bullet Points)"
            >
                <TextArea style={styles.formItem} rows={6} />
            </Form.Item>

            <SectionTitle level={4}>Manager's Opinion</SectionTitle>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={12} xl={6}>
                    <Form.Item name="isSiteRelated" label="Is Claim Site Related?">
                        <Select style={{
                            padding: '8px', // Padding inside the inputs
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Same box-shadow as the input
                            borderRadius: '6px', // Match the rounded corners
                        }}>
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={6}>
                    <Form.Item name="isInstallationRelated" label="Is Claim Installation Related?">
                        <Select style={{
                            padding: '8px', // Padding inside the inputs
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Same box-shadow as the input
                            borderRadius: '6px', // Match the rounded corners
                        }}>
                            <Select.Option value="Yes">Yes</Select.Option>
                            <Select.Option value="No">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={6}>
                    <Form.Item name="isManufacturingRelated" label="Is Claim Manufacturing Related?">
                        <Select style={{
                            padding: '8px', // Padding inside the inputs
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Same box-shadow as the input
                            borderRadius: '6px', // Match the rounded corners
                        }}>
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} xl={6}>
                    <Form.Item name="areSamplesAvailable" label="Are Samples Available?">
                        <Select style={{
                            padding: '8px', // Padding inside the inputs
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Same box-shadow as the input
                            borderRadius: '6px', // Match the rounded corners
                        }}>
                            <Select.Option value="yes">Yes</Select.Option>
                            <Select.Option value="no">No</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="digitalPhotos"
                label="Digital Photos Must be Included of Affected Areas from Multiple Angles (Including Broad Scope View)"
            >
                <Checkbox>Photos Included</Checkbox>
            </Form.Item>
            <Form.Item
                name="otherSiteIssues"
                label="Are There Other Site Issues Contributing to the Problem? (If Yes, Describe)"
            >
                <TextArea style={styles.formItem} rows={4} />
            </Form.Item>

            <SectionTitle level={4}>Recommendations</SectionTitle>
            <Form.Item
                name="repairRecommendations"
                label="Repair Recommendations (Explain in Detail to Include Square Yards/Linear Feet Affected)"
            >
                <TextArea style={styles.formItem} rows={6} />
            </Form.Item>
            <SectionTitle level={4}>Add Attachments</SectionTitle>
            <Row className="custom-dropzone-row" gutter={16}>
                <Upload1
                    takefileList={takefileList}
                    values={initialData}
                    setLoadings={setLoading}

                ></Upload1>
            </Row>
            <Form.Item className='form-circle-button-div'>
                <Tooltip title="Save Report" placement="top">
                    <Button
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                        shape="circle"
                        icon={<SaveOutlined className="svg-icon" />}
                        className="circle-custom-button save-report-circle-btn "

                    />
                </Tooltip>
            </Form.Item>
        </Form >

    );
};

export default CustomerResolutionForm;