import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export function getInspectionFsinspectorPanelData() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'getinspection_requests_fsinspector')
        .then((res) => {
          // You can log or process the data here if needed
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}
