import React from "react";
import { PageLayout } from '../../components/Layout/PageLayout';
import FsinspectorPanelDataTable from "../../datatables/Manufacturer/FsinspectorPanelDataTable";

const FsinspectorPanel = () => {
  return (
    <PageLayout showManageInspectors={false}>
      <div className="inspectionWrapInner">
        <FsinspectorPanelDataTable />
      </div>
    </PageLayout>
  );
};

export default FsinspectorPanel;