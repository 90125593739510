import React, { useState} from "react";
import PagesHeader from "../../components/PageHeader";
import ButtonBar from "../../components/ButtonNavigation/index";
import {
  Row,
  Col,
  Form,
  Card,
  Divider,
  Switch,
  Input,
  Radio,
  Button,
} from "antd";
import * as AddNewDealer from "../../services/AddNewDealer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Dealer = () => {
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const [hideShow1, setHideShow1] = useState(false);
  const [showHide2, setShowHide2] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [value, setValue] = useState([]);
  const [form] = Form.useForm();
 
 
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const toggle1 = () => {
    if (hideShow1 === false) {
      setHideShow1(true);
    } else {
      setHideShow1(false);
    }
  };
  const toggle2 = () => {
    if (showHide2 === false) {
      setShowHide2(true);
    } else {
      setShowHide2(false);
    }
  };
  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };
  const onFinish = (values) => {
    enterLoading(0);
    values.company_info = values.company_info === true ? "enable" : "disable";
    values.shipping_info = values.shipping_info === true ? "enable" : "disable";
    values.send_welcome_email = values.welcome_email === "yes" ? "yes" : "no";
    console.log("Success:", values);
    dispatch(AddNewDealer.addNewDealerData(values))
      .then((res) => {
        console.warn("Submit", res);
        //return;
        navigate("/single-dealer/?user_id=" + res.user_id);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="inspectionWrapper">
      <div className="inspectionHeader">
        <PagesHeader title="Add Dealer" type="secondary" />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={18} className="text-end">
              <ButtonBar />
            </Col>
            <Col xs={24} md={6}>
            
            </Col>
          </Row>
        </div>
      </div>
      <div className="inspectionWrapInner">
        <div className="container-xxl">
          <div className="container">
            <Row gutter={16} justify="center">
              <Col xs={24} md={24}>
                <div className="reportInner">
                  <Form
                    name="coverLetter"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    autoComplete="off"
                    form={form}
                  >
                    <div className="buttonSection"></div>
                    <div className="srWrapperInner">
                      <Card className="reportCard">
                        <div className="reportCardBody">
                          <div className="formSection">
                            <div className="inspectionSection">
                              <div className="inspecDetailHead">
                                <h5 className="inspcTitle">Add Dealer</h5>
                              </div>
                              <Divider />
                              <div className="inspectorDetails">
                                <div className="inovicecard">
                                  <div className="inspecContent">
                                    <Row gutter={16}>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Company Information"
                                            name="company_info"
                                            valuePropName="Yes"
                                          >
                                            <Switch onClick={toggle2} />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                    </Row>
                                    {showHide2 === true ? (
                                      <Row gutter={16}>
                                        <Col xs={24} md={6} xl={6}>
                                          <div className="formGroup">
                                            <Form.Item
                                              label="Company Name"
                                              name="comp_name"
                                            >
                                              <Input
                                                placeholder="Company Name"
                                                className="formControl"
                                              />
                                            </Form.Item>
                                          </div>
                                        </Col>
                                        <Col xs={24} md={6} xl={6}>
                                          <div className="formGroup">
                                            <Form.Item
                                              label="Website"
                                              name="website"
                                            >
                                              <Input
                                                placeholder="Website"
                                                className="formControl"
                                              />
                                            </Form.Item>
                                          </div>
                                        </Col>
                                        <Col xs={24} md={6} xl={6}>
                                          <div className="formGroup">
                                            <Form.Item
                                              label="Email"
                                              name="comp_email"
                                              rules={[
                                                {
                                                  type: 'email',
                                                  message: 'The input is not valid E-mail!',
                                                },
                                                {
                                                  required: true,
                                                  message: 'Please input your E-mail!',
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Email"
                                                className="formControl"
                                              />
                                            </Form.Item>
                                          </div>
                                        </Col>
                                        <Col xs={24} md={6} xl={6}>
                                          <div className="formGroup">
                                            <Form.Item
                                              label="Phone"
                                              name="phone"
                                            >
                                              <Input
                                                placeholder="Phone"
                                                className="formControl"
                                              />
                                            </Form.Item>
                                          </div>
                                        </Col>
                                        <Col xs={24} md={6} xl={6}>
                                          <div className="formGroup">
                                            <Form.Item
                                              label="Company Type"
                                              name="comp_type"
                                            >
                                              <Input
                                                placeholder="Company Type"
                                                className="formControl"
                                              />
                                            </Form.Item>
                                          </div>
                                        </Col>
                                      </Row>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="inovicecard">
                                  <div className="inspecContent">
                                    <Row gutter={16}>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Mailing and Shipping Address"
                                            name="shipping_info"
                                            valuePropName="Yes"
                                          >
                                            <Switch onClick={toggle1} />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      {hideShow1 === true ? (
                                        <Row gutter={16}>
                                           <Col xs={24} md={6} xl={6}>
                                            <div className="formGroup">
                                              <Form.Item
                                                label="PO Box"
                                                name="po_box"
                                              >
                                                <Input
                                                  placeholder="PO Box"
                                                  className="formControl"
                                                />
                                              </Form.Item>
                                            </div>
                                          </Col>
                                          <Col xs={24} md={6} xl={6}>
                                            <div className="formGroup">
                                              <Form.Item
                                                label="Street"
                                                name="street"
                                              >
                                                <Input
                                                  placeholder="Street"
                                                  className="formControl"
                                                />
                                              </Form.Item>
                                            </div>
                                          </Col>
                                         <Col xs={24} md={6} xl={6}>
                                            <div className="formGroup">
                                              <Form.Item
                                                label="City"
                                                name="city"
                                              >
                                                <Input
                                                  placeholder="City"
                                                  className="formControl"
                                                />
                                              </Form.Item>
                                            </div>
                                          </Col>
                                          <Col xs={24} md={6} xl={6}>
                                            <div className="formGroup">
                                              <Form.Item
                                                label="State"
                                                name="state"
                                              >
                                                <Input
                                                  placeholder="State"
                                                  className="formControl"
                                                />
                                              </Form.Item>
                                            </div>
                                          </Col>
                                          <Col xs={24} md={6} xl={6}>
                                            <div className="formGroup">
                                              <Form.Item
                                                label="Zip Code"
                                                name="zip_code"
                                              >
                                                <Input
                                                  placeholder="Zip Code"
                                                  className="formControl"
                                                />
                                              </Form.Item>
                                            </div>
                                          </Col>
                                          <Col xs={24} md={6} xl={6}>
                                            <div className="formGroup">
                                              <Form.Item
                                                label="Country"
                                                name="country"
                                              >
                                                <Input
                                                  placeholder="Country"
                                                  className="formControl"
                                                />
                                              </Form.Item>
                                            </div>
                                          </Col>
                                        </Row>
                                      ) : (
                                        ""
                                      )}
                                    </Row>
                                  </div>
                                </div>
                                <div className="inovicecard">
                                  <div className="inspecContent m-2">
                                    <Row gutter={16}>
                                      <div className="formGroup">
                                        <Form.Item>Login Information</Form.Item>
                                      </div>
                                    </Row>
                                    <Row gutter={16}>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Username"
                                            name="user_name"
                                          >
                                            <Input
                                              placeholder="Username"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="First Name"
                                            name="first_name"
                                          >
                                            <Input
                                              placeholder="First Name"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Last Name"
                                            name="last_name"
                                          >
                                            <Input
                                              placeholder="Last Name"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Email Address"
                                            name="email_add"
                                            rules={[
                                              {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                              },
                                              {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Email Address"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Password"
                                            name="pwd"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please input your password!",
                                              },
                                            ]}
                                            hasFeedback
                                          >
                                            <Input.Password
                                              placeholder="Password"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Confirm Password"
                                            name="confirm_pass"
                                            dependencies={["password"]}
                                              hasFeedback
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please confirm your password!",
                                                },
                                               ]}
                                          >
                                            <Input.Password
                                              placeholder="Confirm Password"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Phone"
                                            name="phone_personal"
                                          >
                                            <Input
                                              placeholder="Phone"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>{" "}
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item label="Send Welcome Email" name="welcome_email">
                                            <Radio.Group onChange={onChange} value={value}>
                                              <Radio value="yes"> Yes </Radio>
                                              <Radio value="no"> No </Radio>
                                            </Radio.Group>
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={12} xl={12}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Send Copies of Reports or Invoice to"
                                            name="send_copy"
                                          >
                                            <Input
                                              placeholder="Send Copies of Reports or Invoice to"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Status"
                                            name="status"
                                          >
                                            <Switch />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                                <div className="custum-btngrp">
                                  <Button
                                    htmlType="submit"
                                    className="submitBtn"
                                    loading={loadings[0]}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dealer;
