import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Image,
  Button,
  message,
  Form,
  Upload,
  DatePicker,
  Input,
  // Spin,
  Skeleton,
  notification,
} from "antd";
import PageHeader from "../../components/PageHeader";
import {
  IdcardOutlined,
  MailOutlined,
  HomeOutlined,
  PhoneOutlined,
  PlusOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { MEDIA_UPLOAD_URL } from "../../Helper";
import * as coverLetterService from "../../services/CoverLetter";
import * as inspectorService from "../../services/InspectorDataService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";

function CreateCoverLetter() {
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [loadings, setLoadings] = useState([]);
  const [inspectorData, setInspectorData] = useState();
  const [inspectonDate, setInspectionDate] = useState();
  const [coverImg, setCoverImg] = useState();
  const [calOpen, setCalOpen] = useState(false);
  const [coverHeading, setCoverHeading] = useState("Inspection Report");
  const [coverOrganization, setCoverOrganization] = useState("");
  const [form] = Form.useForm();

  const queryParams = new URLSearchParams(window.location.search);
  const req_id = queryParams.get("req_id");
  useEffect(() => {
    fetchData();
    fetchCoverImage(req_id);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DateFormat = "MM-DD-YYYY";

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(inspectorService.getInspectorData())
      .then((res) => {
        setInspectorData(res);
        setCoverOrganization(res.organization_name);
        //setCoverHeading(res.coverHeading);
        form.setFieldsValue({
          inspector_certificationcp: res.inspector_certificationcp,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };
  const fetchCoverImage = (req_id) => {
    dispatch(coverLetterService.getCoverimage(req_id))
      .then((res) => {
        console.info("fetchCoverImage", res);
        setImageUrl(res.data.cover_image_url);
        setCoverImg(res.data.cover_image_id);
        setInspectionDate(res.data.cover_date);

        form.setFieldsValue({
          claim_number: res.data.claim_number,
          address_on_cover: res.data.cover_address,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
    console.warn("req_id in cover", req_id);
  };
  console.log();
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };
  const handleChange = ({ name, value, previousValue }) => {
    console.warn(
      name + " saved as: " + value + " (prev: " + previousValue + ")"
    );
    if (name === "organization_name") {
      setCoverOrganization(value);
    }
    if (name === "cover_heading") {
      setCoverHeading(value);
    }
  };
  const onFinish = (values) => {
    values.coverHeading = coverHeading;
    values.inspection_date = inspectonDate;

    enterLoading(0);
    values.req_id = req_id;
    values.cover_logo = inspectorData.logo;
    values.inspector_name = inspectorData.insp_fullname;
    values.inspector_company_name = coverOrganization;
    values.cover_email = inspectorData.email;
    values.cover_phone = inspectorData.phone_number;
    values.cover_img_id = coverImg;
    console.log("Success:", values);

    dispatch(coverLetterService.createCoverLetter(values))
      .then((res) => {
        console.warn("Submit Cover Letter Data", res);
        //return;
        notification.open({
          message: "Cover Letter successfully updated!",
          description: "You have successfully updated a cover letter.",
          icon: (
            <CheckCircleOutlined
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
        navigate("/cover-letter/?cover_id=" + res.data.cover_id);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleDateformate = (e, name, type) => {
    if (name === "inspection_date") {
      if (type === "date") {
        setCalOpen(false);
        let date = moment(e).format("MM-DD-YYYY");
        setInspectionDate(date);
      } else {
        let value = e.target.value;
        setInspectionDate(value);
      }
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    return isJpgOrPng;
  };

  const handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      setLoadingImg(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoadingImg(false);
        setImageUrl(url);
      });
    }
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    fmData.append("file", file);
    try {
      const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
      setCoverImg(res.data.id);
      onSuccess(res.data);
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
      console.warn(error);
    }
  };
  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader title="Create Cover Letter" type="secondary" />
      </div>
      {loading ? (
          <div className="container-xxl">
          <div className="Skeleton_cover">
              <Row gutter={16} className="mb-4" justify="center">
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                <Col xs={24} md={4} xl={4} className="sklpad">
                <Skeleton.Input block={true} active size="large" style={{height:"90px" }}/>
                </Col>
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-5" justify="center">
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-5 mt-5" justify="center">
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-4 mt-5" justify="center">
                <Col xs={24} md={9} xl={9} className="sklpad">
                  
                </Col>
                <Col xs={24} md={6} xl={6} className="sklpad">
                <Skeleton.Input block={true} active size="large" style={{height:"350px" }}/>
                </Col>
                <Col xs={24} md={9} xl={9} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-5" justify="center">
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-4" justify="center">
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                <Col xs={24} md={4} xl={4} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-4" justify="center">
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                <Col xs={24} md={4} xl={4} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-4" justify="center">
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-5" justify="center">
                <Col xs={24} md={10} xl={10} className="sklpad">
                
                </Col>
                <Col xs={24} md={4} xl={4} className="sklpad">
                <Skeleton.Input active size="large" />
                </Col>
                <Col xs={24} md={10} xl={10} className="sklpad">
                
                </Col>
                
              </Row>
              
            </div>
          </div>
        ) : (
        <div className="container-xxl">
          <Row justify="center">
            <Col xs={24} md={20}>
              <Form
                name="coverLetter"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                autoComplete="off"
                form={form}
              >
                <div className="srWrapperInner">
                  <Card className="coverCard">
                    <Row gutter={16} justify="center">
                      <Col xs={24} md={6} xl={6}>
                        <div className="inspectorLogo text-center">
                          <Image
                            src={inspectorData?.logo_url}
                            className="img-fluid"
                            alt="InspectorLogo"
                            preview={false}
                          />
                          {/* <h4 className="inspectorName my-3">
                            {inspectorData?.organization_name}
                          </h4> */}
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={16} justify="center">
                      <Col xs={24} md={24} xl={24}>
                        <div className="coverHaeding1">
                          <EditText
                            name="organization_name"
                            style={{
                              padding: "15px",
                              fontSize: "1.875rem",
                              textAlign: "center",
                              fontWeight: "400",
                              textTransform: "uppercase",
                            }}
                            defaultValue={coverOrganization}
                            onSave={(e) =>
                              handleChange(e, setCoverOrganization)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={16} justify="center">
                      <Col xs={24} md={24} xl={24}>
                      <div className="reportHeader coverHaeding1">
                          {/* <h4>Inspection Report</h4> */}
                          <EditText className="coverHaeding_orag"
                            name="cover_heading"
                            style={{
                              padding: "15px",
                              fontSize: "1.875rem",
                              color: "#0A497B",
                              textAlign: "center",
                              fontWeight: "500",
                              textTransform: "uppercase",
                             
                            }}
                            defaultValue={coverHeading}
                            onSave={(e) => handleChange(e, setCoverHeading)}
                          
                          />
                        </div>
                        </Col>
                        </Row>
                    <Row
                      gutter={16}
                      justify="center"
                      className="my-4"
                      wrap={true}
                    >
                      <Col xs={24} md={8}>
                        <Form.Item
                          name="upload"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          className="text-center uploadLogo"
                        >
                          <Upload
                            name="coverLetterLogo"
                            listType="picture-card"
                            className="ucl-uploader"
                            showUploadList={false}
                            customRequest={uploadImage}
                            beforeUpload={beforeUpload}
                            onChange={handleChangeImage}
                          >
                            {imageUrl ? (
                              <>
                                {loadingImg ? (
                                  <div className="spinDiv">
                                    <LoadingOutlined />
                                  </div>
                                ) : (
                                  <img
                                    src={imageUrl}
                                    alt="avatar"
                                    className="img-fluid"
                                  />
                                )}
                              </>
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          name="address_on_cover"
                          className="customerNameInput"
                        >
                          <Input
                            placeholder="Customer Name/Site Address"
                            className="formControl"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} justify="center">
                      <div className="inspectorNameSection">
                        <h4 className="nameSectionTitle">Inspector Name</h4>
                        <h4 className="inspectorName">
                          {inspectorData?.insp_fullname}
                        </h4>
                      </div>
                    </Row>
                    <Row gutter={30} justify="center" className="mb-4">
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item
                          name="inspection_date"
                          label="Inspection Date"
                        >
                          <Input.Group compact className="mt-0">
                            <Input
                              placeholder="Date"
                              className="formControl"
                              onChange={(e) =>
                                handleDateformate(e, "inspection_date", "text")
                              }
                              value={inspectonDate}
                              onFocus={() => setCalOpen(!calOpen)}
                              onKeyUp={() => setCalOpen(false)}
                              onBlur={() => setCalOpen(false)}
                            />

                            <Button
                              className="btnAddOn"
                              icon={<CalendarOutlined />}
                              onClick={() => setCalOpen(!calOpen)}
                            />
                          </Input.Group>
                          <DatePicker
                            className="formControl dateHide"
                            format={DateFormat}
                            open={calOpen}
                            style={{ visibility: "hidden", width: 0 }}
                            onChange={(e) =>
                              handleDateformate(e, "inspection_date", "date")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8} xl={8} className="colBorder">
                        <Form.Item
                          label="Inspection Certification/CP#"
                          name="inspector_certificationcp"
                        >
                          <Input
                            placeholder="Certification Number"
                            className="formControl"
                          />
                        </Form.Item>
                        {/* <div className="certificationSection">
                            <p className="certificationTitle">Inspection Certification/CP #</p>
                            <p className="certificationNumber formControl">123458</p>
                          </div> */}
                      </Col>
                      <Col xs={24} md={8} xl={8}>
                        <Form.Item label="Claim#" name="claim_number">
                          <Input
                            placeholder="Claim Number"
                            className="formControl"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="letterFooter">
                      <span className="span">
                        <IdcardOutlined />
                        <span className="spanText">
                          {inspectorData?.insp_fullname}
                        </span>
                      </span>
                      <span className="divider">{"|"}</span>
                      <span className="span">
                        <HomeOutlined />
                        <span className="spanText">
                          {inspectorData?.organization_name}
                        </span>
                      </span>
                      <span className="divider">{"|"}</span>
                      <span className="span">
                        <MailOutlined />
                        <span className="spanText">{inspectorData?.email}</span>
                      </span>
                      <span className="divider">{"|"}</span>
                      <span className="span">
                        <PhoneOutlined />
                        <span className="spanText">
                          {inspectorData?.phone_number}
                        </span>
                      </span>
                    </div>
                    <Row gutter={16} className="pt-3">
                      <Col xs={24} md={24} className="text-center">
                        <Button
                          className="btnPrimary"
                          htmlType="submit"
                          loading={loadings[0]}
                        >
                          {" "}
                          Save and Continue
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default CreateCoverLetter;
