import React, { useState, useEffect } from "react";
import { Row, Col, Card, Input, Tag, Button, Modal, Pagination } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { SecondaryButton } from "../../components/Buttons/buttons";
import * as IndustryStandardsServices from "../../services/IndustryStandards";
import { useDispatch } from "react-redux";
import PagesHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SkletonIndustry from "./skletonIndustry";

function IndustryStandards() {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filterStandards, setFilterStandards] = useState([]);
  const [modalIndustry, setModalIndustry] = useState(false);
  const [industryContent, setindustryContent] = useState("");
  const [standards, setStandards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12); // Items per page

  useEffect(() => {
    fetchData();
    setLoading(true);
    window.scrollTo(0, 0);
  }, []);

  const fetchData = () => {
    setLoading(true);
    dispatch(IndustryStandardsServices.publicLibrary())
      .then((res) => {
        setStandards(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const industryPageRoute = () => {
    navigate(`/industry-standards`);
  };

  const copyLibrary = (pid) => {
    Swal.fire({
      title: "Add this standard to your library?",
      text: "This will be added in your private library!",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, add it!",
    }).then((result) => {
      if (result.isConfirmed) {
        copySuggestion(pid);
        Swal.fire("Added!", "Library Added", "success");
      }
    });
  };

  const copySuggestion = (pid) => {
    setLoading(true);
    let val = { pid };
    dispatch(IndustryStandardsServices.copySuggestion(val)).finally(() => {
      setLoading(false);
    });
  };

  const handleClicks = (item1) => {
    setModalIndustry(true);
    const content = standards.find((item) => item.pid === item1);
    setindustryContent(content || "");
  };

  useEffect(() => {
    if (standards) {
      const filteredData = standards.filter((entry) =>
        entry?.suggetion_title?.toLowerCase().includes(search.toLowerCase())
      );
      setFilterStandards(filteredData);
    }
  }, [search, standards]);

  // Pagination change handler
  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // Paginate data
  const paginatedData = (filterStandards.length > 0 ? filterStandards : standards)
    .slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="industryWrapper">
      <div className="industryHeader">
        <PagesHeader title="Public Library" />
      </div>
      <div className="industryInnerWrapper">
        <div className="container-xxl">
          <div className="searchWrap">
            <Row gutter={16} justify="space-between">
              <Col xs={24} md={6}>
                <div className="searchWrapper">
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="Search..."
                    className="formControl"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={10} xs={24}>
                <div className="libraryBtnSection text-end">
                  <SecondaryButton
                    type="secondary"
                    handleClick={() => industryPageRoute()}
                    text="My Industry Standards/Citations"
                    style={{ marginRight: "0" }}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="standardsWrapper">
            <Row gutter={[16, 16]}>
              {loading ? (
                <div className="spinDiv">
                  <SkletonIndustry />
                </div>
              ) : (
                paginatedData.map((item, index) => (
                  <Col xs={24} md={8} key={index} className="gutter-row">
                    <Card className="industryCard">
                      <div className="industryCardBody">
                        <div className="cardTopSection">
                          <div className="headerSec">
                            <h3
                              className="industryTitle"
                              dangerouslySetInnerHTML={{
                                __html: item.suggetion_title,
                              }}
                            ></h3>
                            <p className="industryDesc">{item?.excerpt}</p>
                          </div>
                        </div>
                        <div className="contentSec">
                          <p className="categoryRow">
                            <span className="catLabel">Category- </span>
                            <p>
                              {item?.suggetion_categorylist?.map(
                                (catName, i) => (
                                  <Tag className="categoryTag" key={i}>
                                    <Link
                                      to="/"
                                      className="categoryLink"
                                    >
                                      {catName}
                                    </Link>
                                  </Tag>
                                )
                              )}
                            </p>
                          </p>
                          <p className="categoryRow">
                            <span className="catLabel">Label- </span>
                            <span className="industryLable">
                              <Link to="/" className="lblLink">
                                {item?.suggetion_field_list}
                              </Link>
                            </span>
                          </p>
                          <p className="categoryRow">
                            <span className="catLabel">Created- </span>
                            <span className="industryLable">
                              {item?.created_at}
                            </span>
                          </p>
                          <div className="btnGroup">
                            <Button
                              type="text"
                              id={item.id}
                              className="indstryBtn viewBtn"
                              onClick={() => copyLibrary(item.pid)}
                              disabled={item.copied === true}
                            >
                              Copy
                            </Button>
                            <Button
                              type="text"
                              id={item.id}
                              className="indstryBtn viewBtn"
                              onClick={() => handleClicks(item.pid)}
                            >
                              View More
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))
              )}
            </Row>

            {/* Pagination Component */}
            <Row justify="center" style={{ marginTop: '20px' }}>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={(filterStandards.length > 0 ? filterStandards : standards).length}
                onChange={onPageChange}
                showSizeChanger
                pageSizeOptions={[6, 12, 24]}
              />
            </Row>

          </div>
        </div>
      </div>

      {/* Industry View Modal */}
      <Modal
        title={industryContent.suggetion_title}
        centered
        open={modalIndustry}
        closable={false}
        className="inspectionCategoryModal industryModal"
        width={680}
        footer={[
          <Button
            key="back"
            onClick={() => setModalIndustry(false)}
            className="submitBtn"
          >
            Ok
          </Button>,
        ]}
      >
        <div className="modalContent">
          <div className="formGroup">
            <p
              className="mcDescription"
              dangerouslySetInnerHTML={{
                __html: industryContent.suggetion_content,
              }}
            ></p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default IndustryStandards;
